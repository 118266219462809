import {FC, useState, useEffect} from 'react'
import {CompanyCard} from '../components/CompanyCard'
import {
  useGetCompaniesQuery,
  createCompanySelectors,
  useGetSectorsQuery,
  useGetFinancingTypesQuery,
} from '../CompaniesApiSlice'
import {RootState} from '../../../../data/redux/Store'
import {useSelector} from 'react-redux'
import {Spinner} from 'react-bootstrap'
import Pagination from '../../shared/components/Pagination'
import useAuthToken from '../../../../hooks/useAuthToken'
import {Toolbar} from '../../../../_theme/layout/components/Toolbar'
import FilterButton from '../../shared/components/FilterButton'
import EntityListSearch from '../../shared/components/EntityListSearch'
import {INameIdPair} from '../../shared/interfaces'
import {toggleFiltersState} from '../../../../data/redux/settingsReducer'
import {useSearchParams, useNavigate} from 'react-router-dom'
import { ListPageFilters } from '../../shared/modals/ListPageFilters'

const Companies: FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const routePage = searchParams.get('page') || '1'
  const CurrentPageSize = searchParams.get('pageSize') || '6'
  const currentSearchTerm = searchParams.get('searchTerm') || ''
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false)
  
  const [currentPage, setCurrentPage] = useState<number>(parseInt(routePage))
  const [searchTerm, setSearchTerm] = useState(currentSearchTerm)
  const [pageSize, setPageSize] = useState<number>(parseInt(CurrentPageSize))
  const filters = useSelector((state: RootState) => state.settings.companyFilters)
  
  const isTokenAvailable = useAuthToken()

  const {data, error, isLoading, isFetching, isSuccess, isError} = useGetCompaniesQuery(
    {
      page: currentPage,
      pageSize,
      searchTerm,
      ...filters,
    },
    {skip: !isTokenAvailable}
  )
  const {data: sectorsState} = useGetSectorsQuery()
  const companySectorsList = Object.values(sectorsState?.entities || {}).filter(
    (type): type is INameIdPair => Boolean(type)
  )
  const {data: financingTypesState} = useGetFinancingTypesQuery()

  const companyFinancingTypesList = Object.values(financingTypesState?.entities || {}).filter(
    (type): type is INameIdPair => Boolean(type)
  )
  const {selectIds: selectCompanyIds} = createCompanySelectors({
    page: currentPage,
    pageSize,
    searchTerm,
    ...filters,
  })
  const companyIds = useSelector((state: RootState) => selectCompanyIds(state)) as string[]
  const paginationMetadata = data?.paginationMetadata

  useEffect(() => {
    const newPageSize = parseInt(searchParams.get('pageSize') || '6')
    setPageSize(newPageSize)
  }, [searchParams])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    navigate(`?page=${page}&pageSize=6`)
  }
  

  const handleFiltersModal = () => {
    setShowFiltersModal(!showFiltersModal)
  }
  const handleResetSelectedPage = () => {
    setCurrentPage(1)
  }

  let content
  if (isFetching) {
    content = (
      <div className='d-flex justify-content-center flex-column gap-3 align-items-center h-100'>
        <Spinner animation='border' variant='danger' />
        <p className='pt-0'>Loading...</p>
      </div>
    )
  } else if (isSuccess) {
    content =
      companyIds.length > 0 ? (
        <div className='row'>
          {companyIds.map((id) => (
            <div className={`col-12 col-md-6 col-lg-4`} key={id}>
              <CompanyCard
                id={id}
                page={currentPage}
                pageSize={pageSize}
                searchTerm={searchTerm}
                filters={filters}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className='d-flex justify-content-right align-items-right vh-100'>
          <em>There are no companies listed yet.</em>
        </div>
      )
  } else if (isError) {
    if ('status' in error) {
      const fetchError = error as {status: number; data: unknown; error: string}
      const errorMessage =
        typeof fetchError.error === 'object' ? JSON.stringify(fetchError.error) : fetchError.error
        if (fetchError.status >= 500 || fetchError.status === 404 || errorMessage === 'TypeError: Failed to fetch') {
          navigate('/error')
        }else {
          content = (
            <div className='d-flex justify-content-center align-items-center vh-100'>
              {errorMessage}
            </div>
          )
        }
    } else if ('message' in error) {
      content = (
        <div className='d-flex justify-content-center align-items-center vh-100'>
          {error.message}
        </div>
      )
    } else {
      content = (
        <div className='d-flex justify-content-center align-items-center vh-100'>
          Unknown error occurred
        </div>
      )
    }
  }

  return (
    <div className='d-flex flex-column h-100'>
      <Toolbar page='Companies' link='/companies/add' buttonTitle='Add New Company'>
        <EntityListSearch setSearchTerm={setSearchTerm} handlePageChange={handlePageChange} />
        <FilterButton
          showFilters={showFiltersModal}
          handleFilterButtonClick={handleFiltersModal}
          hasWhiteBackground={true}
        />
      </Toolbar>
      <div className='flex-grow-1'>{content}</div>
      {companyIds.length > 0 && (
        <div className={`flex-shrink-0`}>
          {data && paginationMetadata && (
            <Pagination
              currentPage={currentPage}
              totalPages={Math.ceil(paginationMetadata.totalCount / paginationMetadata.pageSize)}
              onPageChange={handlePageChange}
              entriesCount={paginationMetadata.totalCount}
              entriesPerPage={paginationMetadata.pageSize}
            />
          )}
        </div>
      )}
      <ListPageFilters
        showModal={showFiltersModal}
        handleClose={handleFiltersModal}
        companySectorsList={companySectorsList}
        companyFinancingTypesList={companyFinancingTypesList}
        entities = 'Companies'
        handleResetSelectedPage={handleResetSelectedPage}
      />
    </div>
  )
}

export {Companies}
