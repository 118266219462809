/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useLocation, useParams} from 'react-router-dom'
import Loading from '../../shared/components/Loading'
import CompanyHeader from '../components/CompanyHeader'
import CompanyContactInformation from '../components/CompanyContactInformation'
import {CompanyOperations} from '../components/CompanyOperations'
import {CompanyFinancials} from '../components/CompanyFinancials'
import CompanyDeals from '../components/CompanyDeals'
import CompanyCheckList from '../components/CompanyCheckList'
import AuditLog from '../../shared/components/AuditLog'
import {useGetCompanyDetailsQuery} from '../CompaniesApiSlice'
import ProgressBar from '../../shared/components/ProgressBar'
import {Toolbar} from '../../../../_theme/layout/components/Toolbar'
import {CompanyFoldersCard} from '../components/CompanyFoldersCard'
import {getPaginationState} from '../../../../utils/getPaginationState'
import CompanyDocumentsList from '../components/CompanyDocumentsList'

export const CompanyDetails = () => {
  const location = useLocation()
  const {currentPage, pageSize} = getPaginationState(location)
  const {id} = useParams<{id: string}>() as {id: string}
  const [showModal, setShowModal] = useState(false)
  const [openFolder, setOpenFolder] = useState(false)
  const [selectedFolder, setSelectedFolder] = useState<{
    index: number
    id: string
    folderName: string
    parentFolderId?: string | null
  }>({
    index: 0,
    id: '',
    folderName: '',
    parentFolderId: '',
  })
  const [selectedTab, setSelectedTab] = useState('Company Operations')
  const {data: companyState, isLoading: companyDetailsLoading} = useGetCompanyDetailsQuery(id)
  const companyDetails = companyState?.entities[id]
  const checklistValues = companyDetails?.onboardingStatus.split(',').map(Number)
  const completedSteps = checklistValues?.filter((value) => value === 1).length

  if (companyDetailsLoading) {
    return <Loading />
  }
  const handleClose = () => {
    setShowModal(!showModal)
  }
  const handleFolderToggle = () => {
    setOpenFolder(!openFolder)
  }

  return (
    <>
      <Toolbar page='View Company Details' link='/companies' buttonTitle='Back'></Toolbar>
      <div className='row'>
        <div className='col-lg-8 col-md-12'>
          <CompanyHeader
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            currentPage={currentPage}
            pageSize={pageSize}
          />
          <div className='card card-flush shadow-sm mt-5'>
            <div className='card-body py-5'>
              {selectedTab === 'Company Operations' && (
                <CompanyOperations showModal={showModal} handleClose={handleClose} />
              )}
              {selectedTab === 'Financials' && (
                <CompanyFinancials showModal={showModal} handleClose={handleClose} />
              )}
              {selectedTab === 'Contact Information' && (
                <CompanyContactInformation showModal={showModal} handleClose={handleClose} />
              )}
              {selectedTab === 'Documents' &&
                (selectedFolder.index === 0 ? (
                  <CompanyFoldersCard
                    handleFolderToggle={handleFolderToggle}
                    setSelectedFolder={setSelectedFolder}
                    showModal={showModal}
                    handleClose={handleClose}
                    selectedFolder={selectedFolder}
                  />
                ) : (
                  <CompanyDocumentsList
                    selectedFolder={selectedFolder}
                    setSelectedFolder={setSelectedFolder}
                  />
                ))}
              {selectedTab === 'Deals' && <CompanyDeals />}
            </div>
          </div>
        </div>
        <div className='col-lg-4 col-sm-12 col-md-12'>
          <div className='card card-flush card-stretch-75 card-bordered mb-5'>
            <div className='card-header'>
              <div className='mt-3' style={{borderBottom: '1px solid #dee2e6', width: '100%'}}>
                <h3 className='card-title'>Onboarding Status</h3>
                <ProgressBar value={completedSteps || 0} title='Current Status' />
              </div>
            </div>
            <div className='card-body d-flex flex-column gap-5'>
              <CompanyCheckList />
            </div>
          </div>
          <div className='card card-flush card-stretch-25 card-bordered mb-5'>
            <div className='card-body d-flex flex-column gap-5'>
              <AuditLog />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
