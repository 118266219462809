/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {LineChartCard} from '../components/cards/LineChartCard'
import {FaceAvatorListCard} from '../components/cards/FaceAvatorListCard'
import {SingleDataSetBarChart} from '../components/charts/SingleDataSetBarChart'
import {GlobalMapChartCard} from '../components/cards/GlobalMapChartCard'
import {DataItem} from '../interfaces'
import {SingleDataSetRoundedBarChart} from '../components/charts/SingleDataSetRoundedBarChart'
import {XBarChart} from '../components/charts/BarChart'
import {PageTitle} from '../../../../_theme/layout/core'
import {IconListCard} from '../components/cards/IconListCard'
import {TableBarChartCard} from '../components/cards/TableBarChartCard'

const OverviewPage: FC = () => {

  const tableDataItems: DataItem[] = [
    {dataItemName: 'Agriculture', dataItemNumber: 120},
    {dataItemName: 'Manufacturing', dataItemNumber: 125},
    {dataItemName: 'Tourism', dataItemNumber: 125},
    {dataItemName: 'Entertainment', dataItemNumber: 95},
    {dataItemName: 'Eduction', dataItemNumber: 100},
    {dataItemName: 'Technology', dataItemNumber: 75},
  ]


  return (
    <>
      <div className="row pb-2 mb-4">
        <div className="col-lg-3 pb-6">
          <LineChartCard
            chartTitleFigure="203"
            chartSubTitle="Companies"
            dataSrc={[25, 6, 10, 11, 25, 27]}
          />
          <LineChartCard
            chartTitleFigure="207"
            chartSubTitle="Deals"
            dataSrc={[10, 5, 6, 10, 11, 25, 27]}
          />
        </div>
        <div className="col-lg-3 pb-6">
          <LineChartCard
            chartTitleFigure="27"
            chartSubTitle="Financiers"
            dataSrc={[10, 5, 6, 10, 11, 25, 27]}
          />
          <FaceAvatorListCard
            avatorListLength={10}
            cardDisplayedNumber={5}
            cardLabel="On boarded Sevice Providers"
            avatorListLabel="Our favourites"
          />
        </div>
        <div className="col-lg-6">
          <GlobalMapChartCard
            horizontal={true}
            className=""
            dataSrc={
              [
                {id: 'AF', value: 60, financiers: 35, serviceProviders: 65},
                {id: 'AL', value: 77, financiers: 75, serviceProviders: 75},
                {id: 'DZ', value: 70, financiers: 95, serviceProviders: 95},
                {id: 'AO', value: 51, financiers: 75, serviceProviders: 85},
                {id: 'AR', value: 76, financiers: 25, serviceProviders: 55},
                {id: 'AM', value: 74, financiers: 65, serviceProviders: 45},
                {id: 'ML', value: 54, financiers: 85, serviceProviders: 45},
                {id: 'MR', value: 61, financiers: 95, serviceProviders: 55},
                {id: 'MU', value: 73, financiers: 105, serviceProviders: 35},
                {id: 'RW', value: 63, financiers: 25, serviceProviders: 75},
                {id: 'SA', value: 75, financiers: 15, serviceProviders: 85},
                {id: 'SY', value: 71, financiers: 45, serviceProviders: 85},
                {id: 'TZ', value: 60, financiers: 75, serviceProviders: 65},
                {id: 'TG', value: 56, financiers: 95, serviceProviders: 75},
                {id: 'TN', value: 75, financiers: 75, serviceProviders: 85},
                {id: 'UG', value: 58, financiers: 345, serviceProviders: 95},
                {id: 'ZA', value: 58, financiers: 35, serviceProviders: 55},
                {id: 'ZM', value: 57, financiers: 95, serviceProviders: 45},
                {id: 'ZW', value: 58, financiers: 35, serviceProviders: 65},
              ]
            }
            chartTitleFigure="Location"
            chartSubTitle="Companies, Financiers, and Service Provider Locations"
          />
        </div>
      </div>
      <div className="row pb-2 mb-4">
        <div className="col-lg-3">
          <SingleDataSetBarChart
            chartTitle="Companies By Sector"
            className=""
            dataSrc="/data-source"
            horizontal={true}
            chartSubTitle="Sector beak down"
          />
        </div>
        <div className="col-lg-6">
          <XBarChart
            chartTitle="Companies and Financiers by Country"
            className="house"
            dataSrc="/data-source"
            horizontal={false}
            chartSubTitle=""
          />
        </div>
      </div>
      <div className="row pb-2 mb-4">
        <div className="col-lg-6">
          <TableBarChartCard
            cardTitle="Financiers by sector"
            cardSubTitle="Sectors for financiers"
            dataLabelTitle="Sector"
            dataBarsTitle="Number of financiers"
            dataItems={tableDataItems}
          />
        </div>
        <div className="col-lg-6">
          <SingleDataSetRoundedBarChart
            chartTitle="Financing Types By Average Ticket Size"
            className=""
            dataSrc="/data-source"
            horizontal={true}
            chartSubTitle=""
          />
        </div>
      </div>
      <div className="row pb-2">
        <div className="col-lg-4">
          <IconListCard
            chartTitle="Deal Status"
            dataSrc="/data-source"
            chartSubTitle=""
            itemlist={
              [
                {
                  cardTitle: 'Initiated (Started)',
                  cardSubTitle: '',
                  cardPercentage: 25,
                  cardDisplayedNumber: 12,
                  cardDisplayedValue: 85000,
                  cardIcon: 'bi-bookmarks-fill',
                },
                {
                  cardTitle: 'Submissions Ongoing',
                  cardSubTitle: '',
                  cardPercentage: 20,
                  cardDisplayedNumber: 12,
                  cardDisplayedValue: 8000,
                  cardIcon: 'bi-airplane-fill',
                },
                {
                  cardTitle: 'Deal Succeeded',
                  cardSubTitle: '',
                  cardPercentage: 20,
                  cardDisplayedNumber: 12,
                  cardDisplayedValue: 72000,
                  cardIcon: 'bi-arrow-up-right-circle-fill',
                },
                {
                  cardTitle: 'Process Failed',
                  cardSubTitle: '',
                  cardPercentage: 40,
                  cardDisplayedNumber: 12,
                  cardDisplayedValue: 41000,
                  cardIcon: 'bi-sign-turn-left-fill',
                },
              ]
            }
          />
        </div>
        <div className="col-lg-4">
          <SingleDataSetRoundedBarChart
            chartTitle="Financing Types By Average Ticket Size"
            className=""
            dataSrc="/data-source"
            horizontal={true}
            chartSubTitle=""
          />
        </div>
        <div className="col-lg-4">
          <IconListCard
            chartTitle="Service Provider Categories"
            dataSrc="/data-source"
            chartSubTitle=""
            itemlist={
              [
                {
                  cardTitle: 'Financial Due Diligence',
                  cardSubTitle: '',
                  cardPercentage: 25,
                  cardDisplayedNumber: 12,
                  cardDisplayedValue: 85000,
                  cardIcon: 'bi bi-cash-coin',
                },
                {
                  cardTitle: 'Legal Due Diligence',
                  cardSubTitle: '',
                  cardPercentage: 20,
                  cardDisplayedNumber: 12,
                  cardDisplayedValue: 8000,
                  cardIcon: 'bi bi-blockquote-left',
                },
                {
                  cardTitle: 'Information Technology Support',
                  cardSubTitle: '',
                  cardPercentage: 20,
                  cardDisplayedNumber: 12,
                  cardDisplayedValue: 72000,
                  cardIcon: 'bi bi-bar-chart-fill',
                },
                {
                  cardTitle: 'Tax and Compliance',
                  cardSubTitle: '',
                  cardPercentage: 40,
                  cardDisplayedNumber: 12,
                  cardDisplayedValue: 41000,
                  cardIcon: 'bi bi-building-check',
                },
              ]
            }
          />
        </div>
      </div>
    </>
  )
}

const Overview: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Overview</PageTitle>
      <OverviewPage />
    </>
  )
}

export {Overview}
