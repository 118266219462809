import { FC } from "react";

type EditButtonProps = {
    handleClose: () => void;
    customClass?: string;
};

const EditButton: FC<EditButtonProps> = ({ handleClose, customClass = "company-edit-button" }) => {
  return (
    <button
      className={`position-absolute btn btn-link p-0 ${customClass}`}
      onClick={handleClose}
    >
      <i className="fas fa-pen text-danger"></i>
    </button>
  );
};

export default EditButton;
