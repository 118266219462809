import {IStepperItem} from '../../shared/interfaces/IStepperItem'

export const CompanyCreateFormSteps: Array<IStepperItem> = [
  {
    step: 1,
    title: 'General Information',
    description: 'Company Details',
    isCurrent: true,
  },
  {
    step: 2,
    title: 'Business Operations',
    description: 'Company Location and Contact Info',
    isCurrent: false,
  },
  {
    step: 3,
    title: 'Financial Information',
    description: 'Company Operation Details',
    isCurrent: false,
  },
  {
    step: 4,
    title: 'Contact Information',
    description: 'Company Financials',
    isCurrent: false,
  },
  {
    step: 5,
    title: 'Review and Submit',
    description: 'Company Contact Person Details',
    isCurrent: false,
  },
]