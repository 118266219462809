/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { IconListItemCard } from './IconListItemCard'



type Props = {
    dataSrc: string
    chartTitle: string
    chartSubTitle?: string
    itemlist?: any[]
}

export const IconListCard: React.FC<Props> = (props) => {

    const {
        chartTitle,
        chartSubTitle,
        dataSrc,
        itemlist,
    } = props

    return (
        <>
            <div className="card card-flush h-xl-95">
                <div className="card-header pt-0">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-gray-800">{chartTitle}</span>
                    </h3>
                </div>
                <div className="card-body d-flex align-items-start pt-0">
                    <div className="w-100">
                        {itemlist && itemlist.map((item, index) => (
                            <IconListItemCard
                                key={index}
                                cardTitle={item.cardTitle}
                                cardSubTitle={item.cardSubTitle}
                                cardPercentage={item.cardPercentage}  
                                cardDisplayedNumber={item.cardDisplayedNumber}  
                                cardDispayedValue={item.cardDispayedValue}  
                                cardIcon={item.cardIcon}   
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}


