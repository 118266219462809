import {IStepperItem} from '../../interfaces/IStepperItem'
import React from 'react'

export const StepperItem = (item: IStepperItem) => {
  const {step, title, isCurrent, totalSteps} = item
  return (
    <div
      className={` container-fluid stepper-item p-0  align-items-center ${isCurrent ? ' current' : ''}`}
      data-kt-stepper-element="nav">
      <div className="d-flex flex-column align-items-center  p-0 justify-content-center">
        <div className="position-relative justify-content-center">
          <div className="stepper-icon">
            <i className="stepper-check fas fa-check"></i>
            <span className="stepper-number">{step}</span>
          </div>
          {step !== totalSteps && <span className="position-absolute separator"></span>}
        </div>
        <div className="stepper-label pt-3">
          <h3 className="custom-stepper-title stepper-title f-s">{title}</h3>
        </div>
      </div>
    </div>
  )
}