import {format, isValid, parseISO, formatDistanceToNow} from 'date-fns'

export const dateFormat = 'dd.MM.yyyy'
export const dateTimeFormat = 'dd.MM.yyyy HH:mm'
export const standardDateTimeFormat = 'dd-MM-yyyy HH:mm'
export const standardDateTimeFormatFileName = 'dd.MM.yyyy HH.mm'
export const standardDateTimeFormatSeconds = 'dd-MM-yyyy HH:mm:ss'
export const standardDateFormat = 'dd-MM-yyyy'
export const monthYearFormat = 'MMMM, yyyy'
export const yearDateTime = 'yyyy-MM-dd\'T\'HH:mm:ss'
export const yearMonthDayDate = 'yyyy-MM-dd'
export const NIRADate = 'yyyy-MM-dd'
export const printDateTime = (value: any): string => {
  if (typeof value === 'string') {
    return printDateTime(strToDate(value))
  }
  if (isValid(value)) return format(value, dateTimeFormat)
  else return ''
}

export const printBirthday = (value: any): string => {
  if (typeof value === 'string') {
    return printBirthday(strToDate(value))
  }
  if (isValid(value)) return format(value, 'dd MMM')
  else return ''
}

export const printMonth = (value: any): string => {
  if (typeof value === 'string') {
    return printMonth(strToDate(value))
  }
  if (isValid(value)) return format(value, 'MMM')
  else return ''
}

export const printDay = (value: any): string => {
  if (typeof value === 'string') {
    return printDay(strToDate(value))
  }
  if (isValid(value)) return format(value, 'dd')
  else return ''
}

export const printShortDate = (value: any): string => {
  if (typeof value === 'string') {
    return printShortDate(strToDate(value))
  }
  if (isValid(value)) return format(value, 'dd MMM')
  else return ''
}

export const printDayOfMonth = (value: any): string => {
  if (typeof value === 'string') {
    return printDayOfMonth(strToDate(value))
  }
  if (isValid(value)) return format(value, 'dd')
  else return ''
}

export const printDate = (value: any): string => {
  if (typeof value === 'string') {
    return printDate(strToDate(value))
  }
  if (isValid(value)) return format(value, dateFormat)
  else return ''
}

export const printStdDate = (value: any): string => {
  if (typeof value === 'string') {
    return printDate(strToDate(value))
  }
  if (isValid(value)) return format(value, standardDateFormat)
  else return ''
}

export const printStdDatetime = (value: any): string => {
  if (typeof value === 'string') {
    return printDate(strToDate(value))
  }
  if (isValid(value)) return format(value, standardDateTimeFormat)
  else return ''
}

export const printStdDatetimeSeconds = (value: any): string => {
  if (typeof value === 'string') {
    return printDate(strToDate(value))
  }
  if (isValid(value)) return format(value, standardDateTimeFormatSeconds)
  else return ''
}

export const strToDate = (str: string): Date | null => {
  try {
    return parseISO(str)
  } catch (e) {
    return null
  }
}
export const printMonthYear = (value: any): string => {
  if (typeof value === 'string') {
    return printMonthYear(strToDate(value))
  }
  if (isValid(value)) return format(value, monthYearFormat)
  else return ''
}
export const printYearDateTime = (value: any): string => {
  if (typeof value === 'string') {
    return printMonthYear(strToDate(value))
  }
  if (isValid(value)) return format(value, yearDateTime)
  else return ''
}

export const printYearMonthDayDate = (value: any): string => {
  if (typeof value === 'string') {
    return printMonthYear(strToDate(value))
  }
  if (isValid(value)) return format(value, yearMonthDayDate)
  else return ''
}

export const printDateLong = (value: any): string => {
  if (value && typeof value === 'string') {
    const x: any = strToDate(value)
    return format(x, dateTimeFormat)
  }

  if (isValid(value)) return format(value, dateTimeFormat)
  else return ''
}

export const printDateShort = (value: any): string => {
  if (value && typeof value === 'string') {
    const x: any = strToDate(value)
    return format(x, dateFormat)
  }

  if (isValid(value)) return format(value, dateFormat)
  else return ''
}

export const printDateToNIRA = (value: any): string => {
  if (value && typeof value === 'string') {
    const x: any = strToDate(value)
    return format(x, NIRADate)
  }

  if (isValid(value)) return format(value, NIRADate)
  else return ''
}

export const printStdDatetimeFileName = (value: any): string => {
  if (value && typeof value === 'string') {
    const x: any = strToDate(value)
    return format(x, standardDateTimeFormatFileName)
  }

  if (isValid(value)) return format(value, standardDateTimeFormatFileName)
  else return ''
}

export const timeAgo = (year: number): string => {
  const now = new Date()
  const currentYear = now.getFullYear()
  const yearDifference = currentYear - year

  if (yearDifference < 0) {
    return 'Invalid year'
  } else if (yearDifference === 0) {
    return "Less than a year"
  } else {
    return `${yearDifference} year${yearDifference > 1 ? 's' : ''}`
  }
}

export const formatDateToLocaleString = (dateStr: string): string => {
  if (!dateStr) return ''
  const date = new Date(dateStr)
  return date.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'})
}
const getOrdinalSuffix = (day: number): string => {
  if (day > 3 && day < 21) return 'th'
  switch (day % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

export const printDateWithOrdinal = (value: any): string => {
  if (typeof value === 'string') {
    return printDateWithOrdinal(strToDate(value))
  }
  if (isValid(value)) {
    const day = format(value, 'd')
    const monthYear = format(value, 'MMMM, yyyy')
    return `${day}${getOrdinalSuffix(parseInt(day))} ${monthYear}`
  } else return ''
}
