import  {useState} from 'react'
import {useParams} from 'react-router-dom'

import {UpdateOnboardingChecklist} from '../modals/UpdateOnboardingChecklist'
import { useGetCompanyDetailsQuery } from '../CompaniesApiSlice'


const CompanyCheckList = () => {
  const onboardingChecklist = [
    'Successfully Created Account',
    'Submitted standard business plan',
    'Submitted audited financial statements',
    'Submitted management accounts',
    'Submitted pitchdeck',
    'Submitted investor memos',
  ]
  const {id} = useParams<{id: string}>() as {id: string}
  const {data: companyState} = useGetCompanyDetailsQuery(id)
  const companyDetails = companyState?.entities[id]
  const checklistValues = companyDetails?.onboardingStatus.split(',').map(Number)
  const [showModal, setShowModal] = useState(false)
  const handleClose = () => {
    setShowModal(!showModal)
  }
  return (
    <>
      <h6 className='card-title'>Onboarding Checklist</h6>

      {onboardingChecklist.map((checklist, index) => (
        <div
          key={index}
          className='form-check form-check-custom form-check-solid form-check-lg form-check-danger'
        >
          <input
            className='form-check-input'
            type='checkbox'
            value=''
            readOnly
            checked={checklistValues && checklistValues[index] === 1}
            id='flexCheckboxLg'
          />
          <label className='form-check-label' htmlFor='flexCheckboxLg'>
            {checklist}
          </label>
        </div>
      ))}
      <button className='btn btn-danger' onClick={handleClose}>
        Update Status
      </button>
      <UpdateOnboardingChecklist showModal={showModal} handleClose={handleClose} />
    </>
  )
}

export default CompanyCheckList
