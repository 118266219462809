import React, {FC, useEffect, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_theme/helpers'
import {getInitials} from '../../../../utils/stringHelpers'
import {useParams} from 'react-router-dom'
import {
  useAddNewDocumentMutation,
  useDeleteDocumentMutation,
  useGetDocumentsQuery,
} from '../../documents/DocumentsApiSlice'
import CustomSkeleton from '../../shared/components/CustomSkeleton'
import {
  useGetServiceProviderDetailsQuery,
  useGetServiceProvidersQuery,
  useUpdateServiceProviderMutation,
} from '../ServiceProvidersApiSlice'


type Props = {
  showEditLogoModal: boolean
  handleEditLogoModal: () => void
  currentPage: number
  pageSize: number
}

export const EditServiceProviderLogo: React.FC<Props> = ({
                                                           handleEditLogoModal,
                                                           showEditLogoModal,
                                                           currentPage,
                                                           pageSize,
                                                         }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [preview, setPreview] = useState<string | undefined>(undefined)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [updateServiceProvider, {isLoading}] = useUpdateServiceProviderMutation()
  const [createDocument, {isLoading: savingLogo}] = useAddNewDocumentMutation()
  const [deleteDocument, {isLoading: deletingLogo}] = useDeleteDocumentMutation()
  useGetServiceProvidersQuery({page: currentPage, pageSize: pageSize})
  const {id} = useParams<{id: string}>() as {id: string}
  const {data: serviceProviderState} = useGetServiceProviderDetailsQuery(id)
  const serviceProviderDetails = serviceProviderState?.entities[id]
  const {data: documentsState, isLoading: gettingLogo} = useGetDocumentsQuery(
    serviceProviderDetails?.logo ? [serviceProviderDetails.logo] : [],
    {
      skip: !serviceProviderDetails?.logo,
    },
  )
  let logo: any
  if (serviceProviderDetails) {
    logo = documentsState?.entities[serviceProviderDetails.logo]
  }

  const financierInitials = getInitials(serviceProviderDetails?.name)
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files ? event.target.files[0] : null
    if (file) {
      setSelectedFile(file)
    } else {
      setSelectedFile(null)
    }
  }

  // Update the preview when selectedFile changes
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // Cleanup
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const handleUploadClick = () => {
    fileInputRef.current?.click() // Add this function
  }
  const handleCloseModal = () => {
    setSelectedFile(null)
    handleEditLogoModal()

  }

  const handleSave = async () => {
    let logoId = serviceProviderDetails?.logo

    try {
      if (selectedFile) {
        if (logoId) {
          await deleteDocument(logoId).unwrap()
        }
        const reader = new FileReader()
        reader.onloadend = async () => {  // Make sure to declare this function as async
          const base64String = reader.result as string
          const base64Content = base64String.split(',')[1]
          const fileObj = {
            fileName: selectedFile.name,
            fileType: selectedFile.type,
            fileContent: base64Content,
            dateUploaded: new Date().toISOString(),
            fileSize: selectedFile.size,
          }
          const documents = [fileObj]
          const payload = {files: documents}
          const response = await createDocument(payload).unwrap()
          logoId = response[0].id

          const updatedCompanyDetails = {
            ...serviceProviderDetails,
            logo: logoId,
          }

          await updateServiceProvider(updatedCompanyDetails).unwrap()
          setSelectedFile(null)
          handleEditLogoModal()
        }
        reader.readAsDataURL(selectedFile)  // This line is necessary to start reading the file
      }
    } catch (error) {
      console.log(error)
      handleEditLogoModal()
    }
  }

  return (
    <>
      <Modal
        id="editFinancialsModal"
        aria-hidden={true}
        dialogClassName="h-auto"
        tabIndex={-1}
        show={showEditLogoModal}
        onHide={handleEditLogoModal}
        centered
      >
        <div className="modal-content w-700px" role="document">
          <div className="modal-header">
            <h5 className="modal-title ps-1" id="editLogoModalLabel">
              Change Service Provider Photo
            </h5>
            <div
              className="btn btn-sm btn-icon btn-active-color-danger"
              data-bs-dismiss="modal"
              onClick={handleEditLogoModal}
            >
              <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-1" />
            </div>
          </div>
          <div className="modal-body d-flex align-items-center justify-content-center">
            <div className="w-250px d-flex flex-column justify-content-center align-items-center">
              {gettingLogo ? (
                <CustomSkeleton height={157} width={250} className="rounded" />
              ) : !preview ? (
                <p> No file selected </p>
              ) : (
                <img src={preview} alt="Preview" height={157} width={210} className="rounded" />
              )}
              <input
                type="file"
                ref={fileInputRef}
                className="d-none"
                accept="image/*"
                onChange={(event) => handleFileChange(event)}
              />
              <div className="d-flex justify-content-between align-items-center w-100 px-3">
                <span
                  className="btn btn-link fw-bold text-dark"
                  onClick={handleUploadClick}
                >
                  <i className="fas fa-upload"></i> Upload
                </span>
                <span
                  className="btn btn-link fw-bold text-dark"
                  onClick={() => setSelectedFile(null)}
                >
                  <i className="fas fa-trash"></i> Remove
                </span>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline btn-outline-danger btn-active-light-danger"
              onClick={handleCloseModal}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-danger"
              onClick={handleSave}
              disabled={isLoading || savingLogo || deletingLogo}
            >
                  <span className="indicator-label">
                    {!isLoading && !savingLogo && !deletingLogo ? (
                      'Save'
                    ) : (
                      <>
                        <span className="spinner-border h-15px w-15px align-middle text-white-400"></span>
                      </>
                    )}
                  </span>
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}
