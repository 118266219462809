import {FC, useState} from 'react'
import {Toolbar} from '../../../../_theme/layout/components/Toolbar'
import {SettingsSideMenu} from '../components/SettingsSideMenu'
import SectorsSettings from '../components/SectorsSettings'
import FinancingTypesSettings from '../components/FinancingTypesSettings'

const Settings: FC = () => {
  const [selectedTab, setSelectedTab] = useState('Sectors')
 
  return (
    <>
      <Toolbar page='Settings'></Toolbar>
      <div className='row px-4'>
        <div className='col-2'>
          <SettingsSideMenu selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        </div>
        <div className='col-10'>
          {selectedTab === 'Sectors' && (
            <SectorsSettings />
          )}
          {selectedTab === 'Financing Types' && (
            <FinancingTypesSettings />
          )}
        </div>
      </div>
    </>
  )
}
export default Settings
