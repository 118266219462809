import {createSelector, createEntityAdapter, EntityState, Draft} from '@reduxjs/toolkit'
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {RootState} from '../../../data/redux/Store'
import {AUTH_TOKEN_KEY, env} from '../../../data/constants'
import {
  IFinanciersResponse,
  IFinancierSector,
  IFinancierContactPerson,
  IFinancier,
  IFinancingSectorResponse,
  IFinancierContactPersonResponse,
  IFinancierDocumentMetaData,
  IFinancierDocumentFolders,
  IFinancierCountries,
  IFinancierType,
  IFinancierTypeResponse,
} from './interfaces'
import {INameIdPair, IPaginationMetadata} from '../shared/interfaces'
import {ISearchFinancierDocument} from './interfaces/ISearchFinancierDocument'

// State adapters
const FinanciersAdapter = createEntityAdapter<IFinancier>({
  selectId: (financier) => financier.id,
})
const FinancierSectorsAdapter = createEntityAdapter<IFinancierSector>({
  selectId: (financingSector) => financingSector.id,
})
const FinancierTypesAdapter = createEntityAdapter<IFinancierType>({
  selectId: (financingType) => financingType.id,
})
const financierCountriesAdapter = createEntityAdapter<IFinancierCountries>({
  selectId: (financierCountry) => financierCountry.id,
})

const FinancierContactPersonsAdapter = createEntityAdapter<IFinancierContactPerson>({
  selectId: (financierContactPerson) => financierContactPerson.id,
})
const financierDocumentsAdapter = createEntityAdapter<IFinancierDocumentMetaData>({
  selectId: (financierDocuments) => financierDocuments.id,
})
const financierDocumentFoldersAdapter = createEntityAdapter<IFinancierDocumentFolders>({
  selectId: (financierDocumentFolders) => financierDocumentFolders.id,
})

// State getters

const financiersInitialState = FinanciersAdapter.getInitialState()
const financierSectorsInitialState = FinancierSectorsAdapter.getInitialState()
const financierTypesInitialState = FinancierTypesAdapter.getInitialState()
const financierCountriesInitialState = financierCountriesAdapter.getInitialState()
const financierContactPersonsInitialState = FinancierContactPersonsAdapter.getInitialState()
const financierDocumentsInitialState = financierDocumentsAdapter.getInitialState()
const financierDocumentFolderInitialState = financierDocumentFoldersAdapter.getInitialState()

const baseQuery = fetchBaseQuery({
  baseUrl: env.Financiers,
  validateStatus: (response, result) => {
    if (result && result.isError) return false
    if (response.status >= 200 && response.status <= 300) return true
    if (response.status >= 400 && response.status <= 500) return false
    return false
  },
  prepareHeaders: (headers, {getState}) => {
    const accessToken = localStorage.getItem(AUTH_TOKEN_KEY)
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`)
    }
    return headers
  },
})

export const FinanciersApiSlice = createApi({
  reducerPath: 'financiersApi',
  baseQuery,

  tagTypes: [
    'Financiers',
    'FinancierTypes',
    'FinancierContactPersons',
    'FinancierSectors',
    'FinancierDocument',
    'FinancierDocumentFolder',
    'FinancierCountries',
  ],
  endpoints: (builder) => ({
    getFinanciers: builder.query<
      {entities: EntityState<IFinancier>; paginationMetadata: IPaginationMetadata},
      {page: number; page_size: number; query: string}
    >({
      query: ({page, page_size, query}) => ({
        url: '/financier/',
        params: {page, page_size, query},
      }),
      transformResponse: (res: IFinanciersResponse) => {
        const financiers: IFinancier[] = res.results.map((financier: any) => ({
          id: financier.id,
          createdDate: financier.created_date,
          name: financier.name,
          websiteUrl: financier.website_url,
          email: financier.email,
          phoneNumber: financier.phone_number,
          country: financier.country,
          region: financier.region,
          district: financier.district,
          dateOfIncorporation: financier.date_of_incorporation,
          facebookUrl: financier.facebook_url,
          twitterUrl: financier.twitter_url,
          linkedinUrl: financier.linkedin_url,
          instagramUrl: financier.instagram_url,
          about: financier.about,
          slogan: financier.slogan,
          logo: financier.logo,
          address: financier.address,
          equityMaximumTicketSize: financier.equity_maximum_ticket_size,
          debtMaximumTicketSize: financier.debt_maximum_ticket_size,
          grantMaximumTicketSize: financier.grant_maximum_ticket_size,
          classificationRating: financier.classification_rating,
          countries: financier.countries,
          financingSectors: financier.financing_sectors,
          currency: financier?.currency,
        }))

        const entities = FinanciersAdapter.setAll(financiersInitialState, financiers)
        return {
          entities,
          paginationMetadata: {
            totalCount: res.count,
            pageSize: 6,
            hasNextPage: res.next ? true : false,
            hasPreviousPage: res.previous ? true : false,
          },
        }
      },
      providesTags: (result: {entities: EntityState<IFinancier>} | undefined) => {
        if (result?.entities.ids) {
          return [
            {type: 'Financiers', id: 'LIST'},
            ...result.entities.ids.map((id) => ({type: 'Financiers' as const, id})),
          ]
        } else return [{type: 'Financiers', id: 'LIST'}]
      },
    }),
    addNewFinancier: builder.mutation({
      query: (initialFinancierData) => ({
        url: '/financier/',
        method: 'POST',
        body: initialFinancierData,
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'Financiers', id: 'LIST'}],
    }),
    updateFinancier: builder.mutation({
      query: (updatedFinancierData) => ({
        url: `/financier/${updatedFinancierData.id}`,
        method: 'PATCH',
        body: updatedFinancierData,
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'Financiers', id: arg.id}],
      async onQueryStarted({id, ...rest}, {dispatch, queryFulfilled}) {
        const putResult = dispatch(
          FinanciersApiSlice.util.updateQueryData(
            'getFinanciers',
            id,
            (
              draft: Draft<{
                entities: EntityState<IFinancier>
                paginationMetadata: IPaginationMetadata
              }>
            ) => {
              let financier = draft.entities[id as keyof typeof draft.entities]
              if (financier) {
                Object.assign(financier, rest)
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          putResult.undo()
        }
      },
    }),
    deleteFinancier: builder.mutation({
      query: (id) => ({
        url: `/financier/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'Financiers', id: arg.id}],
    }),
    addNewFinancierTypes: builder.mutation({
      query: (financingTypes) => ({
        url: '/types/',
        method: 'POST',
        body:financingTypes,
      }),

      invalidatesTags: [{type: 'FinancierTypes', id: 'LIST'}],
    }),
    getFinancierTypes: builder.query<EntityState<IFinancierType>, string>({
      query: (financier_Id) => ({
        url: `/types/`,
        params:{financier_id:financier_Id}
      }),
      transformResponse: (res: IFinancierTypeResponse) => {
        const financierTypes: IFinancierType[] = res.results.map((type: any) => ({
          id: type.id,
          financierId: type.financier_id,
          financingTypeId: type.financing_type_id,
        }))
        return FinancierTypesAdapter.setAll(financierTypesInitialState, financierTypes)
      },
      providesTags: (result: EntityState<IFinancierType> | undefined) => {
        if (result?.ids) {
          return [
            {type: 'FinancierTypes', id: 'LIST'},
            ...result.ids.map((id) => ({type: 'FinancierTypes' as const, id})),
          ]
        } else return [{type: 'FinancierTypes', id: 'LIST'}]
      },
    }),
    deleteFinancierTypes: builder.mutation({
      query: (id) => {
        // const params = new URLSearchParams();
        // ids.forEach((id:string) => params.append('id', id));
        return {
          url: `/types/`,
          method: 'DELETE',
          params: id,
        };
      },
      invalidatesTags: (_result, _error, arg) => [
        {type: 'FinancierTypes', id: arg.id},
      ],
    }),
    addNewFinancierSectors: builder.mutation({
      query: (financingSectors) => ({
        url: '/financing_sector/',
        method: 'POST',
        body:financingSectors,
      }),

      invalidatesTags: [{type: 'FinancierSectors', id: 'LIST'}],
    }),
    getFinancierSectors: builder.query<EntityState<IFinancierSector>, string>({
      query: (financier_Id) => ({
        url: `/financing_sector/`,
        params:{financier_id:financier_Id}
      }),
      transformResponse: (res: IFinancingSectorResponse) => {
        const financierSectors: IFinancierSector[] = res.results.map((sector: any) => ({
          id: sector.id,
          financierId: sector.financier_id,
          financingSectorId: sector.financing_sector_id,
        }))
        return FinancierSectorsAdapter.setAll(financierSectorsInitialState, financierSectors)
      },
      providesTags: (result: EntityState<IFinancierSector> | undefined) => {
        if (result?.ids) {
          return [
            {type: 'FinancierSectors', id: 'LIST'},
            ...result.ids.map((id) => ({type: 'FinancierSectors' as const, id})),
          ]
        } else return [{type: 'FinancierSectors', id: 'LIST'}]
      },
    }),
    deleteFinancierSectors: builder.mutation({
      query: (ids:string[]) => {
        const params = new URLSearchParams();
        ids.forEach((id:string) => params.append('id', id));
        return {
          url: `/financing_sector/`,
          method: 'DELETE',
          params: params,
        };
      },
      invalidatesTags: (_result, _error, arg) => arg.map((id:string) => ({type: 'FinancierSectors', id})),
    }),
    getFinancierContactPersons: builder.query<EntityState<IFinancierContactPerson>, string>({
      query: (id) => ({
        url: `/person/?financier_Id=${id}`,
      }),
      transformResponse: (res: IFinancierContactPersonResponse) => {
        return FinancierContactPersonsAdapter.setAll(
          financierContactPersonsInitialState,
          res.results.map((contact: any) => ({
            id: contact.id,
            createdDate: contact.created_date,
            full_name: contact.full_name,
            userId: contact.user_id,
            email: contact.email,
            phoneNumber: contact.phone_number,
            role: contact.role,
            financier: contact.financier,
            address: contact.address,
          }))
        )
      },
      providesTags: (result: EntityState<IFinancierContactPerson> | undefined) => {
        if (result?.ids) {
          return [
            {type: 'FinancierContactPersons', id: 'LIST'},
            ...result.ids.map((id) => ({type: 'FinancierContactPersons' as const, id})),
          ]
        } else return [{type: 'FinancierContactPersons', id: 'LIST'}]
      },
    }),
    addNewFinancierContactPerson: builder.mutation({
      query: (initialFinancierContactPersonData) => ({
        url: '/person/',
        method: 'POST',
        body: initialFinancierContactPersonData,
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'FinancierContactPersons', id: arg.id}],
    }),
    updateFinancierContactPerson: builder.mutation({
      query: (updatedFinancierContactPersonData) => ({
        url: `/person/${updatedFinancierContactPersonData.id}`,
        method: 'PATCH',
        body: updatedFinancierContactPersonData,
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'FinancierContactPersons', id: arg.id}],

      async onQueryStarted({id, ...rest}, {dispatch, queryFulfilled}) {
        const putResult = dispatch(
          FinanciersApiSlice.util.updateQueryData('getFinancierContactPersons', id, (draft) => {
            let financierContactPerson = draft.entities[id]
            if (financierContactPerson) {
              Object.assign(financierContactPerson, rest)
            }
          })
        )
        try {
          await queryFulfilled
        } catch {
          putResult.undo()
        }
      },
    }),
    addNewFinancierDocument: builder.mutation({
      query: (documentMetaData) => ({
        url: '/document/',
        method: 'POST',
        body: documentMetaData,
      }),
      invalidatesTags: (_result, _error) => [
        {type: 'FinancierDocument', id: 'LIST'},
        {type: 'FinancierDocumentFolder', id: 'LIST'},
      ],
    }),
    getFinancierDocuments: builder.query<
      {entities: EntityState<IFinancierDocumentMetaData>; paginationMetadata: IPaginationMetadata},
      ISearchFinancierDocument
    >({
      query: ({financierId, folderId, searchTerm}) => ({
        url: '/document/',
        params: {financier_id: financierId, folder_id: folderId, query: searchTerm},
      }),
      transformResponse: (res: any) => {
        const entities = financierDocumentsAdapter.setAll(
          financierDocumentsInitialState,
          res.results.map((doc: any) => ({
            id: doc.id,
            fileName: doc.file_name,
            fileType: doc.file_type,
            folderId: doc.folder,
            fileSize: doc.file_size,
            financierId: doc.financier_id,
            dateUploaded: new Date().toISOString(),
            documentAssetId: doc.document_asset_id,
          }))
        )
        return {
          entities,
          paginationMetadata: {
            totalCount: res.count,
            pageSize: 6,
            hasNextPage: res.next ? true : false,
            hasPreviousPage: res.previous ? true : false,
          },
        }
      },
      providesTags: (result: {entities: EntityState<IFinancierDocumentMetaData>} | undefined) => {
        if (result?.entities.ids) {
          return [
            {type: 'FinancierDocument', id: 'LIST'},
            ...result.entities.ids.map((id) => ({type: 'FinancierDocument' as const, id})),
          ]
        } else return [{type: 'FinancierDocument', id: 'LIST'}]
      },
    }),
    deleteFinancierDocument: builder.mutation({
      query: ({id, folderId}) => ({
        url: `/document/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [
        {type: 'FinancierDocument', id: arg.id},
        {type: 'FinancierDocumentFolder', id: arg.folderId},
      ],
    }),
    getFinancierDocumentFolders: builder.query<
      {entities: EntityState<IFinancierDocumentFolders>; paginationMetadata: IPaginationMetadata},
      {id: string; searchTerm: string}
    >({
      query: ({id, searchTerm}) => ({
        url: '/folder/',
        params: {financier_id: id, query: searchTerm},
      }),
      transformResponse: (res: any) => {
        const entities = financierDocumentFoldersAdapter.setAll(
          financierDocumentFolderInitialState,
          res.results.map((folder: any) => ({
            id: folder.id,
            folderName: folder.name,
            fileCount: folder.document_count,
            totalFileSize: folder.total_file_size,
            financierId: folder.financier,
          }))
        )
        return {
          entities,
          paginationMetadata: {
            totalCount: res.count,
            pageSize: 6,
            hasNextPage: res.next ? true : false,
            hasPreviousPage: res.previous ? true : false,
          },
        }
      },
      providesTags: (result: {entities: EntityState<IFinancierDocumentFolders>} | undefined) => {
        if (result?.entities.ids) {
          return [
            {type: 'FinancierDocumentFolder', id: 'LIST'},
            ...result.entities.ids.map((id) => ({type: 'FinancierDocumentFolder' as const, id})),
          ]
        } else return [{type: 'FinancierDocumentFolder', id: 'LIST'}]
      },
    }),
    addNewFinancierDocumentFolder: builder.mutation({
      query: (documentFolderData) => ({
        url: '/folder/',
        method: 'POST',
        body: documentFolderData,
      }),
      invalidatesTags: (_result, _error) => [{type: 'FinancierDocumentFolder', id: 'LIST'}],
    }),
    updateFinancierDocumentFolder: builder.mutation({
      query: (updatedFolderData) => ({
        url: `/folder/${updatedFolderData.id}`,
        method: 'PATCH',
        body: updatedFolderData,
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'FinancierDocumentFolder', id: arg.id}],
    }),
    deleteFinancierDocumentFolder: builder.mutation({
      query: (id) => ({
        url: `/folder/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'FinancierDocumentFolder', id: arg.id}],
    }),
    getFinancierCountries: builder.query<
      {entities: EntityState<IFinancierCountries>; paginationMetadata: IPaginationMetadata},
      void
    >({
      query: () => ({
        url: '/country/',
      }),
      transformResponse: (res: IFinanciersResponse) => {
        const financierCountries: IFinancierCountries[] = res.results.map((country: any) => ({
          id: country.id,
          name: country.country_name,
        }))

        const entities = financierCountriesAdapter.setAll(
          financierCountriesInitialState,
          financierCountries
        )
        return {
          entities,
          paginationMetadata: {
            totalCount: res.count,
            pageSize: 6,
            hasNextPage: res.next ? true : false,
            hasPreviousPage: res.previous ? true : false,
          },
        }
      },
      providesTags: (result: {entities: EntityState<IFinancierCountries>} | undefined) => {
        if (result?.entities.ids) {
          return [
            {type: 'FinancierCountries', id: 'LIST'},
            ...result.entities.ids.map((id) => ({type: 'FinancierCountries' as const, id})),
          ]
        } else return [{type: 'FinancierCountries', id: 'LIST'}]
      },
    }),
    addNewFinancierCountry: builder.mutation({
      query: (initialCountryData) => ({
        url: '/country/',
        method: 'POST',
        body: initialCountryData,
      }),
      invalidatesTags: (_result, _error) => [{type: 'FinancierCountries', id: 'LIST'}],
    }),
    updateFinancierCountry: builder.mutation({
      query: (updatedCountryData) => ({
        url: `/country/`,
        method: 'PATCH',
        params: {id: updatedCountryData.id},
        body: updatedCountryData,
      }),
      invalidatesTags: (_result, _error) => [{type: 'Financiers', id: 'LIST'}],
    }),
    deleteFinancierCountry: builder.mutation({
      query: ({id}) => ({
        url: `/country/`,
        method: 'DELETE',
        params: {id},
      }),
      invalidatesTags: (_result, _error, arg) => [{type: 'Financiers', id: arg.id}],
    }),
  }),
})

export const {
  //Financier
  useGetFinanciersQuery,
  useAddNewFinancierMutation,
  useUpdateFinancierMutation,
  useDeleteFinancierMutation,
  //Financier sector
  useGetFinancierSectorsQuery,
  useAddNewFinancierSectorsMutation,
  useDeleteFinancierSectorsMutation,
  //Financier types
  useAddNewFinancierTypesMutation,
  useGetFinancierTypesQuery,
  useDeleteFinancierTypesMutation,
  //Financier Contact person
  useGetFinancierContactPersonsQuery,
  useAddNewFinancierContactPersonMutation,
  useUpdateFinancierContactPersonMutation,
  //Document management
  useAddNewFinancierDocumentMutation,
  useAddNewFinancierDocumentFolderMutation,
  useGetFinancierDocumentsQuery,
  useGetFinancierDocumentFoldersQuery,
  useUpdateFinancierDocumentFolderMutation,
  useDeleteFinancierDocumentFolderMutation,
  useDeleteFinancierDocumentMutation,
  //Financier Countries
  useGetFinancierCountriesQuery,
  useAddNewFinancierCountryMutation,
  useUpdateFinancierCountryMutation,
  useDeleteFinancierCountryMutation,
} = FinanciersApiSlice

//Financiers selectors

export const selectFinanciersResult = (
  state: RootState,
  {page, page_size, query}: {page: number; page_size: number; query: string}
) => FinanciersApiSlice.endpoints.getFinanciers.select({page, page_size, query})(state)

export const selectFinanciersData = (params: {page: number; page_size: number; query: string}) =>
  createSelector(
    (state: RootState) => selectFinanciersResult(state, params),
    (financiersResult) => financiersResult?.data?.entities ?? financiersInitialState
  )

export const createFinancierSelectors = (page: number, page_size: number, query: string) => {
  const selectData = selectFinanciersData({page, page_size, query})
  return FinanciersAdapter.getSelectors((state: RootState) => selectData(state))
}
//Financier countries selectors

export const selectFinancierCountriesResult = (state: RootState) =>
  FinanciersApiSlice.endpoints.getFinancierCountries.select()(state)

export const selectFinancierCountriesData = () =>
  createSelector(
    (state: RootState) => selectFinancierCountriesResult(state),
    (financierCountriesResult) =>
      financierCountriesResult?.data?.entities ?? financierCountriesInitialState
  )

export const createFinancierCountriesSelectors = () => {
  const selectData = selectFinancierCountriesData()
  return financierCountriesAdapter.getSelectors((state: RootState) => selectData(state))
}


