import * as Yup from 'yup'

export const createFinancierSchema = Yup.object({
  generalInformation: Yup.object().shape({
    name: Yup.string().required('Financier Name is required').label('Name'),
    about: Yup.string().nullable().label('About'),
    country: Yup.string().nullable().label('Country'),
    region: Yup.string().nullable().label('Region'),
    logo: Yup.string().nullable().label('Logo'),
  }),
  financials: Yup.object().shape({
    financingTypes: Yup.array().of(Yup.string()).nullable(),
    maximumEquity: Yup.string().nullable(),
    maximumDebt: Yup.string().nullable(),
    maximumGrant: Yup.string().nullable(),
    currency: Yup.string().nullable(),
  }),
  contactPerson: Yup.object().shape({
    name: Yup.string().required('Contact Person Name is required').label('Name'),
    email: Yup.string().nullable('Contact Person Email is required').label('Email'),
    phoneNumber: Yup.string()
      .nullable('Contact Person PhoneNumber is required')
      .matches(/^[0-9]{9}$/, 'Phone number must be 9 digits')
      .label('Phone Number'),
    address: Yup.string().nullable().label('Address'),
  }),
})
