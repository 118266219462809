import {useParams} from 'react-router-dom'
import EditButton from '../../shared/components/EditButton'
import {FC} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import {
  createFinancierSelectors,
  useGetFinancierContactPersonsQuery,
  useGetFinanciersQuery,
} from '../FinanciersApiSlice'
import {IFinancierContactPerson} from '../interfaces'
import {EditFinancierContactInformation} from '../modals/EditFinancierContactInformation'
import LineSpan from '../../shared/components/LineSpan'

type FinancierContactInformationProps = {
  handleClose: () => void
  showModal: boolean
  currentPage: number
  pageSize: number
}

const FinancierContactInformation: FC<FinancierContactInformationProps> = ({
  handleClose,
  showModal,
  currentPage,
  pageSize,
}) => {
  const {id} = useParams<{id: string}>() as {id: string}
  useGetFinanciersQuery({page: currentPage, page_size: pageSize, query:''})
  const {selectById: selectFinancierById} = createFinancierSelectors(currentPage, pageSize,'')
  const financierDetails = useSelector((state: RootState) => selectFinancierById(state, id))
  const {data: financierContactsState, isLoading: financierContactsLoading} =
    useGetFinancierContactPersonsQuery(id)
  let financierContact: IFinancierContactPerson | null | undefined = null
  if (financierContactsState?.entities) {
    const contactsArray = Object.values(financierContactsState?.entities)
    financierContact = contactsArray.length > 0 ? contactsArray[0] : null
  }
  return (
    <div className='position-relative'>
      <div className='card card-flush card-bordered mt-4 mb-4'>
        <div className='card-body py-5'>
          <div className='row'>
            <div className='col-md-6'>
              <h3 className='fs-8'>Facebook</h3>
              <p className='fs-6'>{financierDetails?.facebookUrl || <LineSpan />}</p>
            </div>
            <div className='col-md-6'>
              <h3 className='fs-8'>LinkedIn</h3>
              <p className='fs-6'>{financierDetails?.linkedinUrl || <LineSpan />}</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <h3 className='fs-8'>Twitter</h3>
              <p className='fs-6'>{financierDetails?.twitterUrl || <LineSpan />}</p>
            </div>
            <div className='col-md-6'>
              <h3 className='fs-8'>Website URL</h3>
              <p className='fs-6'>{financierDetails?.websiteUrl || <LineSpan />}</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <h3 className='fs-8'>Name of Contact Person</h3>
              <p className='fs-6'>{financierContact?.full_name || <LineSpan />}</p>
            </div>
            <div className='col-md-6'>
              <h3 className='fs-8'>Email</h3>
              <p className='fs-6'>{financierContact?.email || <LineSpan />}</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <h3 className='fs-8'>Phone Number</h3>
              <p className='fs-6'>{financierContact?.phoneNumber || <LineSpan />}</p>
            </div>
            <div className='col-md-6'>
              <h3 className='fs-8'>Address Line</h3>
              <p className='fs-6'>{financierContact?.address || <LineSpan />}</p>
            </div>
          </div>
        </div>
      </div>
      <EditButton handleClose={handleClose} />
      <EditFinancierContactInformation
        showModal={showModal}
        handleClose={handleClose}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </div>
  )
}

export default FinancierContactInformation
