import React, {useEffect, useState, useRef} from 'react'
import {pdfjs, Document, Page} from 'react-pdf'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import {useParams} from 'react-router-dom'
import {useGetDocumentsQuery} from '../../documents/DocumentsApiSlice'
import Loading from '../components/Loading'

const FilePreview = () => {
  const [totalPages, setTotalPages] = useState(0)
  const {id} = useParams<{id: string}>() as {id: string}
  const {data: documentsState} = useGetDocumentsQuery([id])
  const file = documentsState?.entities[id]
  let fileName = ''
  let blobUrl = ''
  const containerRef = useRef<HTMLDivElement>(null)

  if (file) {
    try {
      const byteCharacters = atob(file.fileContent)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], {type: file.fileType})
      blobUrl = URL.createObjectURL(blob)

      // Extract file extension from MIME type
      fileName = file.fileName
    } catch (error) {
      console.error('Error creating Blob URL:', error)
    }
  }

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  }, [])

  function onDocLoad(event: any) {
    console.log('Pdf loaded: ', event.numPages)
    setTotalPages(event.numPages)
  }

  function onDocError(error: any) {
    console.error('Failed to load PDF file:', error)
  }

  const handleDownload = () => {
    if (file) {
      const link = document.createElement('a')
      link.href = blobUrl
      link.download = file.fileName
      link.click()
    }
  }

  return (
    <div className='d-flex w-100 h-100vh overflow-hidden'>
      <div className='w-100 h-100'>
        <div className='w-100 bg-light h-100'>
          <div className='bg-white h-16 py-2 px-4 d-flex justify-content-between align-items-center'>
            <div className='fw-semibold fs-5'>{fileName}</div>
            <button type='button' className='btn btn-danger btn-sm' onClick={handleDownload}>
              <span className='ms-1'>Download</span>
            </button>
          </div>
          <div
            className='w-100 bg-light p-4 h-100 overflow-auto d-flex justify-content-center align-items-start'
            ref={containerRef}
          >
            {blobUrl ? (
              <Document
                file={blobUrl}
                onLoadError={onDocError} 
                onLoadSuccess={onDocLoad}
              >
                {Array.from({ length: totalPages }, (_, index) => (
                  <Page key={index} pageNumber={index + 1} />
                ))}
              </Document>
            ) : (
              <div className='d-flex justify-content-center align-items-center h-100'>
                <Loading />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FilePreview