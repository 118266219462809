import {useState} from 'react'
import {ServiceProviderHeader} from '../components'
import {ServiceProviderProjects} from '../components'
import {ServiceProviderContactInformation} from '../components'
import {ServiceProviderOperations} from '../components'
import {useLocation} from 'react-router-dom'
import {getPaginationState} from '../../../../utils/getPaginationState'
import {Toolbar} from '../../../../_theme/layout/components/Toolbar'
import ServiceProviderDocumentsList from '../components/ServiceProviderDocumentsList'
import {ServiceProviderDocumentFolderCard} from '../components/ServiceProviderDocumentFolderCard'

export const ServiceProviderDetails = () => {
  const location = useLocation()
  const {currentPage, pageSize, searchTerm} = getPaginationState(location)

  const [selectedTab, setSelectedTab] = useState('Business Operations')

  const [showModal, setShowModal] = useState(false)
  const [openFolder, setOpenFolder] = useState(false)
  const [selectedFolder, setSelectedFolder] = useState<{
    index: number
    id: string
    folderName: string
    parentFolderId?: string | null
  }>({
    index: 0,
    id: '',
    folderName: '',
    parentFolderId: '',
  })

  const handleFolderToggle = () => {
    setOpenFolder(!openFolder)
  }

  const handleClose = () => {
    setShowModal(!showModal)
  }

  return (
    <>
      <Toolbar
        page='View Service Provider Details'
        link='/services/providers'
        buttonTitle='Back'
      ></Toolbar>

      <div className='row'>
        <div className='col-lg-12'>
          <ServiceProviderHeader
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            pageSize={pageSize}
            currentPage={currentPage}
            searchTerm={searchTerm}
          />
        </div>
        <div className='col-lg-12'>
          <div className='card card-flush shadow-sm mt-5'>
            <div className='card-body py-5'>
              {selectedTab === 'Business Operations' && (
                <ServiceProviderOperations
                  handleClose={handleClose}
                  showModal={showModal}
                  pageSize={pageSize}
                  currentPage={currentPage}
                />
              )}
              {selectedTab === 'Contact Information' && (
                <ServiceProviderContactInformation
                  handleClose={handleClose}
                  showModal={showModal}
                  pageSize={pageSize}
                  currentPage={currentPage}
                />
              )}
              {selectedTab === 'Documents' &&
                (selectedFolder.index === 0 ? (
                  <ServiceProviderDocumentFolderCard
                    handleFolderToggle={handleFolderToggle}
                    setSelectedFolder={setSelectedFolder}
                    selectedFolder={selectedFolder}
                    showModal={showModal}
                    handleClose={handleClose}
                  />
                ) : (
                  <ServiceProviderDocumentsList
                    selectedFolder={selectedFolder}
                    setSelectedFolder={setSelectedFolder}
                  />
                ))}
              {selectedTab === 'Projects' && <ServiceProviderProjects />}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
