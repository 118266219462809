import React, {useState, useEffect} from 'react'
import {useField, useFormikContext} from 'formik'

interface Option {
  code: string
  dial_code: string
  image: string
}

interface CustomSelectProps {
  name: string
  options: Option[]
  style?: string
  setCountryCode:(code:string) => void
  countryCode?:string;
}

export const CountryCustomSelect: React.FC<CustomSelectProps> = ({name, options, style,setCountryCode,countryCode='+256'}) => {
  const [field, meta, helpers] = useField({name, value: '+256'}) // default value set to +256
  const {setFieldValue} = useFormikContext()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (!field.value) {
      setFieldValue(name, countryCode)
    }
  }, [field.value, setFieldValue, name])

  const handleSelect = (dial_code: string) => {
    setFieldValue(name, dial_code)
    setCountryCode(dial_code) 
    setIsOpen(false)
  }

  const selectedOption = options.find((option) => option.dial_code === field.value)

  return (
    <div className="input-group-prepend p-0" style={{position: 'relative'}}>
      <div
        className={`form-select form-select-sm form-select-solid rounded-end-0 ${style}`}
        style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedOption && (
          <>
            {selectedOption.dial_code}
            <img
              src={selectedOption.image}
              alt="flag"
              style={{width: '25px', height: '20px', marginLeft: '8px'}}
            />
          </>
        )}
      </div>
      {isOpen && (
        <ul
          className="dropdown-menu show"
          style={{
            position: 'absolute',
            top: '100%',
            left: '0',
            right: '0',
            maxHeight: '200px',
            overflowY: 'auto',
            zIndex: 1000,
            listStyle: 'none',
            padding: 0,
            margin: 0,
          }}
        >
          {options.map((option) => (
            <li
              key={option.code}
              onClick={() => handleSelect(option.dial_code)}
              className="dropdown-item"
              style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
            >
              {option.dial_code}
              <img
                src={option.image}
                alt="flag"
                style={{width: '25px', height: '20px', marginLeft: '8px'}}
              />
            </li>
          ))}
        </ul>
      )}
      {meta.touched && meta.error ? <div className="text-danger mt-2">{meta.error}</div> : null}
    </div>
  )
}
