/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import {
  useCheckNameAvailabilityMutation,
  useGetCompanyDetailsQuery,
  useGetSectorsQuery,
  useUpdateCompanyMutation,
} from '../CompaniesApiSlice'
import {KTSVG} from '../../../../_theme/helpers'
import {useParams} from 'react-router-dom'
import {INameIdPair} from '../../shared/interfaces'
import {MultiSelect} from '../../shared/components/MultiSelect'
import NumberFormatter from '../../shared/components/NumberFormatter'
type Props = {
  showModal: boolean
  handleClose: () => void
}

export const EditCompanyOperation: React.FC<Props> = ({showModal, handleClose}) => {
  const companyOperationValidationSchema = Yup.object().shape({
    summary: Yup.string().required('Company about is required'),
    yearOfIncorporation: Yup.number()
      .nullable()
      .label('Year of Incorporation')
      .min(1900, 'Year must be 1900 or later')
      .max(new Date().getFullYear(), `Year must be ${new Date().getFullYear()} or earlier`)
      .typeError('Year must be a valid number')
      .test('len', 'Year must be exactly 4 digits', (val) => !val || val.toString().length === 4),
    subSector: Yup.string().nullable(),
    productLine: Yup.string().nullable(),
    numberOfEmployees: Yup.string().nullable(),
    numberOfFemaleEmployees: Yup.string().nullable(),
    numberOfYouthEmployees: Yup.string().nullable(),
    averageIncomeByBeneficiaries: Yup.string().nullable(),
    averageIncomeByBeneficiariesCurrency: Yup.string().nullable(),
    sectors: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required(),
          name: Yup.string().required(),
        })
      )
      .nullable(),
    name: Yup.string().required('Company Name is required'),
    slogan: Yup.string().nullable(),
  })
  const [companyNameStatus, setCompanyNameStatus] = useState<boolean | null>(null)
  const {data: sectorsState} = useGetSectorsQuery()
  const companySectorsList = Object.values(sectorsState?.entities || {}).filter(
    (type): type is INameIdPair => Boolean(type)
  )

  const [updateCompany, {isLoading}] = useUpdateCompanyMutation()
  const [checkCompanyName, {isLoading: isCheckingCompanyName}] = useCheckNameAvailabilityMutation()
  const [originalName, setOriginalName] = useState<string | null>(null)
  const {id} = useParams<{id: string}>() as {id: string}
  const {data: companyState} = useGetCompanyDetailsQuery(id)
  const companyDetails = companyState?.entities[id]

  const initialValues = {
    summary: companyDetails?.summary,
    subSector: companyDetails?.subSector,
    productLine: companyDetails?.productLine,
    numberOfEmployees: companyDetails?.numberOfEmployees,
    numberOfFemaleEmployees: companyDetails?.numberOfFemaleEmployees,
    numberOfYouthEmployees: companyDetails?.numberOfYouthEmployees,
    averageIncomeByBeneficiaries: companyDetails?.averageIncomeByBeneficiaries,
    averageIncomeByBeneficiariesCurrency: companyDetails?.averageIncomeByBeneficiariesCurrency ?? '0',
    sectors: companyDetails?.sectors,
    name: companyDetails?.name,
    slogan: companyDetails?.slogan,
    yearOfIncorporation:companyDetails?.yearOfIncorporation,
  }

  useEffect(() => {
    if (companyDetails?.name) {
      setOriginalName(companyDetails.name)
    }
  }, [companyDetails])

  const handleCheckName = async (name: string) => {
    if (name === originalName) {
      setCompanyNameStatus(null)
      return
    }
    try {
      await checkCompanyName({name}).unwrap()
      setCompanyNameStatus(true)
    } catch (error) {
      setCompanyNameStatus(false)
    }
  }
  const handleSave = async (values: any) => {
    try {
      const updatedCompanyDetails = {
        ...companyDetails,
        summary: values.summary,
        yearOfIncorporation: values.yearOfIncorporation,
        subSector: values.subSector,
        productLine: values.productLine,
        numberOfEmployees: values.numberOfEmployees.replace(/,/g, '') || null,
        numberOfFemaleEmployees: values.numberOfFemaleEmployees.replace(/,/g, '') || null,
        numberOfYouthEmployees: values.numberOfYouthEmployees.replace(/,/g, '') || null,
        averageIncomeByBeneficiaries: values.averageIncomeByBeneficiaries.replace(/,/g, '') || null,
        averageIncomeByBeneficiariesCurrency:
          Number(values.averageIncomeByBeneficiariesCurrency) ?? 1,
        name: values.name,
        slogan: values.slogan,
        sectors: values.sectors,
      }

      await updateCompany(updatedCompanyDetails).unwrap()
      handleClose()
    } catch (error) {
      console.error('Failed to update company details:', error)
    }
  }

  return (
    <Modal
      id='kt_edit_company_operation_modal'
      aria-hidden='true'
      dialogClassName='h-auto modal-dialog-centered'
      show={showModal}
      onHide={handleClose}
    >
      <div className='modal-content w-700px' role='document'>
        <Formik
          initialValues={initialValues}
          validationSchema={companyOperationValidationSchema}
          onSubmit={handleSave}
        >
          {({values, errors, touched, isValid, setFieldValue}) => {
            return (
              <Form>
                <div className='modal-header'>
                  <h5 className='modal-title'>Edit Company Operation</h5>
                  <div
                    className='btn btn-sm btn-icon btn-active-color-danger'
                    onClick={handleClose}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                  </div>
                </div>
                <div className='modal-body'>
                  <div className='row'>
                    <div className='col-md-6 mb-3'>
                      <label htmlFor='name' className='form-label required'>
                        Company Name
                      </label>
                      <Field
                        type='text'
                        name='name'
                        className='form-control form-control-sm'
                        style={{color: '#616162', fontWeight: 300}}
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                          handleCheckName(e.target.value)
                        }
                      />
                      {isCheckingCompanyName && (
                        <div className='text-warning mt-2'>Checking name availability...</div>
                      )}
                      {companyNameStatus === false && (
                        <div className='mt-2 text-danger'>Company already exists</div>
                      )}
                      {touched.name && <div className='text-danger mt-2'>{errors.name}</div>}
                    </div>

                    <div className='col-md-6 mb-3'>
                      <label htmlFor='slogan' className='form-label'>
                        Company Slogan
                      </label>
                      <Field
                        type='text'
                        name='slogan'
                        className='form-control form-control-sm'
                        style={{color: '#616162', fontWeight: 300}}
                      />
                      {touched.slogan && <div className='text-danger mt-2'>{errors.slogan}</div>}
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label
                        htmlFor='summary'
                        className='d-flex align-items-center fs-5 fw-bold mb-2 required'
                      >
                        About
                      </label>
                      <Field
                        as='textarea'
                        className='form-control form-control-sm'
                        style={{color: '#616162', fontWeight: 300}}
                        name='summary'
                        placeholder=''
                      />
                      {touched.summary && <div className='text-danger mt-2'>{errors.summary}</div>}
                    </div>

                    <div className='col-md-6 mb-3'>
                      <label className='fs-5 fw-bold mb-2'>Year of Incorporation</label>
                      <Field
                        type='number'
                        className='form-control form-control-sm'
                        style={{color: '#616162', fontWeight: 300}}
                        name='yearOfIncorporation'
                        placeholder=''
                      />
                      {touched.yearOfIncorporation && (
                        <div className='text-danger mt-2'>{errors.yearOfIncorporation}</div>
                      )}
                    </div>
                    <div className='col-md-6 mb-3'>
                      <label className='d-flex align-items-center'>
                        <span className='fs-5 fw-bold mb-2'>Sector(s)</span>
                      </label>

                      <MultiSelect
                        selectedValues={values.sectors || []}
                        options={companySectorsList}
                        setOption={(selectedOptions) => setFieldValue('sectors', selectedOptions)}
                      />
                    </div>

                    <div className='col-md-6 mb-3'>
                      <label htmlFor='subSector' className='form-label'>
                        Sub sector
                      </label>
                      <Field
                        type='text'
                        name='subSector'
                        className='form-control form-control-sm'
                        style={{color: '#616162', fontWeight: 300}}
                      />
                      {touched.subSector && (
                        <div className='text-danger mt-2'>{errors.subSector}</div>
                      )}
                    </div>

                    <div className='col-md-6 mb-3'>
                      <label htmlFor='productLine' className='form-label'>
                        Product Line
                      </label>
                      <Field
                        type='text'
                        name='productLine'
                        className='form-control form-control-sm'
                        style={{color: '#616162', fontWeight: 300}}
                      />
                      {touched.productLine && (
                        <div className='text-danger mt-2'>{errors.productLine}</div>
                      )}
                    </div>

                    <div className='col-md-6 mb-3'>
                      <label htmlFor='numberOfEmployees' className='form-label'>
                        Number of Employees
                      </label>
                      <NumberFormatter
                        name='numberOfEmployees'
                        className='form-control-sm currency-picker'
                        setFieldValue={setFieldValue}
                        initialValue={values.numberOfEmployees}
                      />
                      {touched.numberOfEmployees && (
                        <div className='text-danger mt-2'>{errors.numberOfEmployees}</div>
                      )}
                    </div>

                    <div className='col-md-6 mb-3'>
                      <label htmlFor='numberOfFemaleEmployees' className='form-label'>
                        Number of Female Employees
                      </label>
                      <NumberFormatter
                        name='numberOfFemaleEmployees'
                        className='form-control-sm currency-picker'
                        setFieldValue={setFieldValue}
                        initialValue={values.numberOfFemaleEmployees}
                      />
                      {touched.numberOfFemaleEmployees && (
                        <div className='text-danger mt-2'>{errors.numberOfFemaleEmployees}</div>
                      )}
                    </div>

                    <div className='col-md-6 mb-3'>
                      <label htmlFor='numberOfYouthEmployees' className='form-label'>
                        Number of Youth Employees
                      </label>
                      <NumberFormatter
                        name='numberOfYouthEmployees'
                        className='form-control-sm currency-picker'
                        setFieldValue={setFieldValue}
                        initialValue={values.numberOfYouthEmployees}
                      />
                      {touched.numberOfYouthEmployees && (
                        <div className='text-danger mt-2'>{errors.numberOfYouthEmployees}</div>
                      )}
                    </div>

                    <div className='col-md-6 mb-3'>
                      <label
                        htmlFor='averageIncomeByBeneficiaries'
                        className='d-flex align-items-center fs-5 fw-bold mb-2'
                      >
                        Average Income earned by beneficiaries
                      </label>
                      <div className='input-group mb-3 col-md-6'>
                        <div className='input-group-prepend'>
                          <Field
                            as='select'
                            name='averageIncomeByBeneficiariesCurrency'
                            className='form-select form-select-sm form-select-solid rounded-end-0'
                            style={{color: '#616162', fontWeight: 300}}
                            multiple={false}
                          >
                            <option value='1'>UGX</option>
                            <option value='0'>USD</option>
                            <option value='2'>EUR</option>
                          </Field>
                        </div>
                        <NumberFormatter
                          name='averageIncomeByBeneficiaries'
                          className='form-control-sm border-start-0 rounded-start-0 currency-picker'
                          setFieldValue={setFieldValue}
                          initialValue={values.averageIncomeByBeneficiaries}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <button type='button' className='btn btn-secondary' onClick={handleClose}>
                    Close
                  </button>
                  <button
                    type='submit'
                    className='btn btn-danger'
                    disabled={
                      !isValid || isLoading || isCheckingCompanyName || companyNameStatus === false
                    }
                  >
                    <span className='indicator-label'>
                      {!isLoading ? (
                        'Save'
                      ) : (
                        <>
                          <span className='spinner-border h-15px w-15px align-middle text-white-400'></span>
                        </>
                      )}
                    </span>
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </Modal>
  )
}
