import {FC, useEffect, useMemo, useState} from 'react'
import Search from '../../shared/components/Search'
import {useParams} from 'react-router-dom'
import {
  useAddNewCompanyDocumentMutation,
  useAddNewDocumentFolderMutation,
  useDeleteCompanyDocumentMutation,
  useDeleteDocumentFolderMutation,
  useGetCompanyDocumentsQuery,
  useGetDocumentFoldersQuery,
  useUpdateDocumentFolderMutation,
} from '../CompaniesApiSlice'
import {ICompanyDocumentMetaData} from '../interfaces/ICompanyDocumentMetaData'
import {printDateWithOrdinal} from '../../../../utils/dateHelpers'
import {formatBytes} from '../../../../utils/numberHelpers'
import {trimString} from '../../../../utils/stringHelpers'
import {fileExtensionIcons} from '../../shared/constants'
import {ICompanyDocumentFolders} from '../interfaces'
import {OptionsPopup} from '../../shared/components/OptionsPopup'
import {useDeleteDocumentMutation, useGetDocumentsQuery} from '../../documents/DocumentsApiSlice'
import {UpdateFolder} from '../../shared/components/UpdateFolder'
import {DeleteModal} from '../../shared/components/DeleteModal'
import { CreateFolder } from '../../shared/modals/CreateFolder'
import { UploadDocument } from '../../shared/modals/UploadDocument'

type props = {
  selectedFolder: {index: number; id: string; folderName: string; parentFolderId?: string | null}
  setSelectedFolder: (folder: {
    index: number
    id: string
    folderName: string
    parentFolderId?: string | null
  }) => void
}
const CompanyDocumentsList: FC<props> = ({selectedFolder, setSelectedFolder}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedChildFolder, setSelectedChildFolder] = useState<{
    index: number,
    id: string,
    folderName: string
  }>({index:0, id: '', folderName: ''})
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null)
  const [updateFolder, {isLoading: updatingFolder}] = useUpdateDocumentFolderMutation()
  const [showDeleteFileModal, setShowDeleteFileModal] = useState(false)
  const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false)
  const [showEditFolderModal, setShowEditFolderModal] = useState<boolean>(false)
  const [showUploadDocumentModal, setShowUploadDocumentModal] = useState(false)
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false)
  const [selectedEntityId, setSelectedEntityId] = useState('')
  const [triggerDownload, setTriggerDownload] = useState(false)
  const {id} = useParams<{id: string}>() as {id: string}
  const [folderHistory, setFolderHistory] = useState<
    Array<{
      index: number
      id: string
      folderName: string
      parentFolderId?: string | null
    }>
  >([])

  const [deleteDocument, {isLoading: isDeletingDocument}] = useDeleteDocumentMutation()
  const [addCompanyFolder, {isLoading:creatingCompanyFolder}] = useAddNewDocumentFolderMutation()
  const {data: companyDocumentsState} = useGetCompanyDocumentsQuery({
    companyId: id,
    folderId: selectedFolder.id,
    searchTerm,
  })
  const [deleteCompanyDocument, {isLoading: isDeletingCompanyDocument}] =
    useDeleteCompanyDocumentMutation()
  const [deleteFolder, {isLoading: deletingFolder}] = useDeleteDocumentFolderMutation()
  const {data: companyDocumentFoldersState, isLoading: isGettingFolders} =
    useGetDocumentFoldersQuery({
      companyId: id,
      searchTerm,
      parentFolderId: selectedFolder.id,
    })
  const {data: parentFolderState, isLoading: isGettingParentFolder} =
    useGetDocumentFoldersQuery({
      id: selectedFolder.parentFolderId ?? '',
    })
  const [saveDocumentMetaData, {isLoading: isSavingDocumentMetaData}] = useAddNewCompanyDocumentMutation()
  const parentFolder = selectedFolder.parentFolderId
    ? parentFolderState?.entities[selectedFolder.parentFolderId]
    : null
  const childFolders: ICompanyDocumentFolders[] = Object.values(
    companyDocumentFoldersState?.entities || {}
  ) as ICompanyDocumentFolders[]
  const companyDocuments: ICompanyDocumentMetaData[] = Object.values(
    companyDocumentsState?.entities || {}
  ) as ICompanyDocumentMetaData[]


  const {data: documentsState, isFetching: gettingFile} = useGetDocumentsQuery([selectedEntityId], {
    skip: !triggerDownload,
  })
  const file = useMemo(
    () => documentsState?.entities[selectedEntityId],
    [documentsState, selectedEntityId]
  )
  useEffect(() => {
    if (triggerDownload && file) {
      const byteCharacters = atob(file.fileContent)
      const byteArray = new Uint8Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArray[i] = byteCharacters.charCodeAt(i)
      }
      const blob = new Blob([byteArray], {type: file.fileType})
      const blobUrl = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = blobUrl
      link.download = file.fileName
      link.click()

      URL.revokeObjectURL(blobUrl)
      setTriggerDownload(false)
      setOpenDropdownId(null)
    }
  }, [triggerDownload, file])

  const documents = companyDocuments
    ? companyDocuments.map((document) => {
        const [name, extension] = document.documentName.split('.')
        return {
          ...document,
          documentName: name,
          documentExtension: extension,
          uploadedDate: printDateWithOrdinal(document.dateUploaded),
          size: formatBytes(document.documentSize),
        }
      })
    : []

  const handleFileOptionsClick = (id: string) => {
    setSelectedEntityId(id)
    setOpenDropdownId(id)
  }
  const handleChildFolderOptionsClick = (folder: {index:number, id: string; folderName: string}) => {
    if (selectedChildFolder?.folderName === '') {
      setSelectedChildFolder(folder)
    }
  }
  const handleCreateFolderModal = () => {
    setShowCreateFolderModal(!showCreateFolderModal)
  }
  const handleEditFolderModal = () => {
    setShowEditFolderModal(!showEditFolderModal)
  }
  const handleUpdateFolder = async (name: string) => {
    try {
      await updateFolder({
        folderName: name,
        companyId: id,
        id: selectedChildFolder?.id,
        parentFolderId: selectedFolder?.id,
      }).unwrap()
      setSelectedChildFolder({index:0, id: '', folderName: ''})
      handleEditFolderModal()
    } catch (error) {}
  }

  const handleDeleteFolder = async () => {
    try {
      await deleteFolder(selectedChildFolder?.id).unwrap()
      setSelectedChildFolder({index:0, id: '', folderName: ''})
      handleDeleteFolderModal()
    } catch (error) {}
  }

  const handleUploadFileModal = () => {
    setShowUploadDocumentModal(!showUploadDocumentModal)
  }
  const handleDeleteModal = () => {
    setShowDeleteFileModal(!showDeleteFileModal)
    
  }
  const handleDeleteFolderModal = () => {
    setShowDeleteFolderModal(!showDeleteFolderModal)
  }

  const handleDeleteFile = async () => {
    try {
      await Promise.all([
        deleteDocument(selectedEntityId).unwrap(),
        deleteCompanyDocument({id: selectedEntityId,folderId:selectedFolder.id}).unwrap(),
      ])
      setSelectedEntityId('')
      handleDeleteModal()
    } catch (error) {}
  }
  const handlePreview = () => {
    window.open(`/file-preview/${selectedEntityId}`, '_blank')
  }
  const handleDownload = () => {
    setTriggerDownload(true)
  }
  const handleBackClick = () => {
    if (folderHistory.length > 0) {
      const previousFolder = folderHistory.pop()
      if (previousFolder) {
        setFolderHistory([...folderHistory])
        setSelectedFolder(previousFolder)
      }
    } else {
      setSelectedFolder({
        index: 0,
        id: '',
        folderName: '',
        parentFolderId: '',
      })
    }
  }
  const handleChildFolderClick = (folder: ICompanyDocumentFolders) => {
    setFolderHistory([...folderHistory, selectedFolder])
    setSelectedFolder({
      index: selectedFolder.index + 1,
      id: folder.id,
      folderName: folder.folderName,
      parentFolderId: folder.parentFolderId,
    })
  }

  const handleUploadFile = async (res:any) => {
      try{
        const documentsMetaData = res.map((doc: any) => {
          return {
            DocumentName: doc.fileName,
            DocumentType: doc.fileType,
            FolderId: selectedEntityId,
            dateUploaded: doc.dateUploaded,
            DocumentSize: doc.fileSize,
            id: doc.id,
            companyId: id,
          }
        })
        await saveDocumentMetaData(documentsMetaData).unwrap()
        handleUploadFileModal()
      }
      catch(err){
        console.log(err)
      }
  }

  const handleCreateFolder = async (name: string) => {
    try {
      await addCompanyFolder({
        folderName: name,
        companyId: id,
        ...(selectedEntityId && {parentFolderId: selectedEntityId}),
      }).unwrap()
      handleCreateFolderModal()
    } catch (error) {}
  }

  return (
    <>
      <div className='card card-flush card-bordered mt-4 mb-4'>
        <div className='card-body py-5'>
          <div className='d-flex align-items-center justify-content-between position-relative'>
            <div>
              <span className='fs-6 fw-bold hover-bg text-hover-danger' onClick={handleBackClick}>
                {selectedFolder.index > 1 ? (
                  <>
                    <i className='fas fa-arrow-left fs-8 me-1 text-dark'></i>{' '}
                    {trimString(parentFolder?.folderName ?? '', 12, true)}
                  </>
                ) : (
                  <>
                    <i className='fas fa-arrow-left fs-8 me-1 text-dark'></i> Documents
                  </>
                )}
              </span>
              /
              <span className='fs-7 fw-bold'>
                {trimString(selectedFolder?.folderName ?? '', 5, true)}
              </span>
            </div>
            <div className='d-flex align-items-center gap-3'>
              <Search setSearchTerm={setSearchTerm} />
              <div onClick={() => setSelectedEntityId(selectedFolder?.id)}>
                <OptionsPopup
                  selectedEntity={selectedEntityId}
                  setSelectedEntity={setSelectedEntityId}
                  isButton={true}
                  includeCreateFolder={true}
                  includeUploadFile={true}
                  handleCreateFolderModal={handleCreateFolderModal}
                  handleUploadFileModal={handleUploadFileModal}
                />
              </div>
            </div>
          </div>
          <div className='table-like'>
            <div className='table-header fw-bold fs-6 border-bottom pb-4'>
              <div className='header-item'>Name</div>
              <div className='header-item'>Size</div>
              <div className='header-item'>Uploaded On</div>
              <div className='header-item'></div>
            </div>
            {childFolders.map((folder, index) => (
              <div
                key={folder.id}
                className={`table-row ${index < childFolders.length - 1 ? 'border-bottom' : ''}`}
              >
                <div className='row-item'>
                  <div
                    className='d-flex align-items-center gap-1 cursor-pointer hover-bg'
                    onClick={() => handleChildFolderClick(folder)}
                  >
                    <i className={`fa ${fileExtensionIcons['folder']} me-2`}></i>
                    <span>{folder.folderName}</span>
                  </div>
                </div>
                <div className='row-item'>{formatBytes(folder.totalDocumentSize)}</div>
                <div className='row-item'>{printDateWithOrdinal(folder.dateCreated)}</div>
                <div className='row-item'>
                  <div className='position-relative'>
                    <div
                      className='d-flex justify-content-end p-2 cursor-pointer'
                      onClick={() =>
                        handleChildFolderOptionsClick({
                          index,
                          id: folder.id,
                          folderName: folder.folderName,
                        })
                      }
                    >
                      <OptionsPopup
                        selectedEntity={selectedChildFolder}
                        setSelectedEntity={setSelectedChildFolder}
                        handleDelete={handleDeleteFolder}
                        handleUpdateEntity={handleUpdateFolder}
                        updatingEntity={updatingFolder}
                        showDeleteModal={showDeleteFolderModal}
                        showEditModal={showEditFolderModal}
                        deletingEntity={deletingFolder}
                        handleDeleteModal={handleDeleteFolderModal}
                        handleEditModal={handleEditFolderModal}
                        entity='Folder'
                        includeFilePreviewOption={false}
                        includeUpdateOption={true}
                        includeDeleteOption={true}
                        isEllipsis={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {documents.map((document, index) => (
              <div
                key={index}
                className={`table-row ${index < documents.length - 1 ? 'border-bottom' : ''}`}
              >
                <div className='row-item'>
                  <div className='d-flex align-items-center gap-1'>
                    <i className={`fa ${fileExtensionIcons[document.documentExtension]} me-2`}></i>
                    <span>{document.documentName}</span>
                  </div>
                </div>
                <div className='row-item'>{document.size}</div>
                <div className='row-item'>{document.uploadedDate}</div>
                <div className='row-item'>
                  <div className='position-relative'>
                    <div
                      className='d-flex justify-content-end p-2 cursor-pointer'
                      onClick={() => handleFileOptionsClick(document?.id)}
                    >
                      <OptionsPopup
                        selectedEntity={selectedEntityId}
                        setSelectedEntity={setSelectedEntityId}
                        deletingEntity={isDeletingDocument}
                        handleDeleteModal={handleDeleteModal}
                        entity='File'
                        handlePreview={handlePreview}
                        includeFilePreviewOption={true}
                        includeUpdateOption={false}
                        includeDownloadOption={true}
                        includeDeleteOption={true}
                        triggerDownload={triggerDownload}
                        gettingFile={gettingFile && openDropdownId === document.id}
                        handleDownload={handleDownload}
                        isEllipsis={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <CreateFolder
        showCreateFolderModal={showCreateFolderModal}
        handleCreateFolderModal={handleCreateFolderModal}
        handleCreateFolder={handleCreateFolder}
        isLoading={creatingCompanyFolder}
      />
      <UpdateFolder
          showEditModal={showEditFolderModal}
          selectedFolder={selectedChildFolder}
          setSelectedFolder={setSelectedChildFolder}
          handleEditModal={handleEditFolderModal }
          isLoading={updatingFolder}
          handleUpdateFolder={handleUpdateFolder}
        />
      <DeleteModal
        showDeleteModal={showDeleteFileModal || showDeleteFolderModal}
        isLoading={isDeletingDocument || deletingFolder}
        handleDelete={showDeleteFolderModal? handleDeleteFolder : handleDeleteFile}
        item={showDeleteFolderModal ? 'Folder' : 'File'}
        handleClose={showDeleteFolderModal ? handleDeleteFolderModal: handleDeleteModal}
      />
       <UploadDocument
          showUploadDocumentModal={showUploadDocumentModal}
          handleUploadFileModal={handleUploadFileModal}
          handleUploadFile={handleUploadFile}
          isSavingDocumentMetaData={isSavingDocumentMetaData}
        />
    </>
  )
}

export default CompanyDocumentsList
