import React from 'react'
import { TableBarChartDataItem } from '../charts/TableBarChartDataItem'
import { DataItem } from '../../interfaces'

type Props = {
    cardTitle: string
    cardSubTitle?: string
    dataLabelTitle?: string
    dataBarsTitle?: string
    dataItems?: DataItem[]
}

export const TableBarChartCard: React.FC<Props> = (props) => {

    const {
        cardTitle,
        cardSubTitle,
        dataLabelTitle,
        dataBarsTitle,
        dataItems,
    } = props

    return (
        <>
            <div className="card card-flush h-xl-95">
                <div className="card-header pt-5 pb-0 mb-0">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-gray-800">
                            {cardTitle}
                        </span>
                        <span className="text-gray-400 pt-2 fw-semibold fs-6">
                            {cardSubTitle}
                        </span>
                    </h3>
                </div>
                <div className="card-body d-flex justify-content-between flex-column py-3">
                    <div className="mt-0 pt-0" />
                    <div className="table-responsive mb-n2 pb-2 mt-0">
                        <table className="table table-row-dashed gs-0 gy-4">
                            <thead>
                                <tr className="fs-7 fw-bold border-0 text-gray-400">
                                    <th className="min-w-300px">{dataLabelTitle}</th>
                                    <th className="min-w-100px">{dataBarsTitle}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataItems &&
                                    dataItems.map((item, index) => (
                                        <TableBarChartDataItem
                                            key={index}
                                            dataItemName={item.dataItemName}
                                            dataItemNumber={item.dataItemNumber}
                                        />
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}