import React from 'react';

interface Props {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  entriesCount: number;
  entriesPerPage: number;
}

const Pagination: React.FC<Props> = ({ currentPage, totalPages, onPageChange, entriesCount, entriesPerPage }) => {
  const handlePageChange = (page: number) => {
    onPageChange(page);
  };

  const startIndex = (currentPage - 1) * entriesPerPage + 1;
  const endIndex = Math.min(startIndex + entriesPerPage - 1, entriesCount);

  const renderPageNumbers = () => {
    const pageNumbers = [];

    if (totalPages <= 6) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <button
            key={i}
            className={`btn ${currentPage === i ? 'btn-danger btn-sm active' : 'btn-light'}`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }
    } else {
      // Always show the first page
      pageNumbers.push(
        <button
          key={1}
          className={`btn ${currentPage === 1 ? 'btn-danger btn-sm active' : 'btn-light'}`}
          onClick={() => handlePageChange(1)}
        >
          1
        </button>
      );

      // Show pages 2 and 3
      if (currentPage <= 3) {
        for (let i = 2; i <= 3; i++) {
          pageNumbers.push(
            <button
              key={i}
              className={`btn ${currentPage === i ? 'btn-danger btn-sm active' : 'btn-light'}`}
              onClick={() => handlePageChange(i)}
            >
              {i}
            </button>
          );
        }
        pageNumbers.push(<span key="right-ellipsis" className="btn">...</span>);
      } else if (currentPage > 3 && currentPage < totalPages - 2) {
        pageNumbers.push(<span key="left-ellipsis" className="btn">...</span>);
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pageNumbers.push(
            <button
              key={i}
              className={`btn ${currentPage === i ? 'btn-danger btn-sm active' : 'btn-light'}`}
              onClick={() => handlePageChange(i)}
            >
              {i}
            </button>
          );
        }
        pageNumbers.push(<span key="right-ellipsis" className="btn">...</span>);
      } else {
        pageNumbers.push(<span key="left-ellipsis" className="btn">...</span>);
        for (let i = totalPages - 2; i <= totalPages - 1; i++) {
          pageNumbers.push(
            <button
              key={i}
              className={`btn ${currentPage === i ? 'btn-danger btn-sm active' : 'btn-light'}`}
              onClick={() => handlePageChange(i)}
            >
              {i}
            </button>
          );
        }
      }

      // Always show the last page
      pageNumbers.push(
        <button
          key={totalPages}
          className={`btn ${currentPage === totalPages ? 'btn-danger btn-sm active' : 'btn-light'}`}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className='fs-5 fw-bold mb-2'>
        Showing {startIndex} to {endIndex} of {entriesCount} entries
      </div>
      <div>
        <button className="btn btn-light" onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}>
          <span className="fas fa-angle-left"></span>
        </button>
        {renderPageNumbers()}
        <button className="btn btn-light" onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}>
          <span className="fas fa-angle-right"></span>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
