import React, {FC} from 'react'

const CompanyDeals: FC = () => {
  return (
    <div className='card card-flush card-bordered mt-4 mb-4'>
      <div className='card-body py-5'></div>
    </div>
  )
}

export default CompanyDeals
