import {FC} from 'react'

type CardDetailProps = {
  title: string
  value: string
  showYearOfOperationIcon?: boolean
  showLocationIcon?: boolean
}

const CardDetail: FC<CardDetailProps> = ({
  title,
  value,
  showLocationIcon = false,
  showYearOfOperationIcon = false,
}) => {
  return (
    <div className='border border-gray-600 border-dashed rounded min-w-125px py-3 px-2 me-4 mb-3'>
      <div className='fs-9 fw-bold text-gray-500'>
        {showYearOfOperationIcon && <i className='fas fa-calendar-alt me-2'></i>}
        {showLocationIcon && <i className='fas fa-map-marker-alt me-2'></i>}
        {title}
      </div>
      <div className='fs-8 text-gray-800 fw-bolder'>{value}</div>
    </div>
  )
}

export default CardDetail
