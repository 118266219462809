interface SearchProps {
  setSearchTerm: (search: string) => void
}
const Search = ({ setSearchTerm}: SearchProps) => {
  const handleSearch = (event: any) => {
    if (event.target.value === '') {
        setSearchTerm('')
    }

    if (event.keyCode === 13) {
        setSearchTerm(event.target.value)
    }
}
  return (
    <div className='box'>
      <i className='fa fa-search fs-7' aria-hidden='true' />
      <input 
      type='text' 
      placeholder='Search...'
      role='search'
      onKeyUp={(event) => handleSearch(event)}
      className="fs-7"
      />
    </div>
  )
}

export default Search
