interface IKeyValuePair {
  [key: string]: string | number
}
export const fileExtensionIcons: IKeyValuePair = {
  pdf: 'fa-file-pdf text-danger fs-2x',
  docx: 'fa-file-word text-primary fs-2x',
  xlsx: 'fa-file-excel text-success fs-2x',
  pptx: 'fa-file-powerpoint text-warning fs-2x',
  png: 'fa-file-image text-info fs-2x',
  jpg: 'fa-file-image text-info fs-2x',
  jpeg: 'fa-file-image text-info fs-2x',
  gif: 'fa-file-image text-info fs-2x',
  txt: 'fa-file-alt text-secondary fs-2x',
  csv: 'fa-file-csv text-secondary fs-2x',
  zip: 'fa-file-archive text-muted fs-2x',
  folder: 'fa-folder text-warning fs-2x',
  openFolder: 'fa-folder-open text-warning fs-2x',
}

export const currencies:IKeyValuePair = {
    UGX:'UGX',
    USD:'USD',
    EUR:'EUR'
}
export const currencyNumberPairs:IKeyValuePair = {
  UGX:1,
  USD:0,
  EUR:2
}
export const numberCurrencyPairs: { [key: number]: string } = Object.fromEntries(
  Object.entries(currencyNumberPairs).map(([key, value]) => [value, key])
);
