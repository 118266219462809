import React, {FC} from 'react'
import Modal from 'react-bootstrap/Modal'
import {Formik, Form,Field} from 'formik'
import * as Yup from 'yup'
import {KTSVG} from '../../../../_theme/helpers'

type Props = {
  showCreateModal: boolean
  handleCreateModal: () => void
  handleSave: (name:string) => void
  isLoading:boolean
  section:string
}
const InitialValues = {
  name: '',
}


export const AddSettingItem: React.FC<Props> = ({handleCreateModal, showCreateModal,isLoading,handleSave,section}) => {
  
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`A ${section.toLowerCase()} name is required`),
  })

  return (
    <>
      <Modal
        id='createSettingModal'
        aria-hidden={true}
        dialogClassName="h-auto me-5 w-350px"
        tabIndex={-1}
        show={showCreateModal}
        onHide={handleCreateModal}
        centered
      >
        <div className='modal-content' role='document'>
          <Formik
            initialValues={InitialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSave(values.name)}
          >
            {({errors, touched, isValid, values}) => (
              <Form>
                <div className='modal-header'>
                  <h5 className='modal-title ps-1' id='createFolderModalLabel'>
                    Add a New {section}
                  </h5>
                  <div
                    className='btn btn-sm btn-icon btn-active-color-danger'
                    data-bs-dismiss='modal'
                    onClick={handleCreateModal}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                  </div>
                </div>
                <div className='modal-body d-flex flex-column align-items-start justify-content-center'>
                  <label htmlFor="name" className='pb-2'>{section} name</label>
                  <Field
                    type='text'
                    name='name'
                    className='form-control'
                  />
                  {touched?.name && (
                    <div className='text-danger mt-2'>{errors?.name}</div>
                  )}
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-outline btn-sm btn-outline-danger btn-active-light-danger'
                    onClick={handleCreateModal}
                  >
                    Close
                  </button>
                  <button type='submit' className='btn btn-danger btn-sm' disabled={!isValid || isLoading}>
                    <span className='indicator-label'>
                      {!isLoading ? (
                        'Create'
                      ) : (
                        <span className='spinner-border h-15px w-15px align-middle text-white-400'></span>
                      )}
                    </span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  )
}
