import React from 'react'
import Modal from 'react-bootstrap/Modal'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import {KTSVG} from '../../../../_theme/helpers'

type Props = {
  showEditModal: boolean
  handleEditModal: () => void
  setSelectedFolder: (folder: {
    index: number
    id: string
    folderName: string
    parentFolderId: string | null
  }) => void
  selectedFolder: {index: number; id: string; folderName: string}
  isLoading: boolean
  handleUpdateFolder: (foldername: string) => void
}

const validationSchema = Yup.object().shape({
  folderName: Yup.string().required('Folder name is required'),
})

export const UpdateFolder: React.FC<Props> = ({
  showEditModal,
  selectedFolder,
  handleEditModal,
  isLoading,
  setSelectedFolder,
  handleUpdateFolder,
}) => {
  const handleClose = () => {
    setSelectedFolder({index: 0, id: '', folderName: '', parentFolderId: null})
    handleEditModal()
  }
  const handleSave = async (values: any) => {
    handleUpdateFolder(values.folderName)
  }
  const FolderFormInitialValues = {
    folderName: selectedFolder?.folderName,
  }

  return (
    <>
      <Modal
        id='updateFolderModal'
        aria-hidden={true}
        dialogClassName='h-auto mt-20'
        tabIndex={-1}
        show={showEditModal}
        onHide={handleClose}
        centered
      >
        <div className='modal-content' role='document'>
          <Formik
            initialValues={FolderFormInitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSave}
            enableReinitialize={true}
          >
            {({errors, touched, isValid, values}) => (
              <Form>
                <div className='modal-header'>
                  <h5 className='modal-title ps-1' id='createFolderModalLabel'>
                    Rename Folder
                  </h5>
                  <div
                    className='btn btn-sm btn-icon btn-active-color-danger'
                    data-bs-dismiss='modal'
                    onClick={handleClose}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                  </div>
                </div>
                <div className='modal-body d-flex flex-column align-items-start justify-content-center'>
                  <Field
                    type='text'
                    name='folderName'
                    className='form-control'
                    placeholder='Folder Name'
                  />
                  {touched?.folderName && (
                    <div className='text-danger mt-2'>{errors?.folderName}</div>
                  )}
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-outline btn-sm btn-outline-danger btn-active-light-danger'
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  <button
                    type='submit'
                    className='btn btn-danger btn-sm'
                    disabled={!isValid || isLoading}
                  >
                    <span className='indicator-label'>
                      {!isLoading ? (
                        'Save'
                      ) : (
                        <span className='spinner-border h-15px w-15px align-middle text-white-400'></span>
                      )}
                    </span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  )
}
