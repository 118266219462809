import {ReducersMapObject, configureStore} from '@reduxjs/toolkit'
import {CompaniesApiSlice} from '../../app/features/companies/CompaniesApiSlice'
import {ServiceProvidersApiSlice} from '../../app/features/serviceProviders/ServiceProvidersApiSlice'
import {FinanciersApiSlice} from '../../app/features/financiers/FinanciersApiSlice'
import {DocumentsApiSlice} from '../../app/features/documents/DocumentsApiSlice'
import settingsReducer from './settingsReducer'


const reducers: ReducersMapObject = {
  [CompaniesApiSlice.reducerPath]: CompaniesApiSlice.reducer,
  [ServiceProvidersApiSlice.reducerPath]: ServiceProvidersApiSlice.reducer,
  [FinanciersApiSlice.reducerPath]: FinanciersApiSlice.reducer,
  [DocumentsApiSlice.reducerPath]: DocumentsApiSlice.reducer,
  settings: settingsReducer, 
}


const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
  }).concat(CompaniesApiSlice.middleware, ServiceProvidersApiSlice.middleware, FinanciersApiSlice.middleware, DocumentsApiSlice.middleware),
  devTools: process.env.NODE_ENV !== 'production',

})

export type RootState = ReturnType<typeof store.getState>


export default store

