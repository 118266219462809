/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'



type Props = {
    cardTitle: string
    cardSubTitle?: string
    cardPercentage?: number
    cardDisplayedNumber?: number
    cardDispayedValue?: number
    itemlist?: string[]
    cardIcon?: string
}

export const IconListItemCard: React.FC<Props> = (props) => {

    const {
        cardTitle,
        cardSubTitle,
        cardPercentage,
        cardDisplayedNumber,
        cardDispayedValue,
        cardIcon,
    } = props

    const iconString: string = `bi ${cardIcon} fs-1x`

    return (
        <>
            <div className="w-100">
                <div className="d-flex align-items-center">
                    <div className="symbol symbol-30px me-5">
                        <span className="symbol-label">
                            <i className={iconString}></i>
                        </span>
                    </div>
                    <div className="d-flex align-items-center flex-stack flex-wrap d-grid gap-1 flex-row-fluid">
                        <div className="me-5">
                            <a
                                href="#"
                                className="text-gray-800 fw-bold text-hover-primary fs-6"
                            >
                                {cardTitle}
                            </a>
                            <span className="text-gray-400 fw-semibold fs-7 d-block text-start ps-0">
                                {cardSubTitle}
                            </span>
                        </div>
                        <div className="d-flex align-items-center">
                        <span className="text-gray-800 fw-bold fs-4 me-3">{cardDisplayedNumber?.toLocaleString()} / </span> 
                        <span className="text-gray-400 fw-bold fs-4 me-3">${cardDispayedValue?.toLocaleString()}</span>
                            <span className="badge badge-light-success fs-base">
                                <i className="ki-outline ki-arrow-up fs-5 text-success ms-n1" />
                                {cardPercentage}%
                            </span>
                        </div>
                    </div>
                </div>
                <div className="separator separator-dashed my-3" />
            </div>
        </>
    )
}


