import {useParams} from 'react-router-dom'
import {
  useGetServiceProviderContactsQuery,
  useGetServiceProvidersQuery,
  createServiceProviderSelectors,
} from '../ServiceProvidersApiSlice'
import EditButton from '../../shared/components/EditButton'
import {FC} from 'react'
import {EditContactInformation} from '../modals/EditContactInformation'
import {IServiceProviderContacts} from '../interfaces'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import LineSpan from '../../shared/components/LineSpan'

type ServiceProviderContactInformationProps = {
  handleClose: () => void
  showModal: boolean
  pageSize: number
  currentPage: number
}

const ServiceProviderContactInformation: FC<ServiceProviderContactInformationProps> = ({
  handleClose,
  showModal,
  pageSize,
  currentPage,
}) => {
  const {id} = useParams<{id: string}>() as {id: string}
  useGetServiceProvidersQuery({page: currentPage, pageSize: pageSize})
  const {selectById: selectServiceProviderById} = createServiceProviderSelectors({
    page:currentPage,
    pageSize,
  })
  const serviceProviderDetails = useSelector((state: RootState) =>
    selectServiceProviderById(state, id)
  )
  const {data: serviceProviderContactsState, isLoading: serviceProviderContactsLoading} =
    useGetServiceProviderContactsQuery(id)

  let serviceProviderContact: IServiceProviderContacts | null | undefined = null
  if (serviceProviderContactsState?.entities) {
    const contactsArray = Object.values(serviceProviderContactsState?.entities)
    serviceProviderContact = contactsArray.length > 0 ? contactsArray[0] : null
  }

  return (
    <div className='position-relative'>
      <div className='card card-flush card-bordered mt-4 mb-4'>
        <div className='card-body py-5'>
          <div className='row'>
            <div className='col-md-6'>
              <h3 className='fs-8'>Country</h3>
              <p className='fs-6'>{serviceProviderDetails?.country || <LineSpan />}</p>
            </div>

            <div className='col-md-6'>
              <h3 className='fs-8'>District</h3>
              <p className='fs-6'>{serviceProviderDetails?.district || <LineSpan />}</p>
            </div>

            <div className='col-md-6'>
              <h3 className='fs-8'>Address</h3>
              <p className='fs-6'>{serviceProviderDetails?.address || <LineSpan />}</p>
            </div>
            <div className='col-md-6'>
              <h3 className='fs-8'>Facebook</h3>
              <p className='fs-6'>{serviceProviderDetails?.facebook || <LineSpan />}</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <h3 className='fs-8'>LinkedIn</h3>
              <p className='fs-6'>{serviceProviderDetails?.linkedIn || <LineSpan />}</p>
            </div>
            <div className='col-md-6'>
              <h3 className='fs-8'>Twitter</h3>
              <p className='fs-6'>{serviceProviderDetails?.twitter || <LineSpan />}</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <h3 className='fs-8'>Website URL</h3>
              <p className='fs-6'>{serviceProviderDetails?.website || <LineSpan />}</p>
            </div>
            <div className='col-md-6'>
              <h3 className='fs-8'>Name of Contact Person</h3>
              <p className='fs-6'>
                {serviceProviderContact?.serviceProviderContactPersonName || <LineSpan />}
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <h3 className='fs-8'>Email</h3>
              <p className='fs-6'>
                {serviceProviderContact?.serviceProviderContactPersonEmail || <LineSpan />}
              </p>
            </div>
            <div className='col-md-6'>
              <h3 className='fs-8'>Phone Number</h3>
              <p className='fs-6'>
                {serviceProviderContact?.serviceProviderContactPersonTelephone || <LineSpan />}
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <h3 className='fs-8'>Address Line</h3>
              <p className='fs-6'>
                {serviceProviderContact?.serviceProviderContactPersonAddress || <LineSpan />}
              </p>
            </div>
          </div>
        </div>
      </div>
      <EditButton handleClose={handleClose} />
      <EditContactInformation
        showModal={showModal}
        handleClose={handleClose}
        pageSize={pageSize}
        currentPage={currentPage}
      />
    </div>
  )
}

export {ServiceProviderContactInformation}
