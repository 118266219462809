/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'


type Props = {
  cardDisplayedNumber: number
  cardLabel: string
  avatorListLength: number
  avatorListLabel: string
}

export const FaceAvatorListCard: React.FC<Props> = (props) => {

  const {
    cardDisplayedNumber,
    cardLabel,
    avatorListLength,
    avatorListLabel,
  } = props


  return (
    <>
      <div className="card card-flush h-md-50 mb-xl-10">
        <div className="card-header pt-5">
          <div className="card-title d-flex flex-column">
                        <span className="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">
                            {cardDisplayedNumber}k
                        </span>
            <span className="text-gray-400 pt-1 fw-semibold fs-6">
                            {cardLabel}
                        </span>
          </div>
        </div>
        <div className="card-body d-flex flex-column justify-content-end pe-0">

          <div className="symbol-group symbol-hover flex-nowrap">
            <div
              className="symbol symbol-35px symbol-circle"
              data-bs-toggle="tooltip"
              data-bs-original-title="Alan Warden"
              data-kt-initialized={1}
            >
                            <span className="symbol-label bg-warning text-inverse-warning fw-bold">
                                A
                            </span>
            </div>
            <div
              className="symbol symbol-35px symbol-circle"
              data-bs-toggle="tooltip"
              data-bs-original-title="Susan Redwood"
              data-kt-initialized={1}
            >
                            <span className="symbol-label bg-primary text-inverse-primary fw-bold">
                                S
                            </span>
            </div>

            <a
              href="#"
              className="symbol symbol-35px symbol-circle"
            >
                            <span className="symbol-label bg-light text-gray-400 fs-8 fw-bold">
                                +{avatorListLength}
                            </span>
            </a>
          </div>
        </div>
      </div>
      {' '}
    </>
  )
}


