import {FC, ReactNode} from 'react'
import {Link, useNavigate} from 'react-router-dom'


interface ToolbarProps {
  children?: ReactNode
  page?: string
  link?: string
  buttonTitle?: string
}

const Toolbar: FC<ToolbarProps> = ({children, page, link, buttonTitle}) => {
  const navigate = useNavigate();
  return (
    <div className='toolbar' id='kt_toolbar'>
      <div
        id='kt_toolbar_container'
        className='d-flex align-items-center justify-content-between px-10 w-100'
      >
        <h1 className='text-dark fw-bolder my-1 fs-3'>{page}</h1>
        <div className='d-flex gap-2'>
          {children}
          {buttonTitle === 'Back' ? (
            <button
              className='btn btn-sm ms-lg-3 btn-danger d-flex align-items-center justify-content-center'
              onClick={() => navigate(-1)}
            >
              <i className='fas fa-arrow-left'></i> Back
            </button>
          ) : (
            link && (
              <Link
                to={link}
                className='btn btn-sm ms-lg-3 btn-danger d-flex align-items-center justify-content-center'
              >
                {buttonTitle}
              </Link>
            )
          )}
        </div>
      </div>
    </div>
  )
}
export {Toolbar}
