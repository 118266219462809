import {FC} from 'react'
import {useParams} from 'react-router-dom'
import EditButton from '../../shared/components/EditButton'
import {EditFinancierFinancials} from '../modals'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import {createFinancierSelectors, useGetFinanciersQuery} from '../FinanciersApiSlice'
import LineSpan from '../../shared/components/LineSpan'

type FinancierFinancialsProps = {
  handleClose: () => void
  showModal: boolean
  currentPage: number
  pageSize: number
}

const FinancierFinancials: FC<FinancierFinancialsProps> = ({
  handleClose,
  showModal,
  currentPage,
  pageSize,
}) => {
  const {id} = useParams<{id: string}>() as {id: string}
  useGetFinanciersQuery({page: currentPage, page_size: pageSize, query:''})
  const {selectById: selectFinancierById} = createFinancierSelectors(currentPage, pageSize,'')
  const financierDetails = useSelector((state: RootState) => selectFinancierById(state, id))
  const financingTypes = [
    (financierDetails?.debtMaximumTicketSize ?? 0) > 0 ? 'Debt' : '',
    (financierDetails?.grantMaximumTicketSize ?? 0) > 0 ? 'Grant' : '',
    (financierDetails?.equityMaximumTicketSize ?? 0) > 0 ? 'Equity' : '',
  ].filter((type) => type)
  return (
    <div className='position-relative'>
      <div className='card card-flush card-bordered mt-4 mb-4'>
        <div className='card-body py-5'>
          <div className='row pb-2'>
            <div className='col-md-6'>
              <h3 className='fs-8'>Financing Types</h3>
              <span className='fs-6'>
                {financingTypes?.length
                  ? financingTypes
                      .filter((type) => type)
                      .map((type, index) => (
                        <span key={index}>
                          {type}
                          {index < financingTypes.length - 1 && ', '}
                        </span>
                      ))
                  : <LineSpan />}
              </span>
            </div>

            <div className='col-md-6'>
              <h3 className='fs-8'>Maximum Ticket Size (Debt)</h3>
              <p className='fs-6'>
                 {financierDetails?.currency} {Number(financierDetails?.debtMaximumTicketSize ?? 0).toLocaleString()}
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <h3 className='fs-8'>Maximum Ticket Size (Equity)</h3>
              <p className='fs-6'>
                 {financierDetails?.currency} {Number(financierDetails?.equityMaximumTicketSize ?? 0).toLocaleString()}
              </p>
            </div>
            <div className='col-md-6'>
              <h3 className='fs-8'>Maximum Ticket Size (Grant)</h3>
              <p className='fs-6'>
                 {financierDetails?.currency} {Number(financierDetails?.grantMaximumTicketSize ?? 0).toLocaleString()}
              </p>
            </div>
          </div>
        </div>
      </div>
      <EditButton handleClose={handleClose} />
      <EditFinancierFinancials
        showModal={showModal}
        handleClose={handleClose}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </div>
  )
}

export default FinancierFinancials
