import {FC} from 'react'
import {formatDateToLocaleString} from '../../../../utils/dateHelpers'
import {useParams} from 'react-router-dom'
import {
  createServiceProviderSelectors,
  useGetServiceProvidersQuery,
} from '../ServiceProvidersApiSlice'
import EditButton from '../../shared/components/EditButton'
import {EditBusinessOperations} from '../modals/EditBusinessOperations'
import getEntitySize from '../../../../utils/getEntitySize'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import LineSpan from '../../shared/components/LineSpan'
import {numberCurrencyPairs} from '../../shared/constants'

type ServiceProviderOperationsProps = {
  handleClose: () => void
  showModal: boolean
  pageSize: number
  currentPage: number
}

const ServiceProviderOperations: FC<ServiceProviderOperationsProps> = ({
  handleClose,
  showModal,
  pageSize,
  currentPage,
}) => {
  const {id} = useParams<{id: string}>() as {id: string}
  useGetServiceProvidersQuery({page: currentPage, pageSize: pageSize})
  const {selectById: selectServiceProviderById} = createServiceProviderSelectors({
    page: currentPage,
    pageSize,
  })
  const serviceProviderDetails = useSelector((state: RootState) =>
    selectServiceProviderById(state, id)
  )
  return (
    <div className='position-relative'>
      <div className='card card-flush card-bordered mt-4 mb-4'>
        <div className='card-body py-5'>
          <div className='row pb-2'>
            <div className='col-md-12'>
              <h3 className='fs-8'>About</h3>
              <p className='fs-6'>{serviceProviderDetails?.about || <LineSpan />}</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <h3 className='fs-8'>Slogan</h3>
              <p className='fs-6'>{serviceProviderDetails?.slogan || <LineSpan />}</p>
            </div>
            <div className='col-md-6'>
              <h3 className='fs-8'>Year of Incorporation</h3>
              <p className='fs-6'>
                {serviceProviderDetails?.yearOfIncorporation ? (
                  serviceProviderDetails.yearOfIncorporation
                ) : (
                  <LineSpan />
                )}
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <h3 className='fs-8'>Service Offering</h3>
              <p className='fs-6'>{serviceProviderDetails?.serviceOffering || <LineSpan />}</p>
            </div>
            <div className='col-md-6'>
              <h3 className='fs-8'>Company Size</h3>
              <p className='fs-6'>
                {serviceProviderDetails?.numberOfEmployees != null ? (
                  getEntitySize(serviceProviderDetails.numberOfEmployees)
                ) : (
                  <LineSpan />
                )}
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <h3 className='fs-8'>No of Employees</h3>
              <p className='fs-6'>{serviceProviderDetails?.numberOfEmployees || <LineSpan />}</p>
            </div>
            <div className='col-md-6'>
              <h3 className='fs-8'>Number of Female Employees</h3>
              <p className='fs-6'>
                {serviceProviderDetails?.numberOfFemaleEmployees != null ? (
                  serviceProviderDetails.numberOfFemaleEmployees.toLocaleString()
                ) : (
                  <LineSpan />
                )}
              </p>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <h3 className='fs-8'>No of Youth Employees</h3>
              <p className='fs-6'>
                {serviceProviderDetails?.numberOfYouthEmployees != null ? (
                  serviceProviderDetails.numberOfYouthEmployees.toLocaleString()
                ) : (
                  <LineSpan />
                )}
              </p>
            </div>
            <div className='col-md-6'>
              <h3 className='fs-8'>Average Income earned by beneficiaries</h3>
              <p className='fs-6'>
                {serviceProviderDetails?.averageIncome != null ? (
                  `${
                    numberCurrencyPairs[Number(serviceProviderDetails?.averageIncomeCurrency) ?? 0]
                  } ${serviceProviderDetails.averageIncome.toLocaleString()}`
                ) : (
                  <LineSpan />
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <EditButton handleClose={handleClose} />
      <EditBusinessOperations
        showModal={showModal}
        handleClose={handleClose}
        pageSize={pageSize}
        currentPage={currentPage}
      />
    </div>
  )
}

export {ServiceProviderOperations}
