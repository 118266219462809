import {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../_theme/utils'

export const getLineChartOptions = (height: number, series: any[], categories: any[]): ApexOptions => {
  // const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  // const baseColor = getCSSVariableValue('--bs-primary')
  // const secondaryColor = getCSSVariableValue('--bs-gray-300')

  return {
    series: series,
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      parentHeightOffset: 0,
      toolbar: {
        show: false,
      },
      offsetY: -25,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        columnWidth: '25%',
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        style: {
          fontSize: '12px',

        },
        formatter: function(value, timestamp) {
          return value
        },
      },
      tickPlacement: 'on',
      tickAmount: 3,
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    fill: {
      opacity: 1,
      type: 'solid',
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function(val) {
          return '' + val
        },
      },
    },
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}