import {useAuth} from 'react-oidc-context'

export function useIsGlobalAdmin(): boolean {
  const auth = useAuth()
  const user = auth.user
  const roles = user?.profile?.role
  return Array.isArray(roles)
    ? roles.includes('Global Administrator')
    : roles === 'Global Administrator'
}
