/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts from 'apexcharts'
import {getCSS} from '../../../../../_theme/utils'
import {getLineChartOptions} from '../../../../../utils/lineChartHelpers'

type Props = {
  dataSrc: number[]
  chartTitleFigure: string
  chartSubTitle?: string
}

export const LineChartCard: React.FC<Props> = (props) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  const {
    chartTitleFigure,
    chartSubTitle,
    dataSrc,
  } = props

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const series = [
      {
        name: `${chartSubTitle}`,
        data: dataSrc,
      },

    ]

    const categories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun']

    const chart = new ApexCharts(chartRef.current, getLineChartOptions(height, series, categories))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef])

  return (
    <>
      <div className="card overflow-hidden h-md-50 mb-6">
        <div className="card-body d-flex justify-content-between flex-column px-0 pb-0">
          <div className="mb-4 px-9">
            <div className="d-flex align-items-center mb-2">
                            <span className="fs-2hx fw-bold text-gray-800 me-2 lh-1">
                                {chartTitleFigure}
                            </span>
            </div>
            <span className="fs-6 fw-semibold text-gray-400">
                            {chartSubTitle}
                        </span>
          </div>
          <div
            id="kt_card_widget_8_chart"
            className="min-h-auto mb-4"
            style={{height: 125, minHeight: 140}}
          >
            <div ref={chartRef} id="kt_charts_widget_1_chart" style={{height: '150px', paddingBottom: 0}} />
          </div>
        </div>
      </div>
    </>
  )
}

