import {Field} from 'formik'
import {CountryCustomSelect} from './CountryCustomSelect'
import {countryCodes} from '../../../../utils/countryCodes'

export const CreateContactInformation = ({touched, errors, setCountryCode}: any) => {
  return (
    <div data-kt-stepper-element="content">
      <div className="w-100">
        <div className="fv-row row mb-10 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span className="required">Name of Contact Person</span>
            </label>
            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="contactPerson.name"
              placeholder=""
            />
            {touched?.contactPerson?.name && (
              <div className="text-danger mt-2">{errors?.contactPerson?.name}</div>
            )}
          </div>
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span>Email Address</span>
            </label>

            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="contactPerson.email"
              placeholder=""
            />
            {touched?.contactPerson?.email && (
              <div className="text-danger mt-2">{errors?.contactPerson?.email}</div>
            )}
          </div>
        </div>
        <div className="fv-row row mb-10 p-0">
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span>Phone Number</span>
            </label>

            <div className="input-group mb-3 col-lg-6 ">
              <CountryCustomSelect
                name="dialCode"
                options={countryCodes}
                style={'py-lg-6'}
                setCountryCode={setCountryCode}
              />
              <Field
                type="number"
                className="form-control form-control-lg py-lg-5"
                name="contactPerson.phoneNumber"
                placeholder=""
              />
            </div>
            {touched?.contactPerson?.phoneNumber && (
              <div className="text-danger mt-2">{errors?.contactPerson?.phoneNumber}</div>
            )}
          </div>
          <div className="col-lg-6">
            <label className="d-flex align-items-center fs-4 f-s fw-bold mb-2">
              <span>Address Line</span>
            </label>

            <Field
              type="text"
              className="form-control form-control-lg py-lg-5"
              name="contactPerson.address"
              placeholder=""
            />
            {touched?.contactPerson?.address && (
              <div className="text-danger mt-2">{errors?.contactPerson?.address}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
