/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useNavigate} from 'react-router-dom'
import FinancierPagesHeader from '../financiers/components/FinancierPagesHeader'
import {toAbsoluteUrl} from '../../../_theme/helpers'

const ErrorsPage: React.FC = () => {
  const navigate = useNavigate()

  return (
    <div className='d-flex flex-column h-100'>
      <FinancierPagesHeader isFinancierPage={false} />
      <div className='bg-white flex-grow-1'>
        <div className='d-flex flex-column justify-content-center align-items-center mt-180px'>
          <img
            src={toAbsoluteUrl(`/media/images/page-under-maintenance.svg`)}
            alt={'Page under maintenance'}
          />
          <h1 className='mt-3'>Page Under Maintenance</h1>
          <p className='text-center w-50'>
            We apologize for the inconvenience, but this page is currently undergoing maintenance.
            We are working hard to improve your experience and will have it back online shortly.
          </p>
          <button
            className='btn btn-sm ms-lg-3 btn-danger d-flex align-items-center justify-content-center'
            onClick={() => navigate(-1)}
          >
            <img
              src={toAbsoluteUrl(`/media/images/reload-icon.svg`)}
              alt={'Page under maintenance'}
              className='me-2'
            />{' '}
            Reload Page...
          </button>
        </div>
      </div>
    </div>
  )
}

export {ErrorsPage}
