import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {RootState} from '../data/redux/Store'
import {AUTH_TOKEN_KEY} from '../data/constants'

const useAuthToken = () => {
  const [isTokenAvailable, setIsTokenAvailable] = useState(false)
  // const accessToken = useSelector((state: RootState) => state.auth.user?.access_token);

  const accessToken = localStorage.getItem(AUTH_TOKEN_KEY)

  useEffect(() => {
    if (accessToken) {
      setIsTokenAvailable(true)
    }
  }, [accessToken])

  return isTokenAvailable
}

export default useAuthToken