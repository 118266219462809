import {FC} from 'react'
import {Link} from 'react-router-dom'
import {getInitials} from '../../../../utils/stringHelpers'
import {useSelector} from 'react-redux'
import {timeAgo} from '../../../../utils/dateHelpers'
import {createCompanySelectors} from '../CompaniesApiSlice'
import {RootState} from '../../../../data/redux/Store'
import CardInitials from '../../shared/components/CardInitials'
import CardBadge from '../../shared/components/CardBadge'
import CardDetail from '../../shared/components/CardDetail'
import ProgressBar from '../../shared/components/ProgressBar'
import {INameIdPair} from '../../shared/interfaces'
import {IListPageFilters} from '../interfaces'

type CompanyCardProps = {
  id: string
  page: number
  pageSize: number
  searchTerm: string
  filters: IListPageFilters
  includeOnboardingStage?: boolean
  showCompanyCode?: boolean
  includeFinancingAsk?: boolean
  linkToCompanies?:boolean
  showCardIcons?: boolean
}

export const CompanyCard: FC<CompanyCardProps> = ({
  id,
  page,
  pageSize,
  searchTerm,
  filters,
  linkToCompanies = true,
  includeOnboardingStage = true,
  showCompanyCode = false,
  includeFinancingAsk = false,
  showCardIcons = false,
}) => {
  const {selectById: selectCompanyById} = createCompanySelectors({
    page,
    pageSize,
    searchTerm,
    ...filters,
  })
  const company = useSelector((state: RootState) => selectCompanyById(state, id))
  const checklistValues = company?.onboardingStatus.split(',').map(Number)
  const completedSteps = checklistValues?.filter((value) => value === 1).length
  const companyInitials = getInitials(company?.name, 3)

  return (
    <div>
      <Link
        to={linkToCompanies ? `/companies/${company?.id}` : '#'}
        className={`${
          showCompanyCode ? 'min-height-240px' : 'min-height-400px'
        } card border border-2 border-gray-300 mb-5 d-flex flex-column justify-content-between`}
      >
        <div className='d-flex border-0 pt-9 px-5 justify-content-between align-items-center'>
          <CardInitials
            initials={companyInitials}
            title={company?.name ?? ''}
            tooltip={true}
            width={companyInitials.length >= 4 ? 75 : 50}
          />
          <div className='d-flex py-4'>
            <CardBadge text={(company?.sectors?.[0]?.name ?? '').toUpperCase()} />
          </div>
        </div>
        <div className={`px-5 d-flex justify-content-between align-items-center ${showCompanyCode ? 'h-45px' : 'h-30px'}`}>
          {/* <span className='fw-bolder text-dark fs-3'>{company?.code ?? ''}</span> */}
          <span className='fw-bolder text-dark fs-3'>
            {showCompanyCode ? company?.code ?? '' : company?.name ?? ''}
          </span>
        </div>
        <div className={`d-flex justify-content-between align-items-start h-50px px-5 ${includeFinancingAsk ? 'mb-8' : 'mb-5'}`}>
          <div className='col-md-6 pe-0'>
            <CardDetail
              title='Years of Operation'
              showYearOfOperationIcon={showCardIcons}
              value={company?.yearOfIncorporation ? timeAgo(company?.yearOfIncorporation) : '-'}
            />
          </div>
          <div className='col-md-6 ps-0'>
            <CardDetail showLocationIcon={showCardIcons} title='Location' value={`${company?.country}, ${company?.district}`} />
          </div>
        </div>
        {includeOnboardingStage && (
          <div className='d-flex justify-content-between align-items-center h-30px px-5'>
            <ProgressBar
              value={completedSteps || 0}
              total={6}
              title='Onboarding Stage'
              bold={true}
            />
          </div>
        )}

        <div
          className={`d-flex flex-wrap align-items-center mb-3 px-5 ${
            includeFinancingAsk ? 'h-60px' : 'h-30px'
          }`}
        >
          {(company?.financingTypes ?? []).map((type: INameIdPair, index: number) => (
            <CardBadge
              key={index}
              text={includeFinancingAsk ? `${type.name}` : type.name}
            />
          ))}
        </div>
      </Link>
    </div>
  )
}
