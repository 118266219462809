const FinancierFilters = () => {
  return (
    <div className='d-flex align-items-center justify-content-evenly gap-2 button-transparent w-100px h-40px'>
      <i className='bi bi-filter fs-2 fw-bolder text-danger' aria-hidden='true'></i>
      <span>Filters</span>
    </div>
  )
}

export default FinancierFilters
