/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts from 'apexcharts'
import {getCSS} from '../../../../../_theme/utils'
import {getChartOptions} from '../../../../../utils/chartHelpers'

type Props = {
  horizontal: boolean
  className: string
  dataSrc: string
  chartTitle: string
  chartSubTitle?: string
}

export const XBarChart: React.FC<Props> = (props) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  const {
    className,
    chartTitle,
    chartSubTitle,
    horizontal,
    dataSrc,
  } = props


  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))


    const series = [
      {
        name: 'Companies',
        data: [44, 55, 57, 23, 30],
      },
      {
        name: 'Financiers',
        data: [76, 85, 101, 95, 85],

      },
    ]

    const categories = ['Kenya', 'Rwanda', 'Uganda', 'Tanzania', 'Ghana']

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, series, categories, horizontal))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef])

  return (
    <div className={`card ${className} pb-0`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        {/* begin::Title */}
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-1">{chartTitle}</span>
          {chartSubTitle && <span className="text-muted fw-bold fs-7">{chartSubTitle}</span>}
        </h3>
        {/* end::Title */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body">
        {/* begin::Chart */}
        <div ref={chartRef} id="kt_charts_widget_1_chart" style={{height: '350px'}} />
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}


