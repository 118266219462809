import {FC} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {KTSVG} from '../../../../_theme/helpers'

type Props = {
  showDeleteModal: boolean
  handleClose: () => void
  isLoading: boolean
  handleDelete: () => void
  item: string
}

export const DeleteModal: FC<Props> = ({
  showDeleteModal,
  handleClose,
  handleDelete,
  isLoading,
  item,
}) => {
  return (
    <Modal
      show={showDeleteModal}
      onHide={handleClose}
      aria-labelledby='deleteCompanyModalLabel'
      dialogClassName='modal-50w'
    >
      <div className='modal-content ' role='document'>
        <div className='modal-header'>
          <h5 className='modal-title' id='deleteCompanyModalLabel'>
            <i className='bi bi-exclamation-triangle-fill text-danger fs-3 pe-2'></i> Delete {item}
          </h5>
          <div
            className='btn btn-sm btn-icon btn-active-color-danger'
            data-bs-dismiss='modal'
            onClick={handleClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
        </div>
        <div className='modal-body'>
          <span>
            Are you sure you want to delete the {item}?<br></br> This action cannot be undone.
          </span>
        </div>
        <div className='modal-footer'>
          <Button className='btn btn-secondary' onClick={handleClose}>
            Cancel
          </Button>
          <button
            type='submit'
            className='btn btn-danger'
            onClick={handleDelete}
            disabled={isLoading}
          >
            <span className='indicator-label'>
              {!isLoading ? (
                'Delete'
              ) : (
                <>
                  <span className='spinner-border h-15px w-15px align-middle text-white-400'></span>
                </>
              )}
            </span>
          </button>
        </div>
      </div>
    </Modal>
  )
}
