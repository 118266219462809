import React from 'react'
import {Routes, Route, Navigate} from 'react-router'
import {
  Dashboard,
  FinancierPagesWrapper,
  Calls,
  Deals,
  Settings, Pipeline,
} from '../features/financiers/pages'
import {useAuth} from 'react-oidc-context'

const FinancierRoutes = () => {
  const auth = useAuth()
  const financierId = auth?.user?.profile?.sub
  return (
    <Routes>
      {/* Changed: Moved nested routes directly under this Route */}
      <Route path='financier/:id/*' element={<FinancierPagesWrapper />}>
        <Route index path='pipeline' element={<Pipeline />} />
        <Route path='dashboard' element={<Dashboard />} />
        <Route path='calls' element={<Calls />} />
        <Route path='deals' element={<Deals />} />
        <Route path='settings' element={<Settings />} />
      </Route>
      {/* Changed: Updated Navigate paths to include specific sub-routes */}
      <Route path='/' element={<Navigate to={`/financier/${financierId}/pipeline`} />} />
      <Route path='/calls' element={<Navigate to={`/financier/${financierId}/calls`} />} />
      <Route path='auth/*' element={<Navigate to={`/financier/${financierId}/pipeline`} />} />
    </Routes>
  )
}

export default FinancierRoutes