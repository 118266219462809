import {Field} from 'formik'
import {countries} from '../../../../utils/countries'
import React, { useState } from 'react'
import {useCheckNameAvailabilityMutation} from '../ServiceProvidersApiSlice'

const CreateServiceProviderGeneralInformation = ({errors, touched, name, setFileObject, setFieldValue}: any) => {
    const [providerNameStatus, setProviderNameStatus] = useState<boolean | null>(null)
    const [checkProviderName, {isLoading: isCheckingProviderName}] =
      useCheckNameAvailabilityMutation()
  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    const file = event.target?.files ? event.target.files[0] : null
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result as string
        const base64Content = base64String.split(',')[1]
        const fileObj = {
          fileName: file.name,
          fileType: file.type,
          fileContent: base64Content,
          dateUploaded: new Date().toISOString(),
          fileSize: file.size,
        }
        console.log(fileObj)
        setFileObject(fileObj)
        setFieldValue('generalInformation.logo', file)
      }
      reader.readAsDataURL(file)
    } else {
      setFieldValue('generalInformation.logo', null)
    }
  }

  const handleCheckName = async (name: string) => {
    try {
      await checkProviderName({name}).unwrap()
      setProviderNameStatus(true)
    } catch (error) {
      setProviderNameStatus(false)
    }
  }
  return (
    <div className='current ' data-kt-stepper-element='content'>
      <div className='w-100'>
        <div className='fv-row row mb-7 p-0'>
          <div className='col-lg-6'>
            <div className='mb-7 p-0'>
              <label className='d-flex align-items-center fs-4 fw-bold mb-2 f-s'>
                <span className='required'>{`${name} Name`}</span>
              </label>
              <Field
                type='text'
                className='form-control form-control-lg py-lg-5'
                name='generalInformation.name'
                placeholder=''
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => handleCheckName(e.target.value)}
              />
              {isCheckingProviderName && (
              <div className='text-warning mt-2'>Checking name availability...</div>
            )}
            {providerNameStatus === false && (
              <div className='mt-2 text-danger'>Provider already exists</div>
            )}

              {touched?.generalInformation?.name && (
                <div className='text-danger mt-2'>{errors?.generalInformation?.name}</div>
              )}
            </div>
            <div>
              <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
                <span className='required'>Country</span>
              </label>
              <Field
                as='select'
                name='generalInformation.country'
                className='form-select select2-hidden-accessible py-lg-5 '
              >
                <option value='' disabled selected>
                  Select Country
                </option>
                {countries.map((option) => (
                  <option key={option.value} value={option.label}>
                    {option.label}
                  </option>
                ))}
              </Field>

              {touched?.generalInformation?.country && (
                <div className='text-danger mt-2'>{errors?.generalInformation?.country}</div>
              )}
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='row h-100'>
              <div className='col-12'>
                {/*About the company */}

                <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
                  <span className='required'>{`About ${name}`}</span>
                </label>

                <Field
                  as='textarea'
                  className='form-control form-control-lg py-lg-5'
                  name='generalInformation.about'
                  placeholder=''
                  style={{height: 'calc(100% - 2rem)'}} // Adjust height as needed
                />
                {touched?.generalInformation?.about && (
                  <div className='text-danger mt-2'>{errors?.generalInformation?.about}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='fv-row row mb-10 p-0'>
          <div className='col-lg-6'>
            <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
              <span className='required'>District</span>
            </label>
            <Field
              type='text'
              className='form-control form-control-lg py-lg-5 custom-placeholder'
              name='generalInformation.district'
              placeholder='Kampala'
            />
            {touched?.generalInformation?.district && (
              <div className='text-danger mt-2'>{errors?.generalInformation?.district}</div>
            )}
          </div>
          <div className='col-lg-6'>
            <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
              <span>Company Logo</span>
            </label>

            <input
              type='file'
              className='form-control form-control-lg py-lg-5'
              name='generalInformation.logo'
              placeholder=''
              style={{width: '100%'}}
              onChange={(event) => handleFileChange(event, setFieldValue)}
              accept='image/*'
            />
            {touched?.generalInformation?.logo && (
              <div className='text-danger mt-2'>{errors?.generalInformation?.logo}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateServiceProviderGeneralInformation
