import React, {
  FC,
  Dispatch,
  useState,
  useEffect, SetStateAction, createContext, useContext,
} from 'react'
import {useDispatch} from 'react-redux'
import {AuthModel, IUserProfile} from './_models'
import * as authHelper from './AuthHelpers'
import authService from './AuthService'
import {AUTH_ID_KEY, AUTH_TOKEN_KEY, AUTH_USER_KEY} from '../../../data/constants'
import {useAuth} from 'react-oidc-context'

import {LayoutSplashScreen} from '../../../_theme/layout/core'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: IUserProfile | undefined
  setCurrentUser: Dispatch<SetStateAction<IUserProfile | undefined>>
  userRoles: string[] | undefined
  setUserRoles: Dispatch<SetStateAction<string[] | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {
  },
  currentUser: undefined,
  setCurrentUser: () => {
  },
  logout: () => {
  },
  userRoles: undefined,
  setUserRoles: () => {
  },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuthentication = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<any> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<IUserProfile | undefined>()
  const [userRoles, setUserRoles] = useState<string[] | undefined>()

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = async () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    setUserRoles(undefined)
    // dispatch(handleLogout());
    await authService.logout()
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        userRoles,
        setUserRoles,
        logout,
      }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<any> = ({children}) => {

  const {logout, setCurrentUser, setUserRoles} = useAuthentication()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const auth = useAuth()
  useEffect(() => {
    const user = auth.user
    if (user) {
      localStorage.setItem(AUTH_USER_KEY, JSON.stringify(user))
      localStorage.setItem(AUTH_TOKEN_KEY, user.access_token)
      const profile: any = {...user.profile}
      setCurrentUser(profile)
      setShowSplashScreen(false)

    } else {
      setShowSplashScreen(false)
      logout()
    }
  }, [auth])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthInit, AuthProvider, useAuthentication}
