import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

function CallbackPage() {

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',  // Horizontal centering
          alignItems: 'center',      // Vertical centering
          minHeight: '100vh',        // Full viewport height
          flexDirection: 'column',   // Stack items vertically
        }}
      >
        <Spinner animation="border" />
        <p className="pt-2">Almost there...</p>
      </div>
    </>
  )

}

export default CallbackPage