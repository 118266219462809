import React from 'react'
import {useParams} from 'react-router-dom'
import {useGetCompanyDetailsQuery} from '../../companies/CompaniesApiSlice'
import {printDateWithOrdinal} from '../../../../utils/dateHelpers'
import LineSpan from './LineSpan'

const AuditLog = () => {
  const {id} = useParams<{id: string}>() as {id: string}
  const {data: companyState} = useGetCompanyDetailsQuery(id)
  const companyDetails = companyState?.entities[id]
  return (
    <>
      <h3 className="card-title">Audit Logs</h3>

      <div className="d-flex align-items-center gap-2 ">
        <i className="fas fa-calendar"></i>
        <span className="fw-bold">Created On</span>
        <span>{companyDetails?.dateCreated ? printDateWithOrdinal(companyDetails?.dateCreated) : <LineSpan />}</span>
      </div>
      <div className="d-flex align-items-center gap-2 ">
        <i className="fas fa-user"></i>
        <span className="fw-bold">Created By</span>
        <span>{companyDetails?.createdByName ?? '_'}</span>
      </div>
    </>
  )
}

export default AuditLog
