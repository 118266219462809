import React, {FC, useRef, useState} from 'react'
import {Formik, Form, Field} from 'formik'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_theme/helpers/components/KTSVG'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/flatpickr.min.css'
import * as Yup from 'yup'
import {
  useGetServiceProvidersQuery,
  useUpdateServiceProviderMutation,
  createServiceProviderSelectors,
} from '../ServiceProvidersApiSlice'
import {useParams} from 'react-router-dom'
import {RootState} from '../../../../data/redux/Store'
import {useSelector} from 'react-redux'
import {ICreateDocument} from '../../documents'
import {
  useAddNewDocumentMutation,
  useDeleteDocumentMutation,
} from '../../documents/DocumentsApiSlice'
import {MultiSelect} from '../../shared/components/MultiSelect'
import {INameIdPair} from '../../shared/interfaces'
import {useGetSectorsQuery} from '../../companies/CompaniesApiSlice'
import NumberFormatter from '../../shared/components/NumberFormatter'

interface Props {
  showModal: boolean
  handleClose: () => void
  pageSize: number
  currentPage: number
}

export const EditBusinessOperations: FC<Props> = ({
  showModal,
  handleClose,
  pageSize,
  currentPage,
}) => {
  const businessOperationValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    slogan: Yup.string().nullable(),
    about: Yup.string().required('About is required'),
    logo: Yup.string().nullable(),
    yearOfIncorporation: Yup.number()
      .nullable()
      .label('Year of Incorporation')
      .min(1900, 'Year must be 1900 or later')
      .max(new Date().getFullYear(), `Year must be ${new Date().getFullYear()} or earlier`)
      .typeError('Year must be a valid number')
      .test('len', 'Year must be exactly 4 digits', (val) => !val || val.toString().length === 4),
    serviceOffering: Yup.string().nullable(),
    numberOfEmployees: Yup.string().nullable(),
    numberOfFemaleEmployees: Yup.string().nullable(),
    numberOfYouthEmployees: Yup.string().nullable(),
    averageIncome: Yup.string().nullable(),
    averageIncomeCurrency: Yup.string().nullable(),
  })

  const [updateServiceProvider, {isLoading}] = useUpdateServiceProviderMutation()
  const {id} = useParams<{id: string}>() as {id: string}
  useGetServiceProvidersQuery({page: currentPage, pageSize: pageSize})
  const {selectById: selectServiceProviderById} = createServiceProviderSelectors({
    page: currentPage,
    pageSize,
  })
  const {data: sectorsState} = useGetSectorsQuery()
  const companySectorsList = Object.values(sectorsState?.entities || {}).filter(
    (type): type is INameIdPair => Boolean(type)
  )
  const serviceProviderDetails = useSelector((state: RootState) =>
    selectServiceProviderById(state, id)
  )

  const initialValues = {
    ...serviceProviderDetails,
    sectors: serviceProviderDetails?.serviceProviderSectors.map((sector) => ({
      id: sector.sectorId,
      name: sector.sectorName,
    })),
    yearOfIncorporation:
      !serviceProviderDetails?.yearOfIncorporation 
        ? ''
        : serviceProviderDetails?.yearOfIncorporation,
  }

  const handleSave = async (values: any) => {
    try {
      const updatedServiceProviders = {
        id: serviceProviderDetails?.id,
        name: values.name,
        about: values.about,
        slogan: values.slogan,
        yearOfIncorporation: values.yearOfIncorporation,
        serviceOffering: values.serviceOffering,
        numberOfEmployees: values.numberOfEmployees.replace(/,/g, '') || null,
        numberOfFemaleEmployees: values.numberOfFemaleEmployees.replace(/,/g, '') || null,
        numberOfYouthEmployees: values.numberOfYouthEmployees.replace(/,/g, '') || null,
        averageIncome: values.averageIncome.replace(/,/g, '') || null,
        averageIncomeCurrency: Number(values.averageIncomeCurrency) ?? 1,
        serviceProviderSectors: values.sectors.map((sector: INameIdPair) => ({
          sectorId: sector.id,
          sectorName: sector.name,
          providerId: serviceProviderDetails?.id,
        })),
      }
      await updateServiceProvider(updatedServiceProviders).unwrap()
      handleClose()
    } catch (error) {
      handleClose()
    }
  }

  return (
    <>
      <Modal
        id='kt_edit_business_operation'
        aria-hidden={true}
        dialogClassName='h-auto'
        show={showModal}
        onHide={handleClose}
      >
        <div className='modal-content w-700px' role='document'>
          <Formik
            initialValues={initialValues || {}}
            validationSchema={businessOperationValidationSchema}
            onSubmit={handleSave}
          >
            {({values, errors, touched, isValid, setFieldValue, setFieldTouched}) => {
              return (
                <Form>
                  <div className='modal-content'>
                    <div className='modal-header'>
                      <h5 className='modal-title ps-1'>Edit Business Operations</h5>
                      <div
                        className='btn btn-sm btn-icon btn-active-color-danger'
                        data-bs-dismiss='modal'
                        onClick={handleClose}
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr061.svg'
                          className='svg-icon-1'
                        />
                      </div>
                    </div>
                    <div className='modal-body px-9'>
                      <div className='row fv mb-4'>
                        <div className='col-md-6 mb-3'>
                          <label
                            htmlFor='name'
                            className='d-flex align-items-center fs-5 fw-bold mb-2 text-dark required'
                          >
                            {' '}
                            Business Name
                          </label>
                          <Field
                            type='text'
                            name='name'
                            className='form-control form-control-sm'
                            style={{color: '#616162', fontWeight: 300}}
                          />
                          {touched.name && <div className='text-danger mt-2'>{errors.name}</div>}
                        </div>
                        <div className='col-md-6 mb-3'>
                          <label
                            htmlFor='slogan'
                            className='d-flex align-items-center fs-5 fw-bold mb-2 text-dark'
                          >
                            Business Slogan
                          </label>
                          <Field
                            type='text'
                            name='slogan'
                            className='form-control form-control-sm'
                            style={{color: '#616162', fontWeight: 300}}
                          />
                          {touched.slogan && (
                            <div className='text-danger mt-2'>{errors.slogan}</div>
                          )}
                        </div>
                      </div>
                      <div className='row fv mb-4'>
                        <div className='col-md-6 mb-3'>
                          <label
                            htmlFor='about'
                            className='d-flex align-items-center fs-5 fw-bold mb-2 text-dark required'
                          >
                            About Business
                          </label>
                          <Field
                            as='textarea'
                            type='text'
                            name='about'
                            className='form-control form-control-sm'
                            style={{color: '#616162', fontWeight: 300}}
                          />
                          {touched.about && <div className='text-danger mt-2'>{errors.about}</div>}
                        </div>
                        <div className='col-md-6 mb-3'>
                          <label className='fs-5 fw-bold mb-2'>Year of Incorporation</label>
                          <Field
                            type='number'
                            className='form-control form-control-sm'
                            style={{color: '#616162', fontWeight: 300}}
                            name='yearOfIncorporation'
                            placeholder=''
                          />
                          {touched.yearOfIncorporation && (
                            <div className='text-danger mt-2'>{errors.yearOfIncorporation}</div>
                          )}
                        </div>
                      </div>
                      <div className='row fv mb-4'>
                        <div className='col-md-6 mb-3'>
                          <label className='d-flex align-items-center'>
                            <span className='fs-5 fw-bold mb-2'>Sector(s)</span>
                          </label>

                          <MultiSelect
                            selectedValues={values.sectors || []}
                            options={companySectorsList}
                            setOption={(selectedOptions) =>
                              setFieldValue('sectors', selectedOptions)
                            }
                          />
                        </div>

                        <div className='col-md-6 mb-3'>
                          <label
                            htmlFor='serviceOffering'
                            className='d-flex align-items-center fs-5 fw-bold mb-2 text-dark'
                          >
                            Service Offering
                          </label>
                          <Field
                            type='text'
                            name='serviceOffering'
                            className='form-control form-control-sm'
                            style={{color: '#616162', fontWeight: 300}}
                          />
                        </div>
                      </div>
                      <div className='row fv mb-4'>
                        <div className='col-md-6 mb-3'>
                          <label
                            htmlFor='numberOfEmployees'
                            className='d-flex align-items-center fs-5 fw-bold mb-2 text-dark'
                          >
                            Number Of Employees
                          </label>
                          <NumberFormatter
                            name='numberOfEmployees'
                            className='form-control-sm currency-picker'
                            setFieldValue={setFieldValue}
                            initialValue={values.numberOfEmployees}
                          />
                        </div>

                        <div className='col-md-6 mb-3'>
                          <label
                            htmlFor='numberOfFemaleEmployees'
                            className='d-flex align-items-center fs-5 fw-bold mb-2 text-dark'
                          >
                            Number Of Female Employees
                          </label>
                          <NumberFormatter
                            name='numberOfFemaleEmployees'
                            className='form-control-sm currency-picker'
                            setFieldValue={setFieldValue}
                            initialValue={values.numberOfFemaleEmployees}
                          />
                        </div>
                      </div>
                      <div className='row fv mb-4'>
                        <div className='col-md-6 mb-3'>
                          <label
                            htmlFor='numberOfYouthEmployees'
                            className='d-flex align-items-center fs-5 fw-bold mb-2 text-dark'
                          >
                            Number Of Youth Employees
                          </label>
                          <NumberFormatter
                            name='numberOfYouthEmployees'
                            className='form-control-sm currency-picker'
                            setFieldValue={setFieldValue}
                            initialValue={values.numberOfYouthEmployees}
                          />
                        </div>

                        <div className='col-md-6 mb-3'>
                          <label
                            htmlFor='averageIncome'
                            className='d-flex align-items-center fs-5 fw-bold mb-2'
                          >
                            Average Income
                          </label>
                          <div className='input-group input-group-sm mb-3'>
                            <div className='input-group-prepend'>
                              <Field
                                as='select'
                                name='averageIncomeCurrency'
                                className='form-select form-select-sm form-select-solid rounded-end-0'
                                style={{color: '#616162', fontWeight: 300}}
                                multiple={false}
                                value={values.averageIncomeCurrency}
                              >
                                <option value='1'>UGX</option>
                                <option value='0'>USD</option>
                                <option value='2'>EUR</option>
                              </Field>
                            </div>
                            <NumberFormatter
                              name='averageIncome'
                              className='form-control-sm border-start-0 rounded-start-0 currency-picker'
                              setFieldValue={setFieldValue}
                              initialValue={values.averageIncome}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='modal-footer'>
                      <button
                        type='button'
                        className='btn btn-secondary text-dark'
                        onClick={handleClose}
                      >
                        Close
                      </button>
                      <button
                        type='submit'
                        className='btn btn-danger'
                        onClick={() => {
                          handleSave(values)
                        }}
                        disabled={!isValid || isLoading}
                      >
                        <span className='indicator-label'>
                          {!isLoading ? (
                            'Save'
                          ) : (
                            <>
                              <span className='spinner-border h-15px w-15px align-middle text-white-400'></span>
                            </>
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </Modal>
    </>
  )
}
