import React, {Suspense} from 'react'
import {BrowserRouter, Outlet} from 'react-router-dom'
import {I18nProvider} from '../_theme/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_theme/layout/core'
import {AppRoutes} from './routing/AppRoutes'
import {AuthInit} from "./features/auth/AuthInit";

const App = () => {
    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <I18nProvider>
                <LayoutProvider>
                    <AuthInit>
                        <Outlet/>
                    </AuthInit>
                </LayoutProvider>
            </I18nProvider>
        </Suspense>
    )
}

export {App}
