import React from 'react'

type Props = {
    dataItemName: string
    dataItemNumber?: number
}

export const TableBarChartDataItem: React.FC<Props> = (props) => {

    const {
        dataItemName,
        dataItemNumber,
    } = props

    return (
        <>
            <tr>
                <td>
                    <a
                        href="#"
                        className="text-gray-600 fw-bold text-hover-primary mb-1 fs-6"
                    >
                        {dataItemName}
                    </a>
                </td>
                <td className="d-flex align-items-center border-0">
                    <span className="fw-bold text-gray-800 fs-6 me-3">263</span>
                    <div className="progress rounded-start-0">
                        <div
                            className="progress-bar bg-success m-0"
                            role="progressbar"
                            style={{ height: 12, width: dataItemNumber }}
                            aria-valuenow={166}
                            aria-valuemin={0}
                        />
                    </div>
                </td>
            </tr>
        </>
    )
}





