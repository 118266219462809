import React, {useState} from 'react'
import {useAuth} from 'react-oidc-context'
import {Spinner} from 'react-bootstrap'
import AuthService from './AuthService'
// import {Spinner} from 'react-bootstrap'

const Login = () => {
  const auth = useAuth()
  const [isLoading, setIsLoading] = useState(false)

  // Function to handle login button click
  async function handleLogin() {
    setIsLoading(true) // Start loading when login is initiated
    try {
      // Initiate login process by redirecting the user to the Identity Server login page
      const user = await AuthService.login()
      console.log(user)
    } catch (error) {
      setIsLoading(false)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {
        isLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',  // Horizontal centering
              alignItems: 'center',      // Vertical centering
              minHeight: '100vh',        // Full viewport height
              flexDirection: 'column',   // Stack items vertically
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>

            {/*<Spinner animation="border" />*/}
            <p className="pt-2">Signing you in...</p>
          </div>
        ) : (
          <div className="row login-row">
            <div className="col-lg-6 login-bg">
              <img src="/media/images/authBg.png" alt="" className="auth-bg d-none d-lg-block" />
            </div>
            <div className="col-lg-6 login-content">
              <div className="login-logo d-lg-block">
                <img src="/media/images/black_logo.svg" className="img-asigma-logo" alt="" />
              </div>
              <div className="login-text">
                <h3 className="pb-3">Login to your Account</h3>
                {/* Call handleLogin function when the button is clicked */}
                <button onClick={handleLogin} className="btn btn-primary btn-login">Continue</button>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default Login