import React, {useEffect, useMemo, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {KTSVG} from '../../../../_theme/helpers'
import {useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {
  createFinancierSelectors,
  useDeleteFinancierTypesMutation,
  useGetFinanciersQuery,
  useGetFinancierTypesQuery,
  useUpdateFinancierMutation,
  useAddNewFinancierTypesMutation
} from '../FinanciersApiSlice'
import {RootState} from '../../../../data/redux/Store'
import {useGetFinancingTypesQuery} from '../../companies/CompaniesApiSlice'
import {INameIdPair} from '../../shared/interfaces'
import {MultiSelect} from '../../shared/components/MultiSelect'
import { currencies } from '../../shared/constants'
import NumberFormatter from '../../shared/components/NumberFormatter'

type Props = {
  showModal: boolean
  handleClose: () => void
  currentPage: number
  pageSize: number
}

export const EditFinancierFinancials: React.FC<Props> = ({
  handleClose,
  showModal,
  currentPage,
  pageSize,
}) => {
  const validationSchema = Yup.object().shape({
    equity: Yup.string().nullable(),
    currency: Yup.string().nullable(),
    debt: Yup.string().nullable(),
    grant: Yup.string().nullable(),
  })
  const [updateFinancier, {isLoading}] = useUpdateFinancierMutation()
  const [deleteFinancierTypes] = useDeleteFinancierTypesMutation()
  const [addFinancierTypes] = useAddNewFinancierTypesMutation()
  const {id} = useParams<{id: string}>() as {id: string}
  useGetFinanciersQuery({page: currentPage, page_size: pageSize, query:''})
  const {data: financingTypesState} = useGetFinancingTypesQuery()
  const {selectById: selectFinancierById} = createFinancierSelectors(currentPage, pageSize, '')
  const financierDetails = useSelector((state: RootState) => selectFinancierById(state, id))
  const financingTypes: any = Object.values(financingTypesState?.entities || {}).filter(
    (type): type is INameIdPair => Boolean(type)
  )
  const {data: financierFinancingTypesState} = useGetFinancierTypesQuery(id)

  const financierFinancingTypesIdsList = (financierFinancingTypesState?.ids || []) as string[]
  const initialValues = useMemo(() => ({
    debt: financierDetails?.debtMaximumTicketSize,
    grant: financierDetails?.grantMaximumTicketSize,
    equity: financierDetails?.equityMaximumTicketSize,
    currency: financierDetails?.currency || 'USD',
  }), [financierDetails]);
  const [selectedFinancingTypes, setSelectedFinancingTypes] = useState<INameIdPair[]>([])

  const initialLoad = useRef(true)

  useEffect(() => {
    if (showModal) {
      initialLoad.current = true
    }
  }, [showModal])

  useEffect(() => {
    if (financierDetails && initialLoad.current) {
      const defaultSelectedFinancierTypes = financingTypes.filter((type:INameIdPair) =>
        financierFinancingTypesIdsList.includes(type.id)
      )
      setSelectedFinancingTypes(defaultSelectedFinancierTypes)
      initialLoad.current = false
    }
  }, [financierDetails, financingTypes,initialValues])

  const handleSave = async (values: any) => {
    const updatedFinancierData = {
      id: financierDetails?.id,
      equity_maximum_ticket_size: values.equity.replace(/,/g,''),
      debt_maximum_ticket_size: values.debt.replace(/,/g,''),
      grant_maximum_ticket_size: values.grant.replace(/,/g,''),
      currency: values.currency
    }
      // Extract IDs from selectedFinancierTypes
      const selectedFinancingTypeIds:string[] = selectedFinancingTypes.map(type => type.id)

      // Identify types to delete
      const typesToDelete:string[] = financierFinancingTypesIdsList.filter(id => !selectedFinancingTypeIds.includes(id)) as string[]
  
      // Identify types to add
      const typesToAdd = selectedFinancingTypeIds.filter(id => !financierFinancingTypesIdsList.includes(id))
      const financierTypesObjects = typesToAdd.map((typeId) => ({
        financier_id: id,
        financing_type_id: typeId
      }))
  
      try {
      await Promise.all([
        // deleteFinancierTypes(typesToDelete).unwrap(),
        // addFinancierTypes(financierTypesObjects).unwrap(),
        updateFinancier(updatedFinancierData).unwrap()
      ])
      setSelectedFinancingTypes([])
      handleClose()
    } catch (error) {
      handleClose()
    }
  }

  return (
    <>
      <Modal
        id='editFinancialsModal'
        aria-hidden={true}
        dialogClassName='h-auto'
        tabIndex={-1}
        show={showModal}
        onHide={handleClose}
      >
        <div className='modal-content w-700px' role='document'>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSave}
            enableReinitialize={true}
          >
            {({errors, touched, isValid, values,setFieldValue}) => (
              <Form>
                <div className='modal-header'>
                  <h5 className='modal-title ps-1' id='editFinancialsModalLabel'>
                    Edit Financials
                  </h5>
                  <div
                    className='btn btn-sm btn-icon btn-active-color-danger'
                    data-bs-dismiss='modal'
                    onClick={handleClose}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                  </div>
                </div>
                <div className='modal-body px-9'>
                  <div className='row '>
                    <div className='col-md-6 mb-3'>
                      <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
                        <span>Financing Types</span>
                      </label>
                      <MultiSelect
                        selectedValues={selectedFinancingTypes}
                        options={financingTypes}
                        setOption={setSelectedFinancingTypes}
                      />
                    </div>
                    {selectedFinancingTypes.some((type) => type.name === 'Equity') && (
                      <div className='col-md-6 mb-3'>
                        <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
                          <span>Maximum Ticket Size - Equity</span>
                        </label>
                        <div className='row'>
                          <div className='col-3 pe-0'>
                            <Field
                              as='select'
                              name='currency'
                              className='form-select form-select-sm form-select-solid rounded-end-0 currency-picker'
                              aria-label='Select financing currency for Equity'
                              multiple={false}
                            >
                              {Object.entries(currencies).map(([key, value]) => (
                                <option value={value}>{key}</option>
                              ))}
                            </Field>
                            {touched?.currency && (
                              <div className='text-danger mt-2'>
                                {errors?.currency}
                              </div>
                            )}
                          </div>
                          <div className='col-9 ps-0 '>
                            <NumberFormatter
                              name='equity'
                              className='form-control-sm border-start-0 rounded-start-0 currency-picker'
                              setFieldValue={setFieldValue}
                              initialValue={values.equity}
                            />
                            {touched?.equity && (
                              <div className='text-danger mt-2'>{errors?.equity}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='row pt-5'>
                    {selectedFinancingTypes.some((type) => type.name === 'Debt') && (
                      <div className='col-md-6'>
                        <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
                          <span>Maximum Ticket Size - Debt</span>
                        </label>
                        <div className='row'>
                          <div className='col-3 pe-0'>
                            <Field
                              as='select'
                              name='currency'
                              className='form-select form-select-sm form-select-solid rounded-end-0 currency-picker'
                              aria-label='Select financing currency for Debt'
                              multiple={false}
                            >
                              {Object.entries(currencies).map(([key, value]) => (
                                <option value={value}>{key}</option>
                              ))}
                            </Field>
                            {touched?.currency && (
                              <div className='text-danger mt-2'>
                                {errors?.currency}
                              </div>
                            )}
                          </div>
                          <div className='col-9 ps-0 '>
                            <NumberFormatter
                              name='debt'
                              className='form-control-sm border-start-0 rounded-start-0 currency-picker'
                              setFieldValue={setFieldValue}
                              initialValue={values.debt}
                            />
                            {touched?.debt && (
                              <div className='text-danger mt-2'>{errors?.debt}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {selectedFinancingTypes.some((type) => type.name === 'Grant') && (
                      <div className='col-md-6'>
                        <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
                          <span>Maximum Ticket Size - Grant</span>
                        </label>
                        <div className='row'>
                          <div className='col-3 pe-0'>
                            <Field
                              as='select'
                              name='currency'
                              className='form-select form-select-sm form-select-solid rounded-end-0 currency-picker'
                              aria-label='Select financing currency for Grant'
                              multiple={false}
                            >
                              {Object.entries(currencies).map(([key, value]) => (
                                <option value={value}>{key}</option>
                              ))}
                            </Field>
                            {touched?.currency && (
                              <div className='text-danger mt-2'>
                                {errors?.currency}
                              </div>
                            )}
                          </div>
                          <div className='col-9 ps-0 '>
                            <NumberFormatter
                              name='grant'
                              className='form-control-sm border-start-0 rounded-start-0 currency-picker'
                              setFieldValue={setFieldValue}
                              initialValue={values.grant}
                            />
                            {touched?.grant && (
                              <div className='text-danger mt-2'>{errors?.grant}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-secondary text-dark'
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  <button
                    type='submit'
                    className='btn btn-danger'
                    onClick={() => {
                      handleSave(values)
                    }}
                    disabled={!isValid || isLoading}
                  >
                    <span className='indicator-label'>
                      {!isLoading && 'Save'}
                      {isLoading && (
                        <>
                          <span className='spinner-border h-15px w-15px align-middle text-white-400'></span>
                        </>
                      )}
                    </span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  )
}
