/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from 'react-oidc-context'
import {User} from 'oidc-client-ts'

const HeaderUserMenu: FC = () => {
  // const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const auth = useAuth()
  const user: User | null | undefined = auth.user

  // Function to handle login button click
  async function handleLogout() {
    try {
      // await auth.signoutSilent()
      await auth.signoutRedirect();
    } catch (error) {
      console.error('Error occurred during login: ', error)
    }
  }

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {user?.profile?.given_name} {user?.profile?.family_name}
            </div>
            <a href="#" className="fw-bold text-muted text-hover-danger fs-7">
              {user?.profile?.email}
            </a>
          </div>
        </div>
      </div>
      <div className="menu-item px-5">
        <div className="header-separator my-1"></div>
        <a href="#" className="menu-link px-5 text-hover-danger">
          Profile
        </a>
      </div>
      <div className="menu-item px-5">
        <div className="header-separator my-1"></div>
        <Link to="/auth" className="menu-link px-5 text-hover-danger" onClick={handleLogout}>
          Sign Out
        </Link>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
