import {FC} from 'react'

type CardInitialsProps = {
  initials: string
  width?: number
  height?: number
  fontSize?: number
  title?: string
  tooltip?: boolean
  'data-bs-toggle'?: string
  'data-bs-placement'?: string
}

const CardInitials: FC<CardInitialsProps> = ({
  initials,
  width = 50,
  height = 50,
  fontSize = 18,
  title,
  tooltip = false,
}) => {
  return (
    <div
      className={`w-${width}px h-${height}px bg-danger rounded d-flex justify-content-center align-items-center ${tooltip ? 'custom-tooltip' : ''} px-5`}
    >
      <span className={`text-white fw-bold fs-${fontSize}px`}>{initials}</span>
      {tooltip && <span className="custom-tooltip-text">{title}</span>}
    </div>
  )
}

export default CardInitials