import {FC} from 'react'
import {useParams} from 'react-router-dom'
import EditButton from '../../shared/components/EditButton'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import {UpdateFinancierGeneralInformation} from '../modals'
import {
  createFinancierSelectors,
  useGetFinancierCountriesQuery,
  useGetFinanciersQuery,
  createFinancierCountriesSelectors,
  useGetFinancierSectorsQuery,
} from '../FinanciersApiSlice'
import LineSpan from '../../shared/components/LineSpan'
import { selectSectorById } from '../../companies/CompaniesApiSlice'
import { IFinancierSector } from '../interfaces'
import { INameIdPair } from '../../shared/interfaces'

type FinancierGeneralInformationProps = {
  handleClose: () => void
  showModal: boolean
  currentPage: number
  pageSize: number
}

const FinancierGeneralInformation: FC<FinancierGeneralInformationProps> = ({
  handleClose,
  showModal,
  currentPage,
  pageSize,
}) => {
  const {id} = useParams<{id: string}>() as {id: string}
  useGetFinanciersQuery({page: currentPage, page_size: pageSize, query: ''})
  useGetFinancierCountriesQuery()

  const {data: financierSectorsState} = useGetFinancierSectorsQuery(id)
  const financierSectorsList:IFinancierSector[] = Object.values(financierSectorsState?.entities || {}) as IFinancierSector[]

  const {selectById: selectFinancierById} = createFinancierSelectors(currentPage, pageSize, '')
  const {selectById: selectFinancierCountryById} = createFinancierCountriesSelectors()

  const financierDetails = useSelector((state: RootState) => selectFinancierById(state, id))

  const {countries, sectors}: {countries: INameIdPair[], sectors: INameIdPair[]} = useSelector((state: RootState) => {
    const selectedCountries =
      financierDetails?.countries?.map((countryId) =>
        selectFinancierCountryById(state, countryId)
      ).filter((country): country is INameIdPair => country !== undefined) ?? []
  
    const selectedSectors =
      financierSectorsList.map((sector: IFinancierSector) =>
        sector ? selectSectorById(state, sector.id) : undefined
      ).filter((sector): sector is INameIdPair => sector !== undefined) ?? []
  
    return {countries: selectedCountries, sectors: selectedSectors}
  })

  return (
    <div className='position-relative'>
      <div className='card card-flush card-bordered mt-4 mb-4'>
        <div className='card-body py-5'>
          <div className='row pb-2'>
            <div className='col-md-6'>
              <h3 className='fs-8'>Name</h3>
              <p className='fs-6'>{financierDetails?.name || <LineSpan />}</p>
            </div>
          </div>
          <div className='row pb-2'>
            <div className='col-md-12'>
              <h3 className='fs-8'>About</h3>
              <p className='fs-6'>{financierDetails?.about || <LineSpan />}</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <h3 className='fs-8'>Slogan</h3>
              <p className='fs-6'>{financierDetails?.slogan || <LineSpan />}</p>
            </div>
            <div className='col-md-6'>
              <h3 className='fs-8'>Address</h3>
              <p className='fs-6'>{financierDetails?.address || <LineSpan />}</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-6'>
              <h3 className='fs-8'>Country</h3>
              <p className='fs-6'>{financierDetails?.country || <LineSpan />}</p>
            </div>
            <div className='col-md-6'>
              <h3 className='fs-8'>Region/Province</h3>
              <p className='fs-6'>{financierDetails?.region || <LineSpan />}</p>
            </div>
          </div>

          <div className='row'>
            {/* <div className='col-md-6'>
              <h3 className='fs-8'>Countries of Investment Interest</h3>
              <p className='fs-6'>
                {countries?.length ? countries.map((country) => country?.name).join(', ') : <LineSpan />}
              </p>
            </div> */}
            <div className='col-md-6'>
              <h3 className='fs-8'>Sectors of Interest</h3>
              <span className='fs-6'>
                {sectors?.length ? sectors.map((sector:INameIdPair) => sector?.name).join(', ') : <LineSpan />}
              </span>
            </div>
          </div>
        </div>
      </div>
      <EditButton handleClose={handleClose} />
      <UpdateFinancierGeneralInformation
        showModal={showModal}
        handleClose={handleClose}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </div>
  )
}

export default FinancierGeneralInformation
