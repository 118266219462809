import {useParams} from 'react-router-dom'
import {
  useGetCompanyDetailsQuery,
  useGetCompanyContactsQuery,
} from '../CompaniesApiSlice'
import Loading from '../../shared/components/Loading'
import {FC} from 'react'
import {EditCompanyContactInformation} from '../modals/EditCompanyContactInformation'
import EditButton from '../../shared/components/EditButton'
import LineSpan from '../../shared/components/LineSpan'

type CompanyContactInformationProps = {
  showModal: boolean
  handleClose: () => void
}

const CompanyContactInformation: FC<CompanyContactInformationProps> = ({
                                                                         showModal,
                                                                         handleClose,
                                                                       }) => {
  const {id} = useParams<{id: string}>() as {id: string}
  const {data: companyState} = useGetCompanyDetailsQuery(id)

  const {data: companyContactsState, isLoading: companyContactsLoading} =
    useGetCompanyContactsQuery(id)

  let companyContact = null
  if (companyContactsState?.entities) {
    const contactsArray = Object.values(companyContactsState?.entities)
    companyContact = contactsArray.length > 0 ? contactsArray[0] : null
  }

  const companyDetails = companyState?.entities[id]

  if (companyContactsLoading) return <Loading />
  return (
    <div className="position-relative">
      <div className="card card-flush card-bordered mt-4 mb-4">
        <div className="card-body py-5">
          <div className="row">
            <div className="col-md-6">
              <h3 className="fs-8 m-0">Country</h3>
              <p className="fs-6">{companyDetails?.country}</p>
            </div>
            <div className="col-md-6">
              <h3 className="fs-8 m-0">Region</h3>
              <p className="fs-6">{companyDetails?.region || <LineSpan />}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <h3 className="fs-8 m-0">District</h3>
              <p className="fs-6">{companyDetails?.district || <LineSpan />}</p>
            </div>
            <div className="col-md-6">
              <h3 className="fs-8 m-0">Address</h3>
              <p className="fs-6">{companyDetails?.address || <LineSpan />}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <h3 className="fs-8 m-0">Facebook</h3>
              <p className="fs-6">{companyDetails?.facebook || <LineSpan />}</p>
            </div>
            <div className="col-md-6">
              <h3 className="fs-8 m-0">LinkedIn</h3>
              <p className="fs-6">{companyDetails?.linkedIn || <LineSpan />}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <h3 className="fs-8 m-0">Twitter</h3>
              <p className="fs-6">{companyDetails?.twitter || <LineSpan />}</p>
            </div>
            <div className="col-md-6">
              <h3 className="fs-8 m-0">Website</h3>
              <p className="fs-6">{companyDetails?.website || <LineSpan />}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <h3 className="fs-8 m-0">Name of Contact Person</h3>
              <p className="fs-6">{companyContact?.companyContactPersonName || <LineSpan />}</p>
            </div>
            <div className="col-md-6">
              <h3 className="fs-8 m-0">Email</h3>
              <p className="fs-6">{companyContact?.companyContactPersonEmail || <LineSpan />}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <h3 className="fs-8 m-0">Phone Number</h3>
              <p className="fs-6">{companyContact?.companyContactPersonTelephone || <LineSpan />}</p>
            </div>
            <div className="col-md-6">
              <h3 className="fs-8 m-0">Address</h3>
              <p className="fs-6">{companyContact?.companyContactPersonAddress || <LineSpan />}</p>
            </div>
          </div>
        </div>
      </div>
      <EditButton handleClose={handleClose} />
      <EditCompanyContactInformation showModal={showModal} handleClose={handleClose} />
    </div>
  )
}

export default CompanyContactInformation
