import  {FC} from 'react'

type props = {
  selectedTab: string
  setSelectedTab: (tab:string) => void
}

export const SettingsSideMenu: FC<props> = ({selectedTab, setSelectedTab}) => {
  const navigationTabs = [
    {name: 'Sectors', link: '#sectors'},
    {name: 'Financing Types', link: '#financing-types'},
    {name: 'Users', link: '#users'},
  ]
  return (
    <div className='card card-flush card-bordered card-60vh'>
      {navigationTabs.map((tab, index) => (
        <div key={index} className={`settings-item ${selectedTab === tab.name ? 'selected-setting' : ''} d-flex`} onClick={() => setSelectedTab(tab.name)}>
          <span className='flex-start'>{tab.name}</span>   
        </div>
      ))}
    </div>
  )
}