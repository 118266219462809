import {FC} from 'react'

type CardBadgeProps = {
  text: string
}
const CardBadge: FC<CardBadgeProps> = ({text}) => {
  const colors = [
    { text: 'agriculture', color: '#FFFFFF', background: '#0C751D' },
    { text: 'completed', color: '#08B124', background: '#10FB3733' },
    { text: 'in progress', color: '#FAA707', background: '#FBAB1033' },
    { text: 'manufacturing', color: '#FFFFFF', background: '#AA0050' },
    { text: 'debt', color: '#5F1ED9', background: '#5F1ED933' },
    { text: 'retail', color: '#FFFFFF', background: '#0C751D' },
    { text: 'equity', color: '#D0021B', background: '#FFBDCB' },
    { text: 'grant', color: '#D3900D', background: '#FBAB1033' },
    { text: 'tourism', color: '#FFFFFF', background: '#0A2240' },
    { text: 'tourism,retail and property', color: '#FFFFFF', background: '#0A2240' },
    { text: 'education', color: '#FFFFFF', background: '#181C32' },
    { text: 'legal', color: '#FFFFFF', background: '#0A2240' },
    { text: 'bronze', color: '#FFFFFF', background: '#816F0F' },
    { text: 'gold', color: '#FFFFFF', background: '#F4D323' },
    { text: 'silver', color: '#FFFFFF', background: '#7D7D81' },
  ];

  const colorScheme = colors.find(
    (color) => color.text.toLowerCase().includes(text.toLocaleLowerCase().substring(0, 3))
) || { color: '#FFFFFF', background: '#0A2240' };

  return (
    <span
      className={`badge me-2 mb-2`}
      style={{
        backgroundColor: colorScheme.background,
        color: colorScheme.color,
      }}
    >
      {text}
    </span>
  );
}
export default CardBadge
