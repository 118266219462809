import { Log, User, UserManager } from 'oidc-client-ts';
import { config } from './AuthConfig';

class AuthService {
    public userManager: UserManager;

    constructor() {
        // @ts-ignore
        this.userManager = new UserManager(config);

        Log.setLogger(console);
        Log.setLevel(Log.INFO);
    }

    public getUser(): Promise<User | null> {
        return this.userManager.getUser();
    }

    public async login(): Promise<void> {
        await this.userManager.signinRedirect();
    }

    // public renewToken(): Promise<User> {
    //     return this.userManager.signinSilent();
    // }

    public async logout(): Promise<void> {
        return await this.userManager.signoutRedirect();
    }

    public signinRedirectCallback(): Promise<User> {
        return this.userManager.signinRedirectCallback();
    }
}

const authService = new AuthService();

export default authService;