import * as Yup from 'yup'

export const createServiceProviderSchema = Yup.object({
  generalInformation: Yup.object().shape({
    name: Yup.string().required('Business Name is required').label('Name'),
    about: Yup.string().required('Summary is required').label('About'),
    country: Yup.string().required('Country is required').label('Country'),
    district: Yup.string().required('District is required').label('District'),
    logo: Yup.mixed()
      .label('Company logo')
      .test('fileType', 'Only image files are allowed', (value) => {
        if (!value) return true // Allow empty value
        if (value instanceof File) {
          return value.type.startsWith('image/')
        }
        return false
      }),
  }),
  businessOperations: Yup.object().shape({
    dateOfIncorporation: Yup.number()
    .nullable()
    .label('Year of Incorporation')
    .min(1900, 'Year must be 1900 or later')
    .max(new Date().getFullYear(), `Year must be ${new Date().getFullYear()} or earlier`)
    .typeError('Year must be a valid number')
    .test('len', 'Year must be exactly 4 digits', (val) => !val || val.toString().length === 4),
    // serviceCategory: Yup.string().required('Service Category is required').label('Service Category'),
    sectors: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required(),
        name: Yup.string().required(),
      })
    )
    .nullable(),
    serviceOffering: Yup.string().nullable(),
  }),
  contactPerson: Yup.object().shape({
    name: Yup.string().required('Contact Person Name is required').label('Name'),
    email: Yup.string().nullable(),
    phoneNumber: Yup.string()
      .matches(/^[0-9]{9}$/, 'Phone number must be 9 digits')
      .nullable()
      .label('Contact Person Telephone'),
    address: Yup.string().nullable().label('Address'),
  }),
})