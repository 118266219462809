import {FC, useEffect, useState} from 'react'
import {PageButton, PageTitle} from '../../../../_theme/layout/core'
import {IUser} from '../interfaces'

interface Props {
  id: string
}

const toolbarActions: Array<PageButton> = [
  {
    title: 'Create User',
    class: 'btn-danger fs-4',
    target: 'createSystemUserModal',
    id: 'create_app_btn',
  },
]

const fakeUsers: Array<IUser> = [
  {
    id: '1',
    firstname: 'Atwiine',
    lastname: 'Warren',
    telephone: '0753481731',
    email: 'atwiine.warren@asigmagroup.com',
    category: 'Admin',
  },
  {
    id: '2',
    firstname: 'Wilson',
    lastname: 'Kiggundu',
    telephone: '0783481732',
    email: 'wilson.kiggundu@asigmagroup.com',
    category: 'User',
  },
  {
    id: '3',
    firstname: 'Collines',
    lastname: 'Mukasa',
    telephone: '0773481733',
    email: 'collines.mukasa@asigmagroup.com',
    category: 'User',
  },
  {
    id: '4',
    firstname: 'Tuhimbise',
    lastname: 'Augustine',
    telephone: '0743481734',
    email: 'tuhimbise.augustine@asigmagroup.com',
    category: 'Admin',
  },
  {
    id: '5',
    firstname: 'Arnold',
    lastname: 'Kigonya',
    telephone: '0763481735',
    email: 'arnold.kigonya@asigmagroup.com',
    category: 'User',
  },
  {
    id: '6',
    firstname: 'Samuel',
    lastname: 'Ninsiima',
    telephone: '0753481736',
    email: 'samuel.ninsiima@asigmagroup.com',
    category: 'User',
  },
  {
    id: '7',
    firstname: 'Maggie',
    lastname: 'Namaganda',
    telephone: '0783481737',
    email: 'maggie.namaganda@asigmagroup.com',
    category: 'Admin',
  },
  {
    id: '8',
    firstname: 'Shane',
    lastname: 'Epimat',
    telephone: '0773481738',
    email: 'shane.epimat@asigmagroup.com',
    category: 'User',
  },
  {
    id: '9',
    firstname: 'Jean',
    lastname: 'Kekirunga',
    telephone: '0743481739',
    email: 'jean.kekirunga@asigmagroup.com',
    category: 'User',
  },
  {
    id: '10',
    firstname: 'Lizel',
    lastname: 'Muwaya',
    telephone: '0763481740',
    email: 'lizel.muwaya@asigmagroup.com',
    category: 'Admin',
  },
]

export const SystemUsers = () => {
  const [users, setUsers] = useState<Array<IUser>>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setUsers(fakeUsers)
    setLoading(false)
  }, [])

  // @ts-ignore
  return (
    <>

      <PageTitle toolbarActions={toolbarActions} breadcrumbs={[]}>
        System users
      </PageTitle>

      {loading ? (
        <div
          style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}
        >
          <div className="d-flex flex-column align-items-center" style={{height: '25%'}}>
            <div className="spinner-border" role="status"></div>
          </div>
        </div>
      ) : (
        <div className="row">
          {users &&
            users.map((user, index) => (
              <div key={index} className="col-md-6 col-lg-4 col-xxl-3">
                <div className="card mb-6">
                  <div className="card-body d-flex flex-center flex-column py-9 px-5">
                    <div className="symbol symbol-65px symbol-circle mb-5">
                      <span className="symbol-label bg-danger text-inverse-primary fw-bold">
                        {user.firstname.toUpperCase().slice(0, 1)}{' '}
                        {user.lastname.toUpperCase().slice(0, 1)}
                      </span>
                      <div
                        className="bg-success position-absolute rounded-circle translate-middle start-100 top-100 border border-4 border-body h-15px w-15px ms-n3 mt-n3" />
                      {' '}
                    </div>

                    <a href="#" className="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">
                      {user.firstname.slice(0, 15).charAt(0).toUpperCase() +
                        user.firstname.slice(1, 15)}
                      &nbsp;&nbsp;
                      {user.lastname.slice(0, 15).charAt(0).toUpperCase() +
                        user.lastname.slice(1, 15)}
                    </a>
                    <div className="fw-semibold text-gray-400">{user.telephone.slice(1, 15)}</div>
                    <div className="fw-semibold text-gray-400 mb-6">
                      {user.email?.slice(0, 15)}
                      {user.email && user.email.length > 15 ? '...' : ''}
                    </div>

                    <div className="d-flex justify-content-start">
                      <button className="btn btn-sm btn-light-primary btn-flex btn-center px-2 mx-2">
                        <span className="indicator-label px-2 mx-2">Update</span>
                      </button>
                      <button
                        className="btn btn-sm btn-light-info btn-flex btn-center px-2 mx-2"
                        data-bs-toggle="modal"
                        data-bs-target={`#${'userDeleteConfirmationModal-' + user.id}`}
                      >
                        <span className="indicator-label px-2 mx-2">Remove</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  )
}
