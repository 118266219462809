import * as Yup from 'yup'

export const CompanyFormValidationSchema = Yup.object({
  details: Yup.object().shape({
    name: Yup.string().required('Company name is required').label('Company Name'),
    summary: Yup.string().required('Company about is required').label('About the Company'),
    logo: Yup.mixed()
      .label('Company logo')
      .test('fileType', 'Only image files are allowed', (value) => {
        if (!value) return true // Allow empty value
        if (value instanceof File) {
          return value.type.startsWith('image/')
        }
        return false
      }),
    country: Yup.string().required('Country is required').label('Country'),
    region: Yup.string().required('Region is required').label('Region'),
    district: Yup.string().required('District is required').label('District'),
  }),
  operations: Yup.object().shape({
    dateOfIncorporation: Yup.number()
      .nullable()
      .label('Year of Incorporation')
      .min(1900, 'Year must be 1900 or later')
      .max(new Date().getFullYear(), `Year must be ${new Date().getFullYear()} or earlier`)
      .typeError('Year must be a valid number')
      .test('len', 'Year must be exactly 4 digits', (val) => !val || val.toString().length === 4),
      sectors: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required(),
          name: Yup.string().required(),
        })
      )
      .nullable(),
    subSector: Yup.string().nullable().label('SubSector'),
    productLine: Yup.string().nullable().label('Product Line'),
  }),
  financials: Yup.object().shape({
    financingTypes: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required('ID is required'),
        name: Yup.string().required('Name is required'),
      })
    )
    .nullable(),
    financingAsk: Yup.string()
      .nullable()
      .label('Financing Ask'),
    financingAskCurrency: Yup.string()
      .label('Financing A Currency'),
  }),
  contactPerson: Yup.object().shape({
    contactPersonName: Yup.string()
      .required("Contact Person's name is required")
      .label('Contact Person Name'),
    contactPersonEmail: Yup.string()
      .email('Please Enter a Valid Email')
      .nullable()
      .label('Contact Person Email'),
    contactPersonTelephone: Yup.string()
      .matches(/^[0-9]{9}$/, 'Phone number must be 9 digits')
      .nullable()
      .label('Contact Person Telephone'),
  }),
  reviewAndSubmit: Yup.object().shape({}),
})
