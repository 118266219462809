interface PaginationState {
  currentPage: number
  pageSize: number
  searchTerm: string
}

export function getPaginationState(location: any): PaginationState {
    const state = location.state as { currentPage?: number; pageSize?: number; searchTerm?: string } | null;
    return {
      currentPage: state?.currentPage ?? 1,
      pageSize: state?.pageSize ?? 6,
      searchTerm: state?.searchTerm ?? ''
  }
}
