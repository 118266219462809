import {FC} from 'react'

interface ButtonProps {
  displayText?: string
  buttonClass: 'primary' | 'outline' | 'secondary' | 'danger' | 'info' | 'outline-danger'
  isDisabled: boolean
  link?: string
  target?: string
  id?: string
  textColor?: string
  fitContent?: boolean
  isHeaderToggle?:boolean
  onClick?: () => void
}

const Button: FC<ButtonProps> = ({
  displayText,
  buttonClass,
  isDisabled,
  target,
  link,
  id,
  fitContent = false,
  isHeaderToggle = false,
  onClick,
}: ButtonProps) => {
  return (
    <>
      <a
        aria-disabled={isDisabled}
        href={link}
        className={`btn btn-sm m-lg-2 pb-3 btn-` + buttonClass + (fitContent ? ' fit-content' : '')}
        data-bs-toggle='modal'
        data-bs-target={target}
        id={id}
        onClick={onClick}
      >
       {isHeaderToggle ? <i className='fas fa-ellipsis-h'></i> : displayText}
      </a>
    </>
  )
}

export default Button
