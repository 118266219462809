import {Field} from 'formik'
import {useGetSectorsQuery} from '../../companies/CompaniesApiSlice'
import {MultiSelect} from '../../shared/components/MultiSelect'
import { INameIdPair } from '../../shared/interfaces'

const CreateBusinessOperations = ({
  setFieldValue,
  errors,
  touched,
  values
}: any) => {
  const {data: sectorsState} = useGetSectorsQuery()

  const companySectorsList = Object.values(sectorsState?.entities || {}).filter(
    Boolean
  ) as INameIdPair[]

  return (
    <div data-kt-stepper-element='content'>
      <div className='w-100'>
        {/*Region */}
        <div className='fv-row row mb-10 p-0'>
          <div className='col-lg-6'>
            <label className=' fs-4 f-s fw-bold mb-2'>Year of Incorporation</label>
            <Field
              type='number'
              className='form-control form-control-lg py-lg-5'
              name='businessOperations.yearOfIncorporation'
              placeholder=''
            />
            {touched?.businessOperations?.yearOfIncorporation && (
              <div className='text-danger mt-2'>
                {errors?.businessOperations?.yearOfIncorporation}
              </div>
            )}
          </div>
          <div className='col-lg-6'>
            <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
              <span>Service Offering</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg py-lg-5 custom-placeholder'
              name='businessOperations.serviceOffering'
              placeholder='Due Diligence'
            />
            {touched?.businessOperations?.serviceOffering && (
              <div className='text-danger mt-2'>{errors?.businessOperations?.serviceOffering}</div>
            )}
          </div>
          <div className='col-lg-6'>
            <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
              <span className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>Sector</span>
            </label>

            <MultiSelect
              selectedValues={values.businessOperations.sectors}
              options={companySectorsList}
              setOption={(selectedOptions) => setFieldValue('businessOperations.sectors', selectedOptions)}
              size='lg'
            />
            {touched?.businessOperations?.sector && (
              <div className='text-danger mt-2'>{errors?.businessOperations?.sector}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export {CreateBusinessOperations}
