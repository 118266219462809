import React, {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
import {ICompanyFinancials, ICompanyDetails, ICompanyFinancingAsks} from '../interfaces'
import {KTSVG} from '../../../../_theme/helpers'
import {useParams} from 'react-router-dom'
import {
  useGetCompanyDetailsQuery,
  useGetFinancingTypesQuery,
  useGetCompanyFinancialsQuery,
  useUpdateCompanyFinancialsMutation,
  useUpdateCompanyMutation,
  useAddNewCompanyFinancialsMutation,
} from '../CompaniesApiSlice'
import NumberFormatter from '../../shared/components/NumberFormatter'
import {MultiSelect} from '../../shared/components/MultiSelect'
import {INameIdPair} from '../../shared/interfaces'

type Props = {
  showModal: boolean
  handleClose: () => void
}

export const EditCompanyFinancials: React.FC<Props> = ({handleClose, showModal}) => {
  //const financials: string = company.financingType

  const validationSchema = Yup.object().shape({
    financingAsk: Yup.string().nullable(),
    financingAskCurrency: Yup.string().label('Financing ask Currency'),
    financingTypes: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required(),
          name: Yup.string().required(),
        })
      )
      .nullable(),
    annualRevenue: Yup.string().nullable(),
    annualRevenueCurrency: Yup.string().label('Annual revenue Currency'),
    netProfit: Yup.string().nullable(),
    netProfitCurrency: Yup.string().label('Net profit Currency'),
    assetValue: Yup.string().nullable(),
    assetValueCurrency: Yup.string().label('Asset Value Currency'),
    suitability: Yup.string().nullable(),
  })
  const [updateCompany, {isLoading: isUpdateCompanyLoading}] = useUpdateCompanyMutation()
  const [updateCompanyFinancials, {isLoading: isUpdateCompanyFinancialsLoading}] =
    useUpdateCompanyFinancialsMutation()
  const [addNewCompanyFinancials, {isLoading: isAddNewCompanyFinancialsLoading}] =
    useAddNewCompanyFinancialsMutation()

  const {id} = useParams<{id: string}>() as {id: string}
  const {data: companyFinancialsState}: any = useGetCompanyFinancialsQuery(id)
  const {data: companyState} = useGetCompanyDetailsQuery(id)
  const companyDetails: ICompanyDetails = companyState?.entities[id] ?? ({} as ICompanyDetails)
  const [companyFinancial] = Object.values(
    companyFinancialsState?.entities ?? {}
  ) as ICompanyFinancingAsks[]
  const {data: financingTypesState} = useGetFinancingTypesQuery()

  const financingTypeSelection = Object.values(financingTypesState?.entities || {}).filter(
    (type): type is INameIdPair => Boolean(type)
  )

  const FinancialsFormInitialValues = {
    financingAskCurrency: companyFinancial?.currency || '0',
    annualRevenueCurrency: companyDetails?.annualRevenueCurrency || '0',
    netProfitCurrency: companyDetails?.netProfitCurrency || '0',
    assetValueCurrency: companyDetails?.assetValueCurrency || '0',
    financingAsk: companyFinancial?.ask || '',
    annualRevenue: companyDetails?.annualRevenue || '',
    netProfit: companyDetails?.netProfit || '',
    assetValue: companyDetails?.assetValue || '',
    suitability: companyDetails?.suitability || '',
    financingTypes: companyDetails?.financingTypes || [],
  }

  const handleSave = async (values: any) => {
    const updatedCompanyDetails = {
      ...companyDetails,
      annualRevenue: values.annualRevenue.replace(/,/g, '') || 0,
      annualRevenueCurrency: Number(values.annualRevenueCurrency) ?? 1,
      netProfit: values.netProfit.replace(/,/g, '') || 0,
      netProfitCurrency: Number(values.netProfitCurrency) ?? 1,
      assetValue: values.assetValue.replace(/,/g, '') || 0,
      assetValueCurrency: Number(values.assetValueCurrency) ?? 1,
      suitability: Number(values.suitability),
      financingTypes: values.financingTypes,
    }

    const promises = []

    promises.push(updateCompany(updatedCompanyDetails).unwrap())

    if (!companyFinancial && values.financingAsk) {
      const newFinancingAskDetails = {
        ask: values.financingAsk.replace(/,/g, '') || 0,
        companyId: companyDetails.id,
        currency: Number(values.financingAskCurrency) ?? 0,
      }
      promises.push(addNewCompanyFinancials(newFinancingAskDetails).unwrap())
    }
    if (companyFinancial) {
      const updatedFinancingAskDetails = {
        id: companyFinancial?.id,
        ask: values.financingAsk.replace(/,/g, '') || 0,
        companyId: companyDetails.id,
        currency: Number(values.financingAskCurrency) ?? 0,
      }
      promises.push(updateCompanyFinancials(updatedFinancingAskDetails).unwrap())
    }

    try {
      await Promise.all(promises)
      handleClose()
    } catch (error) {}
  }

  return (
    <>
      <Modal
        id='editFinancialsModal'
        aria-hidden={true}
        dialogClassName='h-auto modal-dialog-centered'
        tabIndex={-1}
        show={showModal}
      >
        <div className='modal-content w-700px' role='document'>
          <Formik
            initialValues={FinancialsFormInitialValues}
            validationSchema={validationSchema}
            onSubmit={handleSave}
          >
            {({errors, touched, isValid, values, setFieldValue}) => {
              return (
                <Form>
                  <div className='modal-header'>
                    <h5 className='modal-title ps-1' id='editFinancialsModalLabel'>
                      Edit Financials
                    </h5>
                    <div
                      className='btn btn-sm btn-icon btn-active-color-danger'
                      data-bs-dismiss='modal'
                      onClick={handleClose}
                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                    </div>
                  </div>
                  <div className='modal-body px-9'>
                    <div className='row '>
                      <div className='col-md-6 mb-3'>
                        <label className='d-flex align-items-center fs-4 f-s fw-bold mb-2'>
                          <span>Financing Types</span>
                        </label>
                        <MultiSelect
                          selectedValues={values.financingTypes || []}
                          options={financingTypeSelection}
                          setOption={(selectedOptions) =>
                            setFieldValue('financingTypes', selectedOptions)
                          }
                        />
                      </div>
                      <div className='col-md-6 mb-3'>
                        <label
                          htmlFor='debtCurrency'
                          className='d-flex align-items-center fs-5 fw-bold mb-2'
                        >
                          Investment Ask
                        </label>
                        <div className='input-group input-group-sm mb-3'>
                          <div className='input-group-prepend'>
                            <Field
                              as='select'
                              name='financingAskCurrency'
                              className='form-select form-select-sm form-select-solid rounded-end-0 currency-picker'
                              multiple={false}
                              value={values.financingAskCurrency}
                            >
                              <option value='1'>UGX</option>
                              <option value='0'>USD</option>
                              <option value='2'>EUR</option>
                            </Field>
                          </div>
                          <NumberFormatter
                            name='financingAsk'
                            className='form-control-sm border-start-0 rounded-start-0 currency-picker'
                            setFieldValue={setFieldValue}
                            initialValue={values.financingAsk}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row '>
                      <div className='col-md-6 mb-3'>
                        <label
                          htmlFor='annualRevenue'
                          className='d-flex align-items-center fs-5 fw-bold mb-2'
                        >
                          Annual Revenue
                        </label>
                        <div className='input-group input-group-sm mb-3'>
                          <div className='input-group-prepend'>
                            <Field
                              as='select'
                              name='annualRevenueCurrency'
                              className='form-select form-select-sm form-select-solid rounded-end-0 currency-picker'
                              multiple={false}
                            >
                              <option value='1'>UGX</option>
                              <option value='0'>USD</option>
                              <option value='2'>EUR</option>
                            </Field>
                          </div>
                          <NumberFormatter
                            name='annualRevenue'
                            className='form-control-sm border-start-0 rounded-start-0 currency-picker'
                            setFieldValue={setFieldValue}
                            initialValue={values.annualRevenue}
                          />
                        </div>
                      </div>
                      <div className='col-md-6 mb-3'>
                        <label
                          htmlFor='netProfit'
                          className='d-flex align-items-center fs-5 fw-bold mb-2'
                        >
                          Net Profit
                        </label>
                        <div className='input-group input-group-sm mb-3'>
                          <div className='input-group-prepend'>
                            <Field
                              as='select'
                              name='netProfitCurrency'
                              className='form-select form-select-sm form-select-solid rounded-end-0 currency-picker'
                              multiple={false}
                            >
                              <option value='1'>UGX</option>
                              <option value='0'>USD</option>
                              <option value='2'>EUR</option>
                            </Field>
                          </div>
                          <NumberFormatter
                            name='netProfit'
                            className='form-control-sm border-start-0 rounded-start-0 currency-picker'
                            setFieldValue={setFieldValue}
                            initialValue={values.netProfit}
                          />
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-6 mb-3'>
                          <label
                            htmlFor='assetValue'
                            className='d-flex align-items-center fs-5 fw-bold mb-2'
                          >
                            Asset Value
                          </label>
                          <div className='input-group input-group-sm mb-3'>
                            <div className='input-group-prepend'>
                              <Field
                                as='select'
                                name='assetValueCurrency'
                                className='form-select form-select-sm form-select-solid rounded-end-0 currency-picker'
                                multiple={false}
                              >
                                <option value='1'>UGX</option>
                                <option value='0'>USD</option>
                                <option value='2'>EUR</option>
                              </Field>
                            </div>
                            <NumberFormatter
                              name='assetValue'
                              className='form-control-sm border-start-0 rounded-start-0 currency-picker'
                              setFieldValue={setFieldValue}
                              initialValue={values.assetValue}
                            />
                          </div>
                        </div>

                        <div className='col-md-6 mb-3'>
                          <label className='d-flex align-items-center'>
                            <span className='fs-5 fw-bold'>Suitability</span>
                          </label>
                          <Field
                            as='select'
                            name='suitability'
                            className='form-select form-select-sm select2-hidden-accessible currency-picker mt-2'
                          >
                            <option value='NULL'>Select</option>
                            <option value='1'>Yes</option>
                            <option value='2'>No</option>
                          </Field>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-secondary text-dark'
                      onClick={handleClose}
                    >
                      Close
                    </button>
                    <button
                      type='submit'
                      className='btn btn-danger'
                      onClick={() => {
                        handleSave(values)
                      }}
                      disabled={
                        !isValid ||
                        isUpdateCompanyLoading ||
                        isUpdateCompanyFinancialsLoading ||
                        isAddNewCompanyFinancialsLoading
                      }
                    >
                      <span className='indicator-label'>
                        {!isUpdateCompanyLoading &&
                          !isUpdateCompanyFinancialsLoading &&
                          !isAddNewCompanyFinancialsLoading &&
                          'Save'}
                        {(isUpdateCompanyLoading ||
                          isUpdateCompanyFinancialsLoading ||
                          isAddNewCompanyFinancialsLoading) && (
                          <>
                            <span className='spinner-border h-15px w-15px align-middle text-white-400'></span>
                          </>
                        )}
                      </span>
                    </button>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </Modal>
    </>
  )
}
