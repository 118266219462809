import {useState} from 'react'
import Search from '../../shared/components/Search'
import CardBadge from '../../shared/components/CardBadge'
import {useGetServiceProviderProjectsQuery} from '../ServiceProvidersApiSlice'
import {useParams} from 'react-router-dom'
import Loading from '../../shared/components/Loading'
import {formatDateToLocaleString} from '../../../../utils/dateHelpers'
import {trimString} from '../../../../utils/stringHelpers'



const ServiceProviderProjects = () => {
  const {id} = useParams<{id: string}>() as {id: string}
  const [searchTerm, setSearchTerm] = useState('')
  const [allChecked, setAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>({});
  const {data: serviceProviderProjectsState, isLoading} = useGetServiceProviderProjectsQuery(id)
  let serviceProviderProjects: any = []
  if (serviceProviderProjectsState?.entities) {
    serviceProviderProjects = Object.values(serviceProviderProjectsState?.entities)
  }
  const projectStages = [
    {stage: 'In Progress', value: 0},
    {stage: 'Completed', value: 1},
  ] 

  const handleAllCheckedChange = (e:any) => {
    const { checked } = e.target;
    setAllChecked(checked);
    const updatedCheckedItems: { [key: string]: boolean } = {};
    serviceProviderProjects.forEach((project: any) => {
      updatedCheckedItems[project.id] = checked;
    });
    setCheckedItems(updatedCheckedItems);
  };

  const handleItemCheckedChange = (e:any, id:any) => {
    const { checked } = e.target;
    setCheckedItems((prevCheckedItems:any) => ({
      ...prevCheckedItems,
      [id]: checked,
    }));
  };

  return (
    <>
      <div className='py-5 px-0'>
        <div className='d-flex align-items-center justify-content-between position-relative'>
          <div className='d-flex align-items-center gap-2'>
            <Search setSearchTerm={setSearchTerm} />
            <div className='d-flex align-items-center justify-content-center button-transparent gap-3 w-50px h-40px'>
              <span>8</span>
              <i className='fa fa-caret-down text-dark' aria-hidden='true'></i>
            </div>
          </div>
          <div className='d-flex align-items-center gap-3'>
            <div className='d-flex align-items-center gap-2'>
              <div className='d-flex align-items-center justify-content-evenly gap-2 button-transparent w-100px h-40px'>
                <i className='bi bi-filter fs-2 fw-bolder text-danger' aria-hidden='true'></i>
                <span>Filters</span>
              </div>
              <div className='d-flex align-items-center justify-content-evenly gap-2 button-transparent w-100px h-40px'>
                <i className='fa fa-bars text-danger' aria-hidden='true'></i>
                <span>Columns</span>
              </div>
              <div className='button-transparent w-50px h-40px d-flex align-items-center justify-content-center'>
                <i className='fas fa-ellipsis-v fs-6 text-danger'></i>
              </div>
            </div>
          </div>
        </div>
        <div className='table-responsive pt-7'>
      <table className='table .table-row-bordered table-row-gray-300 gs-1 gy-3 gx-7'>
        <thead>
          <tr className='fw-bold fs-7 text-gray-800 border-bottom border-gray-200'>
            <th className='pb-2'>
              <div className='form-check form-check-custom form-check-solid form-check-sm'>
                <input
                  className='form-check-input w-15px h-15px'
                  type='checkbox'
                  checked={allChecked}
                  onChange={handleAllCheckedChange}
                  id='flexRadioLg'
                />
              </div>
            </th>
            <th className='fw-bolder px-0'>Project Name</th>
            <th className='fw-bolder px-0'>Project Description</th>
            <th className='fw-bolder px-0'>Project Owner</th>
            <th className='fw-bolder px-0'>Assigned On</th>
            <th className='fw-bolder px-0'>Project Stage</th>
            <th className='fw-bolder px-0'>Action</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={7}>
                <div className='d-flex justify-content-center'>
                  <Loading />
                </div>
              </td>
            </tr>
          ) : (
            serviceProviderProjects &&
            serviceProviderProjects.map((project:any) => (
              <tr key={project.id} className='border-bottom border-gray-200'>
                <td className='pb-2'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm'>
                    <input
                      className='form-check-input w-15px h-15px'
                      type='checkbox'
                      checked={checkedItems[project.id] || false}
                      onChange={(e) => handleItemCheckedChange(e, project.id)}
                      id={`flexRadioLg-${project.id}`}
                    />
                  </div>
                </td>
                <td className='fs-7 fw-bold px-0'>{project.name}</td>
                <td className='ps-0 pe-0'>
                  {trimString(project.description ?? '', 34, true)}
                </td>
                <td className='ps-0'>{project.owner}</td>
                <td className='ps-0'>
                  {project.dateCreated
                    ? formatDateToLocaleString(project.dateCreated)
                    : 'NULL'}
                </td>
                <td className='ps-0'>
                  <CardBadge
                    text={
                      projectStages.find((stage) => stage.value === project.projectStage)?.stage ?? ''
                    }
                  />
                </td>
                <td className='ps-3 cursor-pointer'>
                  <i className='bi bi-eye'></i>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
      </div>
    </>
  )
}

export {ServiceProviderProjects}
