import {IStepperItem} from '../../interfaces/IStepperItem'
import {StepperItem} from './StepperItem'

type IProps = {
  items: Array<IStepperItem>
}

export const Stepper = ({items}: IProps) => {
  const totalSteps = items.length
  return (
    <>
      {items.map((item: IStepperItem, index) => (<StepperItem key={index} {...item} totalSteps={totalSteps} />))}
    </>
  )
}