import {FC, useState} from 'react'
import {useSelector} from 'react-redux'
import {FinancierCard} from '../components/FinancierCard'
import {RootState} from '../../../../data/redux/Store'
import {Spinner} from 'react-bootstrap'
import Pagination from '../../shared/components/Pagination'
import useAuthToken from '../../../../hooks/useAuthToken'
import {Toolbar} from '../../../../_theme/layout/components/Toolbar'
import {
  useGetFinanciersQuery,
  createFinancierSelectors,
} from '../FinanciersApiSlice'
import FinancierFilters from '../components/FinancierFilters'
import FinancierSearch from '../components/FinancierSearch'
import EntityListSearch from '../../shared/components/EntityListSearch'
import { useNavigate } from 'react-router'

const Financiers: FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [pageSize] = useState<number>(6)
  const isTokenAvailable = useAuthToken()
  const navigate = useNavigate()

  const {data, error, isFetching, isSuccess, isError} = useGetFinanciersQuery(
    {page: currentPage, page_size: pageSize,query:searchTerm},
    {skip: !isTokenAvailable},
  )
  const {selectIds: selectFinanciersIds} = createFinancierSelectors(currentPage, pageSize,searchTerm)
  const financiersIds = useSelector((state: RootState) => selectFinanciersIds(state)) as string[]
  const paginationMetadata = data?.paginationMetadata
  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  let content
  if (isFetching) {
    content = (
      <div className="d-flex justify-content-center flex-column gap-3 align-items-center h-100">
        <Spinner animation="border" variant="danger" />
        <p className="pt-0">Loading...</p>
      </div>
    )
  } else if (isSuccess) {
    content =
      financiersIds.length > 0 ? (
        <div className="row">
          {financiersIds.map((id) => (
            <FinancierCard key={id} id={id} page={currentPage} pageSize={pageSize} searchTerm={searchTerm} />
          ))}
        </div>
      ) : (
        <div className="d-flex justify-content-right align-items-right vh-100">
          <em>There are no financiers listed yet.</em>
        </div>
      )
  } else if (isError) {
    console.log(error)
    if ('status' in error) {
      const fetchError = error as {status: number; data: unknown; error: string}
      const errorMessage =
        typeof fetchError.error === 'object' ? JSON.stringify(fetchError.error) : fetchError.error
        if (fetchError.status >= 500 || fetchError.status === 404 || errorMessage === 'TypeError: Failed to fetch') {
          navigate('/error')
        } else {
          content = (
            <div className='d-flex justify-content-center align-items-center vh-100'>
              {errorMessage}
            </div>
          )
        }
    } else if ('message' in error) {
      content = (
        <div className="d-flex justify-content-center align-items-center vh-100">
          {error.message}
        </div>
      )
    } else {
      content = (
        <div className="d-flex justify-content-center align-items-center vh-100">
          Unknown error occurred
        </div>
      )
    }
  }

  return (
    <div className="d-flex flex-column h-100">
      <Toolbar page="Financiers" link="/financiers/add" buttonTitle="Add New Financier">
      <EntityListSearch setSearchTerm={setSearchTerm} handlePageChange={handlePageChange} />
        <FinancierFilters />
      </Toolbar>
      <div className="flex-grow-1">{content}</div>
      <div className="flex-shrink-0">
        {data && paginationMetadata && (
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(paginationMetadata.totalCount / paginationMetadata.pageSize)}
            onPageChange={handlePageChange}
            entriesCount={paginationMetadata.totalCount}
            entriesPerPage={paginationMetadata.pageSize}
          />
        )}
      </div>
    </div>
  )
}

export {Financiers}
