import React from 'react'
import ReactDOM from 'react-dom/client'
import {Provider} from 'react-redux'
import store from './data/redux/Store'
import {AuthProvider} from 'react-oidc-context'
import {config} from './app/features/auth/AuthConfig'

// Apps
import {App} from './app/App'
import {BlackI18nProvider} from './_theme/i18n/Blacki18n'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_theme/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_theme/assets/css/style.rtl.css'
 **/
import './styles/custom.css'
import './_theme/sass/style.scss'
import './_theme/sass/style.react.scss'
import {AuthInit} from './app/features/auth/AuthInit'
import {AppRoutes} from './app/routing/AppRoutes'

const {PUBLIC_URL} = process.env

if (!('process' in window)) {
  // @ts-ignore
  window.process = {}
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BlackI18nProvider>
        <AuthProvider {...config} >
          <AuthInit>
            <AppRoutes />
          </AuthInit>
        </AuthProvider>
      </BlackI18nProvider>
    </Provider>
  </React.StrictMode>,
)
