type EntitySize = 'Small' | 'Medium' | 'Large' ;

export default function getEntitySize(entityCount: number): EntitySize {
    let size: EntitySize;
    switch (true) {
        case (entityCount < 50):
            size = 'Small';
            break;
        case (entityCount <= 100):
            size = 'Medium';
            break;
        default:
            size = 'Large';
    }
    return size;
}

