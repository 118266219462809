import React from 'react'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {PageLink, usePageData} from "../../core";
import {MenuItem} from "./MenuItem";

interface Props {
    items: Array<PageLink>
}

export const MenuInner = ({items}: Props) => {
    const {innerMenu} = usePageData()

    return (
        <>
            {innerMenu?.map((menu, index) => {
                if (menu.subMenu?.length) {
                    return (
                        <MenuInnerWithSub
                            key={index}
                            title={menu.title}
                            to={menu.path}
                            menuPlacement='bottom-start'
                            menuTrigger='click'
                        >
                            {menu.subMenu?.map((item, index) => (<MenuItem to={item.path} title={item.title} hasBullet={true} />))}
                        </MenuInnerWithSub>
                    )
                }else {
                    return <MenuItem key={index} title={menu.title} to={menu.path} />
                }
            })}

        </>
    )
}


