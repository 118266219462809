/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts from 'apexcharts'
import {getCSS} from '../../../../../_theme/utils'
import {getChartOptions} from '../../../../../utils/chartHelpers'

type Props = {
  horizontal: boolean
  className: string
  dataSrc: string
  chartTitle: string
  chartSubTitle?: string
}

export const SingleDataSetBarChart: React.FC<Props> = (props) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  const {className, chartTitle, chartSubTitle, horizontal, dataSrc} = props

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const series = [
      {
        name: 'Net Profit',
        data: [40, 35, 57, 60, 41],
      },
    ]

    const categories = ['Tech', 'Agric', 'Retail', 'Travel', 'Art']

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, series, categories, horizontal),
    )
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef])

  return (
    <div className={`card ${className}`}>
      <div className="card-header border-0 pt-5 pb-0 mb-0">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3 mb-1">{chartTitle}</span>
          {chartSubTitle && <span className="text-muted fw-bold fs-7">{chartSubTitle}</span>}
        </h3>
      </div>
      <div className="card-body pt-0">
        <div ref={chartRef} id="kt_charts_widget_1_chart" style={{height: '350px'}} />
      </div>
    </div>
  )
}
