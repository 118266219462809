export const getInitials = (fullName = "", numInitials?: number) => {
  try {
    if (!fullName) return "";
    fullName = fullName.replace(/\band\b/gi, "&");
    const words = fullName.split(" ");
    let initials = "";
    let count = 0;

    for (const word of words) {
      if (word) {
        const initial = word[0].toUpperCase();
        initials += initial;
        if (initial === "&") {
          // Always include the next initial after '&'
          continue;
        }
        count++;
        if (numInitials && count >= numInitials) break;
      }
    }

    return initials;
  } catch (e) {
    return "";
  }
};

export function getRandomStr(max = 16) {
  const letters = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let str = "";
  for (let i = 0; i < max; i++) {
    str += letters[Math.floor(Math.random() * max)];
  }
  return str;
}

export function createUrlSegment(text: string){
  return text
      .replace(/\s+/g, '-')
      .replace(/[^0-9a-z-]/gi, '')
      .slice(0, 25)
      .toLowerCase()
}

export function enumToArray(typeData: any) {
  return Object.keys(typeData);
}

export const trimString = (data: string, count = 10, hasEllipsis = false) => {
  if (!data) return "";
  if (data.length > count) return `${data.slice(0, count)}${hasEllipsis ? '...' : ''}`;
  return data;
};

// Split pascal case
export function camelPad(str: string) {
  return (
    str
      // Look for long acronyms and filter out the last letter
      .replace(/([A-Z]+)([A-Z][a-z])/g, " $1 $2")
      // Look for lower-case letters followed by upper-case letters
      .replace(/([a-z\d])([A-Z])/g, "$1 $2")
      // Look for lower-case letters followed by numbers
      .replace(/([a-zA-Z])(\d)/g, "$1 $2")
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
      // Remove any white space left around the word
      .trim()
  );
}

export function toTitleCase(str: string) {

  if(!str) return ''

  return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
      }
  );
}

export function formatPhoneNumber(number: string){
  return number ? number.match(/.{1,3}/g)?.join(" ") : ""
}

export function maskNIN(nin: string){
  if(!nin) return ''
  return nin.slice(0,5) + '***********'
}