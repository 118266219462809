/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, createContext, useContext, useEffect, useState} from 'react'

export interface PageLink {
  title: string
  path: string
  isActive?: boolean
  isSeparator?: boolean
  subMenu?: Array<PageLink>
}

export interface PageButton {
  title?: string
  link?: string
  target?: string
  class:
    | 'btn-primary'
    | 'btn-default'
    | 'btn-secondary'
    | 'btn-info'
    | 'btn-danger fs-4'
    | 'btn-danger bi bi-arrow-left fs-4'
    | 'btn-outline btn-outline-secondary bi bi-filter fs-4'
    | 'btn-outline btn-outline-secondary bi bi-search fs-4 pe-20'
    | undefined
  id: string
}

export interface PageDataContextModel {
  innerMenu?: Array<PageLink>
  setInnerMenu: (_links: Array<PageLink>) => void
  pageTitle?: string
  setPageTitle: (_title: string) => void
  pageDescription?: string
  setPageDescription: (_description: string) => void
  pageBreadcrumbs?: Array<PageLink>
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void
  setToolbarActions: (_actions: Array<PageButton>) => void
  toolbarActions?: Array<PageButton>
}

const PageDataContext = createContext<PageDataContextModel>({
  setPageTitle: (_title: string) => {
  },
  setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {
  },
  setPageDescription: (_description: string) => {
  },
  setToolbarActions: (_actions: Array<PageButton>) => {
  },
  setInnerMenu: (_actions: Array<PageLink>) => {
  },
})

const PageDataProvider: React.FC = ({children}) => {
  const [pageTitle, setPageTitle] = useState<string>('')
  const [pageDescription, setPageDescription] = useState<string>('')
  const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([])
  const [innerMenu, setInnerMenu] = useState<Array<PageLink>>([])
  const [toolbarActions, setToolbarActions] = useState<Array<PageButton>>([])
  const value: PageDataContextModel = {
    pageTitle,
    setPageTitle,
    pageDescription,
    setPageDescription,
    pageBreadcrumbs,
    setPageBreadcrumbs,
    toolbarActions,
    setToolbarActions,
    innerMenu,
    setInnerMenu,
  }
  return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>
}

function usePageData() {
  return useContext(PageDataContext)
}

type Props = {
  description?: string
  breadcrumbs?: Array<PageLink>
  toolbarActions?: Array<PageButton>
  innerMenu?: Array<PageLink>
}

const PageTitle: FC<Props> = ({children, description, breadcrumbs, toolbarActions, innerMenu}) => {
  const {setPageTitle, setPageDescription, setPageBreadcrumbs, setToolbarActions, setInnerMenu} =
    usePageData()

  useEffect(() => {
    if (children) {
      setPageTitle(children.toString())
    }
    return () => {
      setPageTitle('')
    }
  }, [children])

  useEffect(() => {
    if (description) {
      setPageDescription(description)
    }
    return () => {
      setPageDescription('')
    }
  }, [description])

  useEffect(() => {
    if (breadcrumbs) {
      setPageBreadcrumbs(breadcrumbs)
    }
    return () => {
      setPageBreadcrumbs([])
    }
  }, [breadcrumbs])

  useEffect(() => {
    if (toolbarActions) {
      setToolbarActions(toolbarActions)
    }
    return () => {
      setToolbarActions([])
    }
  }, [toolbarActions])

  useEffect(() => {
    if (innerMenu) {
      setInnerMenu(innerMenu)
    }
    return () => {
      setInnerMenu([])
    }
  }, [innerMenu])

  return null
}

const PageMenu: FC<Props> = ({innerMenu}): any => {
  const {setInnerMenu} = usePageData()

  useEffect(() => {
    if (innerMenu) {
      setInnerMenu(innerMenu)
    }
    return () => {
      setInnerMenu([])
    }
  }, [innerMenu])

  return <></>
}

const PageDescription: React.FC = ({children}) => {
  const {setPageDescription} = usePageData()
  useEffect(() => {
    if (children) {
      setPageDescription(children.toString())
    }
    return () => {
      setPageDescription('')
    }
  }, [children])
  return <></>
}

export {PageDescription, PageTitle, PageMenu, PageDataProvider, usePageData}
