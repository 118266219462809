import React, {FC} from 'react'
import {useNavigate} from 'react-router-dom'
import {getInitials, trimString} from '../../../../utils/stringHelpers'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import CardInitials from '../../shared/components/CardInitials'
import CardBadge from '../../shared/components/CardBadge'
import CardDetail from '../../shared/components/CardDetail'
import {createFinancierSelectors} from '../FinanciersApiSlice'
import {INameIdPair} from '../../shared/interfaces'
import {ratings} from '../constants/Ratings'
import {useGetDocumentsQuery} from '../../documents/DocumentsApiSlice'
import CustomSkeleton from '../../shared/components/CustomSkeleton'

type FinancierCardProps = {
  id: string
  page: number
  pageSize: number
  searchTerm: string
}

export const FinancierCard: FC<FinancierCardProps> = ({id, page, pageSize,searchTerm}) => {
  const navigate = useNavigate()
  const {selectById: selectFinancierById} = createFinancierSelectors(page, pageSize,searchTerm)
  const financier = useSelector((state: RootState) => selectFinancierById(state, id))

  const {data: documentsState, isLoading: gettingLogo} = useGetDocumentsQuery(
    financier?.logo ? [financier.logo] : [],
    {
      skip: !financier,
    }
  )
  let logo
  if (financier) {
    logo = documentsState?.entities[financier.logo]
  }

  const financierInitials = getInitials(financier?.name,3)
  const sizes = [
    Number(financier?.debtMaximumTicketSize),
    Number(financier?.grantMaximumTicketSize),
    Number(financier?.equityMaximumTicketSize),
  ].filter((size): size is number => !isNaN(size) && size > 0)
  sizes.sort((a, b) => a - b) // Sort the sizes in ascending order

  const maximumTicketSize = sizes[sizes.length - 1] || 0
  const minimumTicketSize = sizes[0] || 0

  const handleNavigate = () => {
    navigate(`/financiers/${financier?.id}`, {
      state: {currentPage: page, pageSize},
    })
  }

  const getRatingLabel = (value: string | undefined): string => {
    const rating = ratings.find((r) => r.value === value)
    return rating ? rating.label : ''
  }

  const financingTypes = [
    (financier?.debtMaximumTicketSize ?? 0) > 0 ? 'Debt' : '',
    (financier?.grantMaximumTicketSize ?? 0) > 0 ? 'Grant' : '',
    (financier?.equityMaximumTicketSize ?? 0) > 0 ? 'Equity' : '',
  ].filter((type) => type)

  return (
    <div className='col-md-6 col-lg-4 col-sm-12'>
      <div
        onClick={handleNavigate}
        className='card border border-2 border-gray-300 mb-5 cursor-pointer min-height-360px d-flex flex-column justify-content-between'
      >
        <div className='d-flex border-0 pt-9 px-5 justify-content-between'>
          <div className='pb-7'>
            {gettingLogo ? (
              <CustomSkeleton height={50} width={50} className='rounded' />
            ) : !logo ? (
              <CardInitials
                initials={financierInitials}
                title={financier?.name ?? ''}
                tooltip={true}
                width={financierInitials.length >= 4 ? 75 : 50}
              />
            ) : (
              <img
                src={`data:${logo.fileType};base64,${logo.fileContent}`}
                alt={logo.fileName}
                height={50}
                width={50}
                className='rounded'
              />
            )}
          </div>
          <div className='d-flex py-4'>
            <CardBadge text={getRatingLabel(financier?.classificationRating) ?? ''} />
          </div>
        </div>
        <div className='px-5 mb-5'>
          <span className='fw-bolder text-dark fs-3'>{financier?.name}</span>
        </div>
        <div className='d-flex justify-content-center align-items-start px-5 mb-5'>
          <div className='col-md-6 pe-0'>
            <CardDetail
              title='Minimum Ticket Size'
              value={`${financier?.currency} ${minimumTicketSize.toLocaleString()}`}
            />
          </div>
          <div className='col-md-6 ps-0'>
            <CardDetail
              title='Maximum Ticket Size'
              value={`${financier?.currency} ${maximumTicketSize.toLocaleString()}`}
            />
          </div>
        </div>
        <div className='px-5 mb-5 d-flex justify-content-between align-items-center h-50px'>
          <span className='fw-bold text-dark fs-5 w-50'>Location</span>
          <span className='fw-bolder text-dark fs-5 w-50'>
          <span className='fw-bolder text-dark fs-5 w-50'>
            {`${financier?.region ? `${financier.region}, ` : ''}${financier?.country}`}
          </span>
          </span>
        </div>
        <div className='d-flex align-items-center pb-8 pt-5  px-5 h-50px'>
          {financingTypes.map((type: string, index: number) => (
            <CardBadge key={index} text={type} />
          ))}
        </div>
      </div>
    </div>
  )
}
