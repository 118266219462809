/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useLayoutEffect} from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import * as am4maps from '@amcharts/amcharts4/maps'
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow'


type Props = {
  horizontal: boolean
  className: string
  dataSrc: {id: string; value: number, financiers: number, serviceProviders: number}[]
  chartTitleFigure: string
  chartSubTitle?: string
}

export const GlobalMapChartCard: React.FC<Props> = (props) => {

  useLayoutEffect(() => {
    am4core.useTheme(am4themes_animated)

    var chart = am4core.create('chartdiv', am4maps.MapChart)
    chart.hiddenState.properties.opacity = 0

    chart.geodata = am4geodata_worldLow
    chart.projection = new am4maps.projections.Orthographic()

    var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries())
    var polygonTemplate = polygonSeries.mapPolygons.template

    polygonTemplate.tooltipText = '{name}: \n Companies - {value} \nFinanciers - {financiers} \n Service providers - {serviceproviders} '

    polygonSeries.heatRules.push({
      property: 'fill',
      target: polygonSeries.mapPolygons.template,
      min: am4core.color('#ffffff'),
      max: am4core.color('#009EF7'),
    })
    polygonSeries.useGeodata = true

    // add heat legend
    var heatLegend = chart.chartContainer.createChild(am4maps.HeatLegend)
    heatLegend.valign = 'bottom'
    heatLegend.align = 'left'
    heatLegend.width = am4core.percent(100)
    heatLegend.series = polygonSeries
    heatLegend.orientation = 'horizontal'
    heatLegend.padding(20, 20, 20, 20)
    heatLegend.valueAxis.renderer.labels.template.fontSize = 10
    heatLegend.valueAxis.renderer.minGridDistance = 40

    polygonSeries.mapPolygons.template.events.on('over', event => {
      handleHover(event.target)
    })

    polygonSeries.mapPolygons.template.events.on('hit', event => {
      handleHover(event.target)
    })

    function handleHover(mapPolygon: am4maps.MapPolygon) {
      if (!isNaN(mapPolygon.dataItem.value)) {
        heatLegend.valueAxis.showTooltipAt(mapPolygon.dataItem.value)
      } else {
        heatLegend.valueAxis.hideTooltip()
      }
    }

    polygonSeries.mapPolygons.template.strokeOpacity = 0.4
    polygonSeries.mapPolygons.template.events.on('out', event => {
      heatLegend.valueAxis.hideTooltip()
    })

    polygonTemplate.events.on('hit', function(event) {
      const dataContext = event.target.dataItem.dataContext as {
        value: number;
        financiers: number;
        serviceproviders: number;
      }

      if (
        dataContext &&
        dataContext.value !== undefined &&
        dataContext.financiers !== undefined &&
        dataContext.serviceproviders !== undefined
      ) {
        polygonTemplate.tooltipText = '{name}: \n' +
          'Companies - {value} \n' +
          'Financiers - {financiers} \n' +
          'Service providers - {serviceproviders}'
      } else {
        polygonTemplate.tooltipText = ''
      }
    })

    chart.zoomControl = new am4maps.ZoomControl()
    chart.zoomControl.valign = 'top'


// life expectancy data
    polygonSeries.data = dataSrc

// excludes Antarctica
    polygonSeries.exclude = ['AQ']


    return () => {
      chart.dispose()
    }
  }, [])


  const {
    className,
    chartTitleFigure,
    chartSubTitle,
    horizontal,
    dataSrc,
  } = props

  return (
    <>
      <div className="card card-flush h-md-95">
        <div className="card-header pt-7">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bold text-dark">
              {chartTitleFigure}
            </span>
            <span className="text-gray-400 pt-2 fw-semibold fs-6">
              {chartSubTitle}
            </span>
          </h3>
        </div>
        <div className="card-body d-flex flex-center">
          <div id="chartdiv" className="w-100 h-400px">
          </div>
        </div>
      </div>
      {' '}
    </>
  )
}


function handleHover(target: am4maps.MapPolygon) {
  throw new Error('Function not implemented.')
}

