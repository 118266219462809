import {useParams, useNavigate} from 'react-router-dom'
import DetailsHeaderNavigationBar from '../../shared/components/DetailsHeaderNavigationBar'
import React, {FC, useState} from 'react'
import {
  createFinancierSelectors,
  useDeleteFinancierMutation,
  useGetFinanciersQuery,
} from '../FinanciersApiSlice'
import CardBadge from '../../shared/components/CardBadge'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import EditButton from '../../shared/components/EditButton'
import {EditFinancierLogo} from '../modals'
import {getInitials} from '../../../../utils/stringHelpers'
import CardInitials from '../../shared/components/CardInitials'
import {countries} from '../../../../utils/countries'
import {useGetDocumentsQuery} from '../../documents/DocumentsApiSlice'
import CustomSkeleton from '../../shared/components/CustomSkeleton'
import {useIsGlobalAdmin} from '../../../../_theme/utils/authUtil'
import {OptionsPopup} from '../../shared/components/OptionsPopup'
import { DeleteModal } from '../../shared/components/DeleteModal'

interface HeaderProps {
  selectedTab: string
  setSelectedTab: (tab: string) => void
  currentPage: number
  pageSize: number
}

const FinancierHeader: FC<HeaderProps> = ({selectedTab, setSelectedTab, currentPage, pageSize}) => {
  const [showModal, setShowModal] = useState(false)

  const navigationTabs = [
    {name: 'General Information'},
    {name: 'Financials'},
    {name: 'Contact Information'},
    {name: 'Documents'},
    {name: 'Deals'},
    {name: 'Opportunities'},
  ]

  const {id} = useParams<{id: string}>() as {id: string}
  useGetFinanciersQuery({page: currentPage, page_size: pageSize, query: ''})
  const navigate = useNavigate()
  const [deleteFinancier, {isLoading}] = useDeleteFinancierMutation()
  useGetFinanciersQuery({page: currentPage, page_size: pageSize, query: ''})
  const {selectById: selectFinancierById} = createFinancierSelectors(currentPage, pageSize, '')
  const financierDetails = useSelector((state: RootState) => selectFinancierById(state, id))

  const {data: documentsState, isLoading: gettingLogo} = useGetDocumentsQuery(
    financierDetails?.logo ? [financierDetails.logo] : [],
    {
      skip: !financierDetails?.logo,
    }
  )
  let logo
  if (financierDetails) {
    logo = documentsState?.entities[financierDetails.logo]
  }

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const financierInitials = getInitials(financierDetails?.name, 3)
  const handleClose = () => {
    setShowModal(!showModal)
  }

  const handleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal)
  }
  const handleDelete = async () => {
    await deleteFinancier(id).unwrap()
    navigate('/financiers')
  }

  const financierCode = `${id.slice(0, 6)}${
    countries.find((country) => country.label === financierDetails?.country)?.value || ''
  }`
  const financingTypes = [
    (financierDetails?.debtMaximumTicketSize ?? 0) > 0 ? 'Debt' : '',
    (financierDetails?.grantMaximumTicketSize ?? 0) > 0 ? 'Grant' : '',
    (financierDetails?.equityMaximumTicketSize ?? 0) > 0 ? 'Equity' : '',
  ].filter((type) => type)

  const isGlobalAdmin = useIsGlobalAdmin()

  return (
    <div className='card card-flush shadow-sm'>
      <div className='card-body py-5 gap-5 pb-0'>
        <div className='d-flex justify-content-between pb-12'>
          <div className='d-flex align-items-start'>
            <div className='position-relative'>
              {gettingLogo ? (
                <CustomSkeleton height={120} width={120} className='rounded' />
              ) : !logo ? (
                <CardInitials
                  initials={financierInitials}
                  width={financierInitials.length >= 4 ? 150 : 120}
                  height={120}
                  fontSize={48}
                />
              ) : (
                <img
                  src={`data:${logo.fileType};base64,${logo.fileContent}`}
                  alt={logo.fileName}
                  height={120}
                  width={120}
                  className='rounded'
                />
              )}
              <EditButton handleClose={handleClose} customClass='logo-edit-button' />
            </div>

            <div className='pt-5 d-flex flex-column gap-2 p-50px'>
              <span className='fw-bolder text-dark fs-1'>{financierCode}</span>
              <span className='fw-bold text-dark fs-4'>{financierDetails?.name}</span>
              <span className='fw-light text-dark fs-5'>{financierDetails?.slogan}</span>
              <div className='d-flex flex-wrap'>
                {financingTypes.map((type: string, index: number) => (
                  <CardBadge key={index} text={type} />
                ))}
              </div>
            </div>
          </div>

          <div className='d-flex gap-0 justify-content-center align-items-start'>
            <div className='pt-2'>
              <button className='btn btn-sm m-lg-2 btn-outline btn-outline-danger btn-active-light-danger'>
                0 Closed Deals
              </button>
            </div>
            {isGlobalAdmin && (
              <OptionsPopup
                handleDelete={handleDelete}
                handleDeleteModal={handleDeleteModal}
                isHeaderToggle={true}
                includeDeleteOption={true}
              />
            )}
          </div>
        </div>
        <div className='d-flex gap-5'>
          {navigationTabs.map((tab, index) => (
            <DetailsHeaderNavigationBar
              key={index}
              tab={tab}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          ))}
        </div>
      </div>
      <EditFinancierLogo
        showModal={showModal}
        handleClose={handleClose}
        currentPage={currentPage}
        pageSize={pageSize}
      />
      <DeleteModal
        showDeleteModal={showDeleteModal}
        isLoading={isLoading}
        handleDelete={handleDelete}
        item={'Financier'}
        handleClose={handleDeleteModal}
      />
    </div>
  )
}

export default FinancierHeader
