import {useParams} from 'react-router-dom'
import {useGetCompanyDetailsQuery} from '../CompaniesApiSlice'
import {FC} from 'react'
import {EditCompanyOperation} from '../modals'
import getEntitySize from '../../../../utils/getEntitySize'
import LineSpan from '../../shared/components/LineSpan'
import { numberCurrencyPairs } from '../../shared/constants'

type CompanyOperationProps = {
  showModal: boolean
  handleClose: () => void
}

export const CompanyOperations: FC<CompanyOperationProps> = ({showModal, handleClose}) => {
  const {id} = useParams<{id: string}>() as {id: string}
  const {data: companyState} = useGetCompanyDetailsQuery(id)
  const companyDetails = companyState?.entities[id]

  return (
    <div className='position-relative'>
      <div className='card card-flush card-bordered mt-4 mb-4'>
        <div className='card-body py-5'>
          <div className='row mb-4'>
            <div className='col-md-12'>
              <h3 className='fs-8 m-0'>About Company</h3>
              <p className='text-justify'>{companyDetails?.summary || <LineSpan />}</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <h3 className='fs-8 m-0'>Slogan</h3>
              <p className='fs-6'>{companyDetails?.slogan || <LineSpan />}</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <h3 className='fs-8 m-0'>Year of Incorporation</h3>
              <p className='fs-6'>
              {companyDetails?.yearOfIncorporation || <LineSpan />}
              </p>
            </div>
            <div className='col-md-6'>
              <h3 className='fs-8 m-0'>Sub-sector</h3>
              <p className='fs-6'>{companyDetails?.subSector || <LineSpan />}</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <h3 className='fs-8 m-0'>Product Line</h3>
              <p className='fs-6'>{companyDetails?.productLine || <LineSpan />}</p>
            </div>
            <div className='col-md-6'>
              <h3 className='fs-8 m-0'>Company Size</h3>
              <p className='fs-6'>
                {companyDetails?.numberOfEmployees != null ? (
                  getEntitySize(companyDetails.numberOfEmployees)
                ) : (
                  <LineSpan />
                )}
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <h3 className='fs-8 m-0'>No of Employees</h3>
              <p className='fs-6'>
                {companyDetails?.numberOfEmployees != null ? (
                  companyDetails.numberOfEmployees.toLocaleString()
                ) : (
                  <LineSpan />
                )}
              </p>            </div>
            <div className='col-md-6'>
              <h3 className='fs-8 m-0'>Number of Female Employees</h3>
              <p className='fs-6'>
                {companyDetails?.numberOfFemaleEmployees != null ? (
                  companyDetails.numberOfFemaleEmployees.toLocaleString()
                ) : (
                  <LineSpan />
                )}
              </p>            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <h3 className='fs-8 m-0'>No of Youth Employees</h3>
              <p className='fs-6'>
                {companyDetails?.numberOfYouthEmployees != null ? (
                  companyDetails.numberOfYouthEmployees.toLocaleString()
                ) : (
                  <LineSpan />
                )}
              </p>            </div>
            <div className='col-md-6'>
              <h3 className='fs-8 m-0'>Average Income earned by beneficiaries</h3>
              <p className='fs-6'>
                {companyDetails?.averageIncomeByBeneficiaries != null ? (
                  `${numberCurrencyPairs[Number(companyDetails?.averageIncomeByBeneficiariesCurrency) ?? 0]} ${companyDetails.averageIncomeByBeneficiaries.toLocaleString()}`
                ) : (
                  <LineSpan />
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <button
        className='position-absolute btn btn-link p-0 company-edit-button'
        onClick={handleClose}
      >
        <i className='fas fa-pen text-danger'></i>
      </button>
      <EditCompanyOperation showModal={showModal} handleClose={handleClose} />
    </div>
  )
}
