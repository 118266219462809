import {FC, useState} from 'react'
import {ButtonGroup, Dropdown} from 'react-bootstrap'
import Button from './Button'
import clsx from 'clsx'
import {useAuth} from 'react-oidc-context' // Import useAuth
import {toAbsoluteUrl} from '../../../../_theme/helpers'
import {Link} from 'react-router-dom'

type props = {
  handleEntityOptionsPopup?: () => void
  selectedEntity?: any
  setSelectedEntity?: (entity: any) => void
  updatingEntity?: boolean
  deletingEntity?: boolean
  gettingFile?: boolean
  triggerDownload?: boolean
  handleUpdateEntity?: (name: string) => void
  handleDelete?: () => void
  handlePreview?: () => void
  showDeleteModal?: boolean
  showEditModal?: boolean
  showUploadDocumentModal?: boolean
  showCreateFolderModal?: boolean
  handleEditModal?: () => void
  handleUploadFileModal?: () => void
  handleCreateFolderModal?: () => void
  handleDownload?: () => void
  handleLogout?: () => void
  handleDeleteModal?: () => void
  includeDeleteOption?: boolean
  includeFilePreviewOption?: boolean
  includeUpdateOption?: boolean
  includeDownloadOption?: boolean
  includeUploadFile?: boolean
  includeCreateFolder?: boolean
  entity?: string
  isHeaderToggle?: boolean
  isEllipsis?: boolean
  isButton?: boolean
  handleUploadFile?: (res: any, selectedFolder: string) => void
  isFinancierHeader?: boolean
  includeFinancierHeaderOptions?: boolean
}
export const OptionsPopup: FC<props> = ({
  handleEntityOptionsPopup,
  selectedEntity,
  setSelectedEntity,
  updatingEntity,
  deletingEntity,
  handleUpdateEntity,
  handleDelete,
  showEditModal,
  showUploadDocumentModal,
  showCreateFolderModal,
  showDeleteModal,
  handleEditModal,
  handleCreateFolderModal,
  handleDeleteModal,
  handlePreview,
  handleDownload,
  handleUploadFileModal,
  gettingFile = false,
  includeDeleteOption = false,
  includeFilePreviewOption = false,
  includeUpdateOption = false,
  includeDownloadOption = false,
  isHeaderToggle = false,
  entity,
  triggerDownload,
  isButton,
  isEllipsis,
  includeCreateFolder,
  includeUploadFile,
  handleUploadFile,
  isFinancierHeader = false,
  includeFinancierHeaderOptions = false,
  handleLogout,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
  const auth = useAuth()
  const user = auth.user
  const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
    toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'
  return (
    <>
      <Dropdown
        as={ButtonGroup}
        show={dropdownOpen || gettingFile}
        onToggle={() => setDropdownOpen(!dropdownOpen)}
      >
        <Dropdown.Toggle
          as='button'
          className='bg-transparent dropdown-toggle-custom p-2 m-0 border-0 caret-off'
        >
          {isHeaderToggle && (
            <Button
              buttonClass={'secondary'}
              isDisabled={true}
              id='more-actions'
              fitContent={true}
              isHeaderToggle={isHeaderToggle}
            />
          )}
          {isEllipsis && <i className='fas fa-ellipsis-v'></i>}
          {isFinancierHeader && (
            <div className='d-flex'>
              <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
                <div className='mx-2 text-white'>Welcome, {user?.profile?.given_name}</div>{' '}
              </div>

              <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
                <div
                  className={clsx('cursor-pointer symbol fs-24px', toolbarUserAvatarHeightClass)}
                >
                  <img src={toAbsoluteUrl('/media/images/user-profile-image.svg')} alt='' />
                </div>
              </div>
            </div>
          )}
          {isButton && (
            <button
              type='button'
              className='btn btn-danger d-flex align-items-center justify-content-center btn-sm'
            >
              <i className='fas fa-plus fs-5 me-1'></i>
              <span className='ms-1'>New</span>
            </button>
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {includeUpdateOption && (
            <Dropdown.Item
              onClick={(event) => {
                event.stopPropagation()
                handleEditModal && handleEditModal()
              }}
              className='no-bg-change mb-3'
            >
              <i className='fas fa-pen me-5 icon-spacing fs-6'></i>
              <span className='fs-6'>Rename</span>
            </Dropdown.Item>
          )}
          {includeUploadFile && (
            <Dropdown.Item
              onClick={(event) => {
                event.stopPropagation()
                handleCreateFolderModal && handleCreateFolderModal()
              }}
              className='no-bg-change mb-3'
            >
              <i className='fas fa-folder-open me-5 text-warning icon-spacing fs-6'></i>
              <span className='fs-6'>Create Folder</span>
            </Dropdown.Item>
          )}
          {includeCreateFolder && (
            <Dropdown.Item
              onClick={(event) => {
                event.stopPropagation()
                handleUploadFileModal && handleUploadFileModal()
              }}
              className='no-bg-change mb-3'
            >
              <i className='fas fa-file me-7 text-danger icon-spacing fs-6'></i>
              <span className='fs-6'>Upload File</span>
            </Dropdown.Item>
          )}
          {includeFilePreviewOption && (
            <Dropdown.Item onClick={handlePreview} className='no-bg-change'>
              <i className='fas fa-eye me-5 icon-spacing fs-6'></i>
              <span className='fs-6'>Preview</span>
            </Dropdown.Item>
          )}
          {includeDownloadOption && (
            <Dropdown.Item
              onClick={(event) => {
                event.stopPropagation()
                handleDownload && handleDownload()
              }}
              className={`no-bg-change ${triggerDownload || gettingFile ? 'text-danger' : ''}`}
              disabled={triggerDownload || gettingFile}
            >
              <i className='fas fa-download me-5 icon-spacing fs-6 text-dark'></i>
              <span className='indicator-label fs-6'>
                {!gettingFile ? (
                  'Download'
                ) : (
                  <span className='spinner-border h-15px w-15px align-middle text-white-400'></span>
                )}
              </span>
            </Dropdown.Item>
          )}
          {includeDeleteOption && (
            <Dropdown.Item
              onClick={(event) => {
                event.stopPropagation()
                handleDeleteModal && handleDeleteModal()
              }}
              className='no-bg-change text-hover-danger'
            >
              <i className='fas fa-trash-alt me-5 icon-spacing fs-6 text-danger'></i>
              <span className='fs-6'>Delete</span>
            </Dropdown.Item>
          )}
          {includeFinancierHeaderOptions && (
            <>
              <Dropdown.Item className='menu-item px-3'>
                <div className='menu-content d-flex align-items-center px-3'>
                  <div className='d-flex flex-column'>
                    <div className='fw-bolder d-flex align-items-center fs-5'>
                      {user?.profile?.given_name} {user?.profile?.family_name}
                    </div>
                    <a href='#' className='fw-bold text-muted fs-7'>
                      {user?.profile?.email}
                    </a>
                  </div>
                </div>
              </Dropdown.Item>
              {/* <Dropdown.Item className='menu-item px-5'>
                <div className='header-separator my-1'></div>
                <a href='#' className='menu-link px-5 text-hover-danger text-black fw-bolder'>
                  Profile
                </a>
              </Dropdown.Item> */}
              <Dropdown.Item className='menu-item px-5'>
                <div className='header-separator my-1'></div>
                <Link
                  to={''}
                  className='menu-link px-5 text-hover-danger text-black fw-bolder'
                  onClick={handleLogout}
                >
                  Sign Out
                </Link>
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
      {/* {showDeleteModal && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          isLoading={deletingEntity || false}
          handleDelete={handleDelete || (() => {})}
          item={entity || ''}
          handleClose={handleDeleteModal || (() => {})}
        />
      )} */}
      {/* {showEditModal &&  (
        <UpdateFolder
          showEditModal={showEditModal}
          selectedFolder={selectedEntity}
          setSelectedFolder={setSelectedEntity || (() => {})}
          handleEditModal={handleEditModal || (() => {})}
          isLoading={updatingEntity || false}
          handleUpdateFolder={handleUpdateEntity || (() => {})}
        />
      )} */}
      {/* {showCreateFolderModal && (
        <CreateFolder
          showCreateFolderModal={showCreateFolderModal}
          handleCreateFolderModal={handleCreateFolderModal || (() => {})}
          parentFolderId={selectedEntity}
          entity={entity}
        />
      )} */}
      {/* {showUploadDocumentModal && (
        <UploadDocument
          showUploadDocumentModal={showUploadDocumentModal}
          handleUploadFileModal={handleUploadFileModal || (() => {})}
          selectedFolder={selectedEntity}
          handleUploadFile={handleUploadFile || (() => {})}
        />
      )} */}
    </>
  )
}
