import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  companyFilters: {
    countries: [],
    minYearsInOperation: '',
    maxYearsInOperation: '',
    sectors: [],
    financingTypes: [],
    minInvestmentAsk: '', 
    maxInvestmentAsk: '', 
    investmentAskCurrency: 0, 
  },
  providerFilters: {
    countries: [],
    minYearsInOperation: '',
    maxYearsInOperation: '',
    sectors: [],
  },
  showFilters:false
  // other settings can be added here
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setCompanyFilters(state, action) {
      state.companyFilters = action.payload;
    },
    setProviderFilters(state, action) {
      state.providerFilters = action.payload;
    },
    toggleFiltersState(state, action) {
      state.showFilters = action.payload;
    },
  },
});

export const { setCompanyFilters,setProviderFilters,toggleFiltersState } = settingsSlice.actions;
export default settingsSlice.reducer;