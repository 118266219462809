import React, {useEffect, useState} from 'react'
import {Formik, Form, Field} from 'formik'
import {Modal} from 'react-bootstrap'
import {countries} from '../../../../utils/countries'
import * as Yup from 'yup'
import {useParams} from 'react-router-dom'
import {KTSVG} from '../../../../_theme/helpers'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import {
  createFinancierSelectors,
  useGetFinancierContactPersonsQuery,
  useGetFinanciersQuery,
  useUpdateFinancierContactPersonMutation,
  useUpdateFinancierMutation,
} from '../FinanciersApiSlice'
import {IFinancierContactPerson} from '../interfaces'
import {CountryCustomSelect} from '../../shared/components/CountryCustomSelect'
import {countryCodes} from '../../../../utils/countryCodes'

type Props = {
  showModal: boolean
  handleClose: () => void
  pageSize: number
  currentPage: number
}

export const EditFinancierContactInformation: React.FC<Props> = ({
  showModal,
  handleClose,
  pageSize,
  currentPage,
}) => {
  const ContactInformationValidationSchema = Yup.object().shape({
    facebook: Yup.string().nullable(),
    linkedIn: Yup.string().nullable(),
    twitter: Yup.string().nullable(),
    website: Yup.string().nullable(),
    financierContactPersonName: Yup.string().required('Contact person name is required'),
    financierContactPersonEmail: Yup.string().email().nullable(),
    financierContactPersonTelephone: Yup.string()
      .matches(/^[0-9]{9}$/, 'Phone number must be 9 digits')
      .nullable(),
    financierContactPersonAddress: Yup.string().nullable(),
  })

  const [updateFinancier, {isLoading: isUpdateFinancierLoading}] = useUpdateFinancierMutation()
  const [updateFinancierContact, {isLoading: isUpdateFinancierContactLoading}] =
    useUpdateFinancierContactPersonMutation()
  const {id} = useParams<{id: string}>() as {id: string}
  useGetFinanciersQuery({page: currentPage, page_size: pageSize, query: ''})
  const {selectById: selectFinancierById} = createFinancierSelectors(currentPage, pageSize, '')
  const financierDetails = useSelector((state: RootState) => selectFinancierById(state, id))
  const {data: financierContactsState} = useGetFinancierContactPersonsQuery(id)

  let financierContact: IFinancierContactPerson | null | undefined = null
  if (financierContactsState?.entities) {
    const contactsArray = Object.values(financierContactsState?.entities)
    financierContact = contactsArray.length > 0 ? contactsArray[0] : null
  }
  const phoneNumber = financierContact?.phoneNumber?.split('-')[1] || ''
  const [countryCode, setCountryCode] = useState<string>('')

  useEffect(() => {
    const countryPrefix = financierContact?.phoneNumber?.split('-')[0] || ''
    setCountryCode(countryPrefix)
  }, [financierContact])

  const initialValues = {
    facebook: financierDetails?.facebookUrl,
    linkedIn: financierDetails?.linkedinUrl,
    twitter: financierDetails?.twitterUrl,
    website: financierDetails?.websiteUrl,
    financierContactPersonName: financierContact?.full_name,
    financierContactPersonEmail: financierContact?.email,
    financierContactPersonTelephone: phoneNumber,
    financierContactPersonAddress: financierContact?.address,
  }

  const handleSave = async (values: any) => {
    const updatedFinancierDetails = {
      country: values.country,
      region: values.region,
      address: values.address,
      website_url: values.website,
      facebook_url: values.facebook,
      linkedin_url: values.linkedIn,
      twitter_url: values.twitter,
      id: financierDetails?.id,
    }

    const updatedContactPersonDetails = {
      id: financierContact?.id ?? null,
      full_name: values.financierContactPersonName,
      email: values.financierContactPersonEmail,
      phone_number: `${countryCode}-${values.financierContactPersonTelephone}`,
      address: values.financierContactPersonAddress,
      financier: financierDetails?.id,
    }
    try {
      await Promise.all([
        updateFinancier(updatedFinancierDetails).unwrap(),
        updateFinancierContact(updatedContactPersonDetails).unwrap(),
      ])
      handleClose()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Modal
      id='kt_edit_contact_information'
      aria-hidden='true'
      dialogClassName='h-auto'
      show={showModal}
      onHide={handleClose}
    >
      <div className='modal-content w-700px' role='document'>
        <Formik
          initialValues={initialValues || {}}
          validationSchema={ContactInformationValidationSchema}
          onSubmit={handleSave}
        >
          {({errors, touched, isValid}) => (
            <Form>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h5 className='modal-title ps-1' id='editFinancialsModalLabel'>
                    Edit Contact Information
                  </h5>
                  <div
                    className='btn btn-sm btn-icon btn-active-color-danger'
                    onClick={handleClose}
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                  </div>
                </div>
                <div className='modal-body px-9'>
                  {/* Socials */}
                  <div className='row fv mb-4'>
                    <div className='col-md-6'>
                      <label htmlFor='facebook' className='form-label text-dark'>
                        Facebook
                      </label>
                      <Field
                        type='text'
                        name='facebook'
                        className='form-control form-control-sm'
                        style={{color: '#616162', fontWeight: 300}}
                      />
                      {touched.facebook && (
                        <div className='text-danger mt-2'>{errors.facebook}</div>
                      )}
                    </div>
                    <div className='col-md-6'>
                      <label htmlFor='linkedIn' className='form-label text-dark'>
                        LinkedIn
                      </label>
                      <Field
                        type='text'
                        name='linkedIn'
                        className='form-control form-control-sm'
                        style={{color: '#616162', fontWeight: 300}}
                      />
                      {touched.linkedIn && (
                        <div className='text-danger mt-2'>{errors.linkedIn}</div>
                      )}
                    </div>
                  </div>

                  <div className='row fv mb-4'>
                    <div className='col-md-6'>
                      <label htmlFor='twitter' className='form-label text-dark'>
                        Twitter
                      </label>
                      <Field
                        type='text'
                        name='twitter'
                        className='form-control form-control-sm'
                        style={{color: '#616162', fontWeight: 300}}
                      />
                      {touched.twitter && <div className='text-danger mt-2'>{errors.twitter}</div>}
                    </div>
                    <div className='col-md-6'>
                      <label htmlFor='website' className='form-label text-dark'>
                        Website URL
                      </label>
                      <Field
                        type='text'
                        name='website'
                        className='form-control form-control-sm'
                        style={{color: '#616162', fontWeight: 300}}
                      />
                      {touched.website && <div className='text-danger mt-2'>{errors.website}</div>}
                    </div>
                  </div>

                  {/* Contact Person Details */}

                  <div className='row fv mb-4'>
                    <div className='col-md-6'>
                      <label
                        htmlFor='financierContactPersonName'
                        className='form-label text-dark required'
                      >
                        Name of Contact Person
                      </label>
                      <Field
                        type='text'
                        name='financierContactPersonName'
                        className='form-control form-control-sm'
                        style={{color: '#616162', fontWeight: 300}}
                      />
                      {touched.financierContactPersonName && (
                        <div className='text-danger mt-2'>{errors.financierContactPersonName}</div>
                      )}
                    </div>
                    <div className='col-md-6'>
                      <label
                        htmlFor='financierContactPersonEmail'
                        className='form-label text-dark'
                      >
                        Email of Contact person
                      </label>
                      <Field
                        type='text'
                        name='financierContactPersonEmail'
                        className='form-control form-control-sm'
                        style={{color: '#616162', fontWeight: 300}}
                      />
                      {touched.financierContactPersonEmail && (
                        <div className='text-danger mt-2'>{errors.financierContactPersonEmail}</div>
                      )}
                    </div>
                  </div>

                  <div className='row fv mb-4'>
                    <div className='col-md-6'>
                      <label
                        htmlFor='financierContactPersonTelephone'
                        className='form-label text-dark'
                      >
                        Phone Number of Contact person
                      </label>
                      <div className='input-group input-group-sm mb-3'>
                        <CountryCustomSelect
                          name='dialCode'
                          options={countryCodes}
                          setCountryCode={setCountryCode}
                        />
                        <Field
                          type='number'
                          className='form-control form-control-sm border-start-0 rounded-start-0'
                          name='financierContactPersonTelephone'
                          style={{color: '#616162', fontWeight: 300}}
                        />
                        {touched.financierContactPersonTelephone && (
                          <div className='text-danger mt-2'>
                            {errors.financierContactPersonTelephone}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <label htmlFor='financierContactPersonAddress' className='form-label text'>
                        Address line of Contact person
                      </label>
                      <Field
                        type='text'
                        name='financierContactPersonAddress'
                        className='form-control form-control-sm'
                        style={{color: '#616162', fontWeight: 300}}
                      />
                      {touched.financierContactPersonAddress && (
                        <div className='text-danger mt-2'>
                          {errors.financierContactPersonAddress}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='modal-footer '>
                  <button
                    type='button'
                    className='btn btn-secondary text-dark'
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  <button
                    type='submit'
                    className='btn btn-danger'
                    disabled={
                      !isValid || isUpdateFinancierLoading || isUpdateFinancierContactLoading
                    }
                  >
                    <span className='indicator-label'>
                      {!isUpdateFinancierLoading && !isUpdateFinancierContactLoading && 'Save'}
                      {(isUpdateFinancierLoading || isUpdateFinancierContactLoading) && (
                        <>
                          <span className='spinner-border h-15px w-15px align-middle text-white-400'></span>
                        </>
                      )}
                    </span>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}
