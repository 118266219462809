import clsx from 'clsx'
import React, {FC} from 'react'
import {HeaderUserMenu} from '../index'
import {useLayout} from '../../core'
import {useAuth} from 'react-oidc-context' // Import useAuth

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const {config} = useLayout()
  const auth = useAuth() 
  const user = auth.user 

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Begin :: Alert/ Notification */}
      {/* <div className={clsx('d-flex align-items-center')}>
        <div className={'d-flex align-items-center'}>
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <span className='position-relative'>
              <span className='fas fa-bell' style={{fontSize: '24px', color: 'grey'}}></span>
              <span
                className='position-absolute bottom-0 start-100 translate-middle badge rounded-pill bg-danger'
                style={{fontSize: '0.8em', padding: '0.25em 0.5em'}}
              >
                3
              </span>
            </span>
          </div>
        </div>
      </div> */}
      {/* Welcome::User */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div className='mx-2'>
          <strong>Welcome</strong>, {user?.profile?.given_name}
        </div>{' '}
        
      </div>

      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <span className='fas fa-user-circle' style={{fontSize: '24px'}}></span>
        </div>
        <HeaderUserMenu />
        {/* end::Toggle */}
      </div>
      {/* end::User */}
    </div>
  )
}

export {Topbar}
