import {useEffect, useState} from 'react'

type props = {
  placeholder?: string
  className: string
  name: string
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  initialValue: number | undefined | string
  allowDecimal?: boolean
}

const NumberFormatter = ({
  placeholder = '',
  className = '',
  name,
  setFieldValue,
  initialValue,
  allowDecimal = true,
}: props) => {
  const formatValue = (value: string) => {
    if (!value) return ''
    const cleanValue = value.replace(allowDecimal ? /[^0-9.]/g : /[^0-9]/g, '')
    const parts = cleanValue.split('.')
    let whole = parts[0]
    let decimal = parts.length > 1 ? parts[1].replace(/[^0-9]/g, '') : undefined
    if (!isNaN(parseFloat(whole))) {
      whole = parseInt(whole, 10).toLocaleString()
    }
    return decimal !== undefined ? `${whole}.${decimal}` : whole
  }

  useEffect(() => {
    if (initialValue !== undefined) {
      const formattedInitialValue = formatValue(initialValue?.toString())
      setFieldValue(name, formattedInitialValue)
    }
  }, [name, initialValue, setFieldValue])

  const handleChange = (event: any) => {
    const {value} = event.target
    const formattedValue = formatValue(value)
    setFieldValue(name, formattedValue || '')
  }

  return (
    <input
      type='text'
      className={`form-control ${className}`}
      placeholder={placeholder}
      value={initialValue !== undefined ? formatValue(initialValue?.toString()) : ''}
      onChange={handleChange}
    />
  )
}

export default NumberFormatter
