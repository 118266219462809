import React from 'react'
import {ReactSVG} from 'react-svg'
import {toAbsoluteUrl} from '../AssetHelpers'
type Props = {
  className?: string
  path: string
  svgClassName?: string
  color?:string
}

const KTSVG: React.FC<Props> = ({className = '', path, svgClassName = 'mh-50px', color='red'}) => {
  return (
    <>
      <span className={`svg-icon ${className}`}>
        <>
          <ReactSVG
            src={toAbsoluteUrl(path)}
            className={svgClassName}
            beforeInjection={(svg) => {
              if (color) {
                svg.setAttribute('fill', color)
              }
            }}
          />
        </>
      </span>
    </>
  )
}

export {KTSVG}
