/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useNavigate} from 'react-router-dom'
import CardInitials from '../../shared/components/CardInitials'
import CardBadge from '../../shared/components/CardBadge'
import CardDetail from '../../shared/components/CardDetail'
import ProgressBar from '../../shared/components/ProgressBar'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../data/redux/Store'
import {createServiceProviderSelectors} from '../ServiceProvidersApiSlice'
import {getInitials, trimString} from '../../../../utils/stringHelpers'
import {timeAgo} from '../../../../utils/dateHelpers'
import {IListPageFilters} from '../../companies/interfaces'

type ServiceProviderCardProps = {
  id: string
  page: number
  pageSize: number
  searchTerm: string
  filters: IListPageFilters
}

export const ServiceProviderCard: FC<ServiceProviderCardProps> = ({
  id,
  page,
  pageSize,
  searchTerm,
  filters
}) => {
  const navigate = useNavigate()
  const {selectById: selectServiceProviderById} = createServiceProviderSelectors({
    page,
    pageSize,
    searchTerm,
    ...filters
  })
  const serviceProvider = useSelector((state: RootState) => selectServiceProviderById(state, id))
  const serviceProviderInitials = getInitials(serviceProvider?.name, 3)

  const handleNavigate = () => {
    navigate(`/services/providers/${id}`, {
      state: {currentPage: page, pageSize, searchTerm, ...filters},
    })
  }
  return (
    <div className='col-md-6 col-lg-4 col-sm-12 min-height-270px pb-10'>
      <div
        onClick={handleNavigate}
        className='card border border-2 border-gray-300 border-hover mb-5 h-100 d-flex flex-column justify-content-between'
        style={{cursor: 'pointer'}}
      >
        <div className='d-flex border-0 pt-9 px-5 justify-content-between align-items-center'>
          <CardInitials
            initials={serviceProviderInitials}
            tooltip={true}
            title={serviceProvider?.name ?? ''}
            width={serviceProviderInitials.length >= 4 ? 75 : 50}
          />
          <div className='d-flex py-4'>
            <CardBadge
              text={(serviceProvider?.serviceProviderSectors?.[0]?.sectorName ?? '').toUpperCase()}
            />
          </div>
        </div>
        <div className='px-5 pt-3 d-flex justify-content-between align-items-center h-50px'>
          <span className='fw-bolder text-dark fs-3'>{serviceProvider?.code ?? ''}</span>
        </div>
        <div className='d-flex justify-content-between align-items-start h-50px px-5 mb-5'>
          <div className='col-md-6 pe-0'>
            <CardDetail
              title='Years of Operation'
              value={ serviceProvider?.yearOfIncorporation ? timeAgo(serviceProvider?.yearOfIncorporation) : '-'}
            />
          </div>
          <div className='col-md-6 ps-0'>
            <CardDetail
              title='Location'
              value={`${serviceProvider?.country}, ${serviceProvider?.district}`}
            />
          </div>
        </div>
        <div className='px-5 mt-5 mb-5 d-flex justify-content-between align-items-center h-50px'>
          <ProgressBar
            value={serviceProvider?.numberOfCompletedProjects ?? 0}
            total={serviceProvider?.numberOfProjects ?? 0}
            title='Completed Projects'
            isPercentage={false}
            bold={true}
          />
        </div>
      </div>
    </div>
  )
}
